import React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL, API_TIMEOUT } from '@env';
import axios from 'axios';

type AuthRequest = {
    success: boolean;
    response: any;
};

export type MasterPrivileges = { [key: string]: { [name: string]: string } }
type Auth = {
    auth: boolean;
    initialized: boolean;
    user: null | User;
    request: { current: any };
    masterPrivileges: MasterPrivileges;
    leaseToken: (name: string, expiration: number) => Promise<string | null>;
    validateToken: (token: string) => Promise<boolean>;
    requestProfile: () => Promise<Profile | null>;
    login: (email: string, password: string) => Promise<AuthRequest>;
    logout: () => Promise<AuthRequest>;
    updateToken: (token: string) => void;
}

export type Privileges = Array<{ set: string, privilege: string }>;
export type Profile = {
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
}

export type User = {
    uuid: string;
    email: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    profile: Profile;
    privileges: Privileges;
    subscriptions: Array<string>;
} | null;

const AuthContext = React.createContext({} as Auth);

/**
 * Creates axios request object.
 * @param {string | null} token - User token to be used in request headers.
 * @returns {Object} - Axios request object.
 */
function buildRequest(token: string | null) {
    return axios.create({
        baseURL: API_URL,
        timeout: API_TIMEOUT,
        headers: {
            Authorization: token ? `Bearer ${token}` : null
        }
    });
}

async function validateToken(token: string) {
    return axios.get(`${API_URL}/users/profile`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(() => { return true; }).catch(() => { return false; });
}

function useAuth(): Auth {
    const isInitialMount = React.useRef(true);

    const [initialized, setInitialized] = React.useState(false);
    const request = React.useRef(null as any);

    const [auth, setAuth] = React.useState(false);
    const [token, setToken] = React.useState(null as string | null);

    const [user, setUser] = React.useState(null as null | User);
    const [masterPrivileges, setMasterPrivileges] = React.useState({} as MasterPrivileges)

    const requestProfile = async () => {
        // Retrieve user profile.
        return request.current.get('/users/profile')
            .then((response: any) => {
                setAuth(true);
                setUser(response.data.data);
                return response.data.data
            })
            .catch((response: any) => {
                // If unauthorized, remove token from storage.
                if (response.response?.status === 401) {
                    AsyncStorage.removeItem('token');
                    setToken(null);
                    return null;
                }
            });
    }

    const leaseToken = async (name: string, expiration?: number) => {
        return request.current.post('/users/tokens/lease', { name, expiration })
            .then((response: any) => {
                return response.data.data;
            }).catch((response: any) => {
                console.log(`Failed to lease token for '${name}' set to expire in ${expiration}ms: '${response.response.data.message}' (response.response.status).`);
                return null;
            });
    }

    const requestMasterPrivilegeSet = async () => {
        // Retrieve master privilege set.
        return request.current.get('/users/privileges/master')
            .then((response: any) => {
                setMasterPrivileges(response.data.data);
            })
            .catch((response: any) => { });
    }

    // Update request object when token changes.
    React.useEffect(() => {
        if (isInitialMount.current) { isInitialMount.current = false; return; }

        // Create request object.
        request.current = buildRequest(token);

        // If there is not a user token, return.
        if (!token) {
            setAuth(false);
            setUser(null);
            return;
        }

        // Retrieve profile and privileges, then set initialized.
        Promise.allSettled([requestProfile(), requestMasterPrivilegeSet()])
        .then(() => { if (!initialized) { setInitialized(true); } });
    }, [token]);

    // Retrieve token from storage on mount.
    React.useEffect(() => {
        AsyncStorage.getItem('token')
            .then((token) => {
                if (token) { return setToken(token); }

                // If there is not a user token, build request object.
                request.current = buildRequest(null);

                // Retrieve privilege, then set initialized.
                Promise.allSettled([requestMasterPrivilegeSet()])
                .then(() => { if (!initialized) { setInitialized(true); } });
            });
    }, []);

    return {
        auth,
        initialized,
        user,
        request,
        masterPrivileges,
        leaseToken,
        validateToken,
        requestProfile,
        async login(email: string, password: string) {
            return await request.current.post('/users/login', { email, password })
                .then((response: any) => {
                    // Save token to storage.
                    AsyncStorage.setItem('token', response.data.token);
                    setToken(response.data.token);

                    return { success: true, response };
                })
                .catch((response: any) => {
                    return { success: false, response };
                });
        },
        async logout() {
            return await request.current.post('/users/logout')
                .then(async (response: any) => {
                    const originToken = await AsyncStorage.getItem('originToken');
                    if (originToken) { 
                        AsyncStorage.setItem('token', originToken);
                        AsyncStorage.removeItem('originToken');
                    } else { 
                        AsyncStorage.removeItem('token'); 
                    }
                    
                    setToken(originToken || null);
                    return { success: true, response };
                })
                .catch((response: any) => {
                    return { success: false, response };
                });
        },
        async updateToken(token) {
            AsyncStorage.setItem('token', token);
            setToken(token);
        }
    };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const auth = useAuth();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
    return React.useContext(AuthContext);
}