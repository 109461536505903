import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from '../../../components/Text';
import useTheme from '../../../contexts/theme';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import { ScrollableModal } from '../../../components/Modal';
import Title from '../../../components/Title';
import Divider from '../../../components/Divider';
import Interactive from '../../../components/Interactive';
import { useNavigate } from 'react-router-dom';
import Status from '../../../components/InvoiceStatus';
import { formatDate } from '../../../utility/date';
import useScale from '../../../contexts/scale';

export default function DashboardHome() {
    const { theme } = useTheme();
    const push = useToast();
    const { request } = useAuth();
    const navigate = useNavigate();
    const { style } = useScale();

    // Set invoice references.
    const [invoices, setInvoices] = React.useState([]);
    const [active, setActive] = React.useState(-1);

    // Retrieve invoices.
    React.useEffect(() => {
        request.current.get('/billing/stripe/invoices')
            .then((response: any) => {
                setInvoices(response.data.data.data.filter((invoice: any) => invoice.status !== 'draft'));
            })
            .catch((response: any) => {
                push("Failed to fetch invoices.", "error")
                console.log(`Failed to fetch invoices: ${response.data}`);
            });
    }, []);

    // Styles
    const styles = StyleSheet.create({
        table: {
            flexDirection: 'row',
        },
        container: {
            ...style({ width: '200%' }, 'any'),
            ...style({ width: '100%' }, 'sm')
        },
        entryView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 32,
            borderRadius: 4,
        },
        entry: { flex: 1 },
        activeEntry: {
            flex: 1,
            textDecorationLine: 'underline',
        },
    });

    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>

            {/* Header */}
            <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                <Title title="Invoices" />
            </View>

            {/* Invoices Modal */}
            <ScrollableModal>
                <View style={styles.container}>
                    {/* Outstanding invoices message. */}
                    <View style={{ marginBottom: 24 }}>
                        {invoices.length > 0 ? <DefaultText style={{ color: theme.text.secondary }}>You have {invoices.filter((invoice: any) => invoice.status === 'open').length.toString()} unpaid invoice(s).</DefaultText> : <DefaultText style={{ color: theme.text.secondary }}>You have no invoices.</DefaultText>}
                    </View>

                    {/* Invoice Table Title */}
                    <View style={{ ...styles.table, paddingHorizontal: 32 }}>
                        <DefaultText style={styles.entry}>Invoice No.</DefaultText>
                        <DefaultText style={styles.entry}>Date</DefaultText>
                        <DefaultText style={styles.entry}>Due Date</DefaultText>
                        <DefaultText style={styles.entry}>Total</DefaultText>
                        <DefaultText style={styles.entry}>Status</DefaultText>
                    </View>
                    <Divider style={{ backgroundColor: theme.alternativeSecondary, marginBottom: 24 }} />

                    {/* <Placeholder dependency={null} color={theme.accent}/> */}
                    {/* Invoice Display */}
                    {invoices.length > 0 && invoices.map((invoice: any, index) => (
                        <View key={index} style={{ ...styles.table }}>
                            <Interactive onEnter={() => setActive(index)} onLeave={() => setActive(-1)} onPress={() => navigate(`/dashboard/invoices/view-invoice`, { state: invoices[index] })} style={{ width: '100%' }}>

                                {/* Invoice Entry */}
                                {/* @ts-ignore */}
                                <View style={{ ...styles.entryView, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary, cursor: active == index ? 'pointer' : 'none' }}>
                                    <DefaultText style={active == index ? styles.activeEntry : styles.entry}>{invoice.number}</DefaultText>
                                    <DefaultText style={active == index ? styles.activeEntry : styles.entry}>{formatDate(invoice.created)}</DefaultText>
                                    <DefaultText style={active == index ? styles.activeEntry : styles.entry}>{invoice.due_date ? formatDate(invoice.due_date) : formatDate(invoice.created + 23 * 60 * 60)}</DefaultText>
                                    <DefaultText style={active == index ? styles.activeEntry : styles.entry}>${(invoice.amount_due / 100).toFixed(2)}</DefaultText>
                                    <DefaultText style={styles.entry}>
                                        <Status status={invoice.status} type={'invoices'} />
                                    </DefaultText>
                                </View>
                            </Interactive>
                        </View>
                    ))}

                    {/* No invoices message. */}
                    {invoices.length === 0 && <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}><DefaultText style={{ color: theme.text.secondary }}>No invoices found.</DefaultText></View>}
                </View>
            </ScrollableModal>
        </View>
    )
}