import React from 'react';
import { View, Image } from 'react-native';
import Modal from '../../../components/Modal';
import { DefaultText } from '../../../components/Text';
import Camera from '../../../components/Camera';
import useWebSocket, { Store, Camera as CameraType } from '../../../contexts/websocket';
import { Octicons } from '@expo/vector-icons';
import useTheme from '../../../contexts/theme';

import { MainContent as DashContent } from '../../../components/DashboardContent';
import Construction from '../../../components/Construction';

export default function Surveillance({ }) {
    const { socket } = useWebSocket();
    const { theme } = useTheme();
    return ( <Construction note={"This module is currently in beta & restricted."}/> )
    const [store, setStore] = React.useState({ camera: [] } as Store);

    const boxes = React.useRef([] as any[]);

    // NOTES
    // - CLEANSING
    // - Move camera stuff to separate than websocket?

    // - UBSUBSCRIBE if oldState !== newState (loss of cameras)
    // - Add timestamp to camera devices. stream: {src: obj, timestamp: number} (overlay on feed)

    // - camera fade in/out
    // refresh animation (one rev.)

    // - resizing
    // - order logic
    // - change getFromId to getFromUUID
    // - create local storage of order to properly do this.

    // - viwer count
    // - use phone as temporary camera.
    /**
     * Retrieves the index from the camera id.
     * @param {string} id - The camera id.
     * @returns {number} - The camera index.
     */
    function getCameraFromId(id: string): number | null {
        for (const index in store.camera) {
            if (store.camera[index].id === id) { return Number(index); }
        }
        return null;
    }

    const refreshFeed = () => {
        console.log("Refreshing...")
        socket.emit('cameraFeeds', (feeds: any) => {
            // Updated store feed.
            const updatedStore: CameraType[] = [];

            // New cameras to be stored.
            const newStore: CameraType[] = [];

            for (const index in feeds) {
                const id = feeds[index].id

                // Subscribe to each camera.
                socket.emit('cameraSubscribe', id, true);

                // Retrieve previous index.
                const storedIndex = getCameraFromId(id);

                // If was previously indexed
                if (storedIndex !== null) {
                    // Update position.
                    updatedStore[storedIndex] = feeds[index];

                    // Update previous src data.
                    updatedStore[storedIndex].src = store.camera[storedIndex].src
                } else { newStore.push(feeds[index]); }

                // Remove all empty elements.
                store.camera = updatedStore.filter((element) => { return element != null; });

                // Add new cameras to the end of the updated store.
                store.camera = [...store.camera, ...newStore];

                console.log(store.camera)
            }

            // Merge old and new feeds.
            setStore({ ...store });
        });
    }

    React.useEffect(() => {
        socket.on('cameraTick', async (id, payload) => {
            const index = getCameraFromId(id);

            if (index === null) { return; } // this should never happen.

            // Lifecycle:
            // preload --> src --> trash
            URL.revokeObjectURL(store.camera[index].src);
            store.camera[index].src = store.camera[index].preloadSrc;

            const blob = new Blob([payload.feed], { type: 'image/jpeg' });
            store.camera[index].preloadSrc = URL.createObjectURL(blob);
            store.camera[index].predictions = payload.predictions;
            store.camera[index].data = {...payload.data, ...store.camera[index].data};

            setStore({ ...store });
        });

        // Retrieve subscribed cameras from API.
        refreshFeed();

        return () => {
            // Unsubscribe from all cameras.
            for (const index in store.camera) { socket.emit('cameraSubscribe', store.camera[index].id, false); }

            // Remove all cameraTick listeners.
            socket.removeAllListeners('cameraTick');
        }
    }, []);

    return (
        <View style={{ flexDirection: 'row' }}>
            {/* <DefaultText>{i.current}</DefaultText> */}
            {/* Menu Modal */}
            <Modal style={{ container: { height: '50%', flex: 0.125 } }}>
                <DefaultText>This will be a menu or something.</DefaultText>
            </Modal>

            {/* Divider */}
            <View style={{ marginHorizontal: 10 }}></View>

            {/* Main Content Modal */}
            <DashContent title='Camera Feeds' icon='device-camera-video' description='Camera feed overview.' headerContent={<Octicons style={{ marginLeft: 'auto' }} name='sync' size={24} color={theme.text.color} onPress={() => { refreshFeed(); }} />}>
                {/* Cameras */}
                <View style={{ flexDirection: 'row' }}>
                    {
                        store.camera.map((camera, index) => {
                            return <Camera key={index} socket={socket} data={camera} />
                        })
                    }
                </View>
            </DashContent>

        </View>
    )
}