import React from 'react';
import { View, StyleSheet } from 'react-native';
import Sidebar, { MenuItem } from '../../components/Sidebar';
import { Outlet, useNavigate } from "react-router-dom";
import Banner from '../../components/Banner';

import Tree from '../../components/Tree';
import Divider from '../../components/Divider';
import { DefaultText, TextLinkInteractive } from '../../components/Text';

import { getTree } from '../../utility/location';
import { makeTitle } from '../../utility/string';
import Modal from '../../components/Modal';
import useTheme from '../../contexts/theme';
import useScale from '../../contexts/scale';

const styles = StyleSheet.create({
    container: {
        height: '100%',
        flexDirection: 'row',
    },
    title: {
        fontSize: 24,
        marginBottom: 4
    },
});

const titles = ['Terms of Service', 'Privacy Policy'];
const items = [
    [
        { title: 'General', link: 'terms' },
        { title: 'Subscription', link: 'terms/subscription' }
    ],
    [
        { title: 'General', link: 'privacy' },
        { title: 'GDPR', link: 'privacy/gdpr' }
    ]
]

export default function Root() {
    const { theme } = useTheme();
    const location = getTree();
    const navigate = useNavigate();
    const { style } = useScale();

    const url = location.slice(2).join('/');

    const active = {
        color: theme.accent,
        borderLeftWidth: 2,
        borderLeftColor: theme.accent,
        paddingLeft: 8
    }

    return (
        <View style={styles.container}>
            <View style={{ flexGrow: 1 }}>

                {/* Page Header */}
                <DefaultText font='bold' style={styles.title}>{makeTitle(location[location.length - 1])}</DefaultText>
                <Tree />
                <Divider />

                <View style={{ flexDirection: 'column', ...style({flexDirection: 'row'}, 'sm') }}>
                    <View style={style({marginRight: 48}, 'sm')}>
                        {/* Menu */}
                        <Modal style={{ container: { width: '100%', ...style({width: 'fit-content'}, 'sm'),  } }}>
                            {titles.map((title, index) => (
                                <View key={index} style={index > 0 && { marginTop: 24 }}>
                                    <DefaultText font='bold' style={{ color: theme.text.secondary, textTransform: 'uppercase', fontSize: 14, lineHeight: 24 }}>{title}</DefaultText>
                                    <Divider style={{ backgroundColor: theme.alternativeSecondary, marginVertical: 6 }} />
                                    <View style={{ marginTop: 3 }}>
                                        {items[index].map((item, index) => (
                                            <TextLinkInteractive key={index} navigate={navigate} link={item.link} font='semibold' style={{ ...{ fontSize: 14, lineHeight: 24, marginBottom: 3 }, ...(item.link === url && active) }}>{item.title}</TextLinkInteractive>
                                        ))}
                                    </View>
                                </View>
                            ))}
                        </Modal>
                    </View>

                    {/* Page Content */}
                    <View style={{ flexGrow: 1, marginRight: '33%', marginTop: 8, width: '90vw', textAlign: 'center', ...style({marginTop: 0, width: 'min-content', textAlign: 'left'}, 'sm') }}><Outlet /></View>
                </View>

            </View>
        </View>
    )
}