import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import useTheme from '../contexts/theme';
import { Link, useNavigate } from 'react-router-dom';
import { getTree, isRouteActive } from '../utility/location';
import { DefaultText } from './Text';
import useScale from '../contexts/scale';
import useData from '../contexts/data';
import { useLocation } from 'react-router-dom';

import { AntDesign } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
const Icon = Octicons;

import Divider from './Divider';
import Interactive from './Interactive';

export type MenuItem = {
    name: string,
    path: string | null,
    active?: boolean | undefined,
    icon: keyof typeof Icon.glyphMap
}

export default function Root({ routes }: { routes: MenuItem[] }) {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const tree = getTree();
    const [routeState, setRouteState] = React.useState(routes);
    const { style } = useScale();
    const { getStatic, setEph, dataLink, getEph } = useData();

    const [active, setActive] = React.useState(true);

    const pathName = useLocation().pathname;

    const sidebarOverride = [
        'view-tree'
    ];

    // Declare override if applicable.
    React.useEffect(() => {
        for (const route of sidebarOverride) { if (tree.includes(route)) { setEph('dashMenuClosedOverride', true); } }
        // Reset override status.
        return () => { setEph('dashMenuClosedOverride', false) }
    }, [pathName]);

    React.useEffect(() => {
        setRouteState(routes);

        // Reset route states.
        return () => { for (const route of routes) { route.active = false; } }
    }, [routes])


    // Retrieve sidebar status.
    React.useEffect(() => {
        (async () => {
            const staticState = await getStatic('dashboardMenuClosed');
            setActive(getEph('dashMenuClosedOverride') ? false : !Boolean(staticState)); 
        })();
    }, [dataLink.current])

    const styles = StyleSheet.create({
        container: {
            width: 'auto', // was 240
            // height: '100vh',
            // flex: 1,
            // borderColor: 'red',
            // borderWidth: 2,
            paddingRight: 16,
            paddingBottom: 16,

            ...style({
                borderRightWidth: 3,
                borderRightColor: theme.accent,
                borderRadius: 8,
                marginRight: 12,
            }, 'any'),

            ...style({
                borderRightWidth: 0,
                borderRightColor: null,
                borderRadius: 0,
                marginRight: 'auto',
            }, 'sm'),


            // ...style({ position: 'absolute' }, 'any'),
            // ...style({ position: null }, 'sm'),
            ...style({ backgroundColor: theme.background }, 'any'),
            ...style({ backgroundColor: null }, 'sm'),
            ...style({ marginTop: -16 }, 'any'),
            ...style({ marginTop: -24 }, 'sm'),

            // ...style(theme.shadowPrimary, 'any'),
            ...style({ boxShadow: null }, 'sm'),
        },
        subheader: {
            marginTop: 24,
            marginBottom: 8,
            // marginVertical: 12,
        },
        routeContainer: {
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
        },
        linkContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        activeLink: {
            backgroundColor: theme.alternative,
            borderRadius: 100,
            paddingVertical: 6,
            paddingHorizontal: 18,
        },
        icon: {
            width: 24,
            height: 24,
        },
        iconSpacing: { marginRight: 24 },

        selected: {
            backgroundColor: theme.alternative,
            borderRadius: 8,
        }
    });

    // Product example.
    // https://sx.physgun.net/1667804510-Present-Nauplius.png

    function toggleItemStatus(item: MenuItem) {
        item.active = !item.active;
        setRouteState([...routeState]);
    }

    if (!true) {
        return (
            <ScrollView style={{ ...styles.container, width: undefined, marginTop: -12 }}>
                {routeState.map((item, index) => {
                    if (item.path === null) { return null; }
                    return (
                        <View key={index} style={{ flexDirection: 'column', marginBottom: 16 }}>
                            <Link to={item.path} style={{ textDecoration: 'none' }}>
                                <View style={{ flexDirection: 'column' }}>
                                    <Icon style={{ ...styles.icon, marginBottom: 4, width: undefined }} name={item.icon} size={24} color={theme.text.color} />
                                    <DefaultText style={{ fontSize: 10 }}>{item.name}</DefaultText>
                                </View>
                            </Link>
                        </View>
                    )
                })}
            </ScrollView>
        )
    }

    const subheader = (item: MenuItem) => {
        return (
            <View key={item.name} style={styles.subheader}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    {/* Icon */}
                    <Octicons name={item.icon} size={24} color={theme.text.color} />
                    {/* Divider */}
                    <View style={{ height: '100%', marginHorizontal: 12, borderRightColor: theme.accent, borderRightWidth: 2 }}></View>
                    {/* Title */}
                    <DefaultText font='semibold' style={{ fontSize: 16 }}>{item.name}</DefaultText>
                </View>
                <Divider style={{ marginBottom: 0 }} />
            </View>
        )
    }

    return active && (
        <ScrollView style={styles.container}>
            { /* Menu Items */}
            {routeState.map((item, index) => {
                // if item is a subheader
                if (item.path === null) { return subheader(item); }

                let styling = { ...styles.linkContainer };
                if (isRouteActive(item.path, tree) || item.active) {
                    styling = { ...styling, ...styles.activeLink }
                }
                return (
                    <View key={index} style={styles.routeContainer}>
                        {/* @ts-ignore */}
                        <Interactive onPress={() => { navigate(item.path); }} onEnter={() => { toggleItemStatus(item); }} onLeave={() => { toggleItemStatus(item); }}>
                            <View style={styling}>
                                <Icon style={{ ...styles.icon, ...styles.iconSpacing }} name={item.icon} size={24} color={theme.text.color} />
                                <DefaultText style={{ fontSize: 14 }}>{item.name}</DefaultText>
                            </View>
                        </Interactive>
                    </View >
                )
            })}
        </ScrollView >
    )
}