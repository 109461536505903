import React from 'react';
import { Animated, View } from 'react-native';
import { AnimateRef } from '../utility/animation';
import { Octicons } from '@expo/vector-icons';
import useTheme from '../contexts/theme';

export default function SpinIcon({ active, size = 24, style }: { active: boolean, size?: number, style?: any }) {
    const { theme } = useTheme();

    // Spin value & animation.
    const spinValue = React.useRef(new Animated.Value(0)).current;
    const spinAnim = Animated.loop(AnimateRef(spinValue, 1, 1000, false));

    // Conditional start.
    if (active) { spinAnim.start(); }

    // Spinner opacity.
    const opacityValue = React.useRef(new Animated.Value(0)).current;
    AnimateRef(opacityValue, active ? 1 : 0).start();

    // Spin interpolation mapping.
    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    });

    // Return spinner component.
    return (
        <View style={style}>
            <Animated.View style={{ transform: [{ rotate: spin }], opacity: opacityValue }}>
                <Octicons name="sync" size={size} color={theme.text.color} />
            </Animated.View>
        </View>
    )
}