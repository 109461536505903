import { Animated } from 'react-native';

/**
 * Simple ref animation.
 * @param {Animated.Value} ref - Reference to Animated.Value to animate.
 * @param {number} value - Value to animate to.
 * @param {number} [duration=500] - Duration of animation.
 * @param {boolean} [useNativeDriver=true] - Use native driver.
 * @returns {Animated.timing}
 */
export function AnimateRef(ref: Animated.Value, value: number, duration = 500, useNativeDriver = true) {
    return Animated.timing(ref, {
        toValue: value,
        duration: duration,
        useNativeDriver: useNativeDriver
    });
}