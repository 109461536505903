import React from 'react';
import Form, { Field } from '../components/Form';
import { DefaultText } from '../components/Text';
import { Link } from 'react-router-dom';
import useTheme from '../contexts/theme';
import useToast from '../contexts/toast';
import useAuth from '../contexts/auth';

import { View, Animated } from 'react-native';
import Modal from '../components/Modal';
import { passwordStrength, Options as PasswordStrengthOptions } from 'check-password-strength'
import StatusBar from '../components/StatusBar';

import { handleFailure } from '../utility/network';
import Construction from '../components/Construction';
import useScale from '../contexts/scale';

const passwordStrengths = [
    {
        id: 0,
        value: "Not viable",
        minDiversity: 0,
        minLength: 0
    },
    {
        id: 1,
        value: "Weak",
        minDiversity: 1,
        minLength: 8
    },
    {
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 10
    },
    {
        id: 3,
        value: "Strong",
        minDiversity: 4,
        minLength: 12
    }
] as PasswordStrengthOptions<string>;

function Success({ email, linkedOpacity, theme }: { email: string, linkedOpacity: Animated.Value, theme: any }) {
    const { style } = useScale();

    return (
        <Animated.View style={{ width: '100%', marginHorizontal: 'auto', flexDirection: 'column', alignItems: 'center', textAlign: 'center', opacity: linkedOpacity }}>
            <DefaultText font='semibold' style={{ ...style({fontSize: 20}, 'any'), ...style({fontSize: 24}, 900) }}>📧 Awaiting Email Verification</DefaultText>
            <DefaultText style={{ fontSize: 16 }}>Account created successfully!</DefaultText>
            <DefaultText style={{ fontSize: 16, marginTop: 12 }}>Please check your email to activate your account.</DefaultText>
            <DefaultText style={{ fontStyle: 'italic' }}>Make sure to check your spam folder as well.</DefaultText>
            <DefaultText font='medium' style={{ color: theme.text.secondary }}>👤 {email}</DefaultText>
        </Animated.View>
    )
}

export default function Register() {
    const { theme } = useTheme();
    const { request } = useAuth();
    const push = useToast();
    const { style } = useScale();

    const [status, setStatus] = React.useState(0);
    const [statusText, setStatusText] = React.useState('Password Strength: Unknown');

    const fields: (Field | Field[])[] = [
        {
            label: 'Email address',
            placeholder: 'Email address',
            maxLength: 64,
            payloadKey: 'email',
        },
        [
            {
                label: 'Password',
                placeholder: 'Password',
                maxLength: 64,
                payloadKey: 'password',
                secureTextEntry: true,
                onChangeText: (value: string) => {
                    const strength = passwordStrength(value, passwordStrengths);
                    setStatus((strength.id / 3) * 100);
                    setStatusText(`Password Strength: ${strength.value}`);
                }
            },
            {
                label: 'Password confirmation',
                placeholder: 'Password confirmation',
                maxLength: 64,
                payloadKey: 'password_confirmation',
                secureTextEntry: true,
            },
        ]
    ]

    return (
        <Form title="Register" fields={fields} style={{ modal: style({ width: '40%' }, 'xl') }} customFields={<StatusBar title={statusText} status={status} style={{ container: { marginBottom: 16 } }} />} submissionText="REGISTER" onPress={(data, cb, linkedOpacity) => {
            request.current.post('/users/register', data)
                .then((response: any) => {
                    push('Successfully registered account.', 'success');
                    return true;
                })
                .catch((response: any) => {
                    handleFailure(response, push);
                    // if (!response.response?.data) { push("Failed to register account.", "error"); return;}
                    // push(`${response.response?.data.message}: ${response.response?.data.errors.map((e: any) => { return e.field; }).join(', ')}. (${response.response.status})`, 'error');
                })
                .then((success: boolean | undefined) => {
                    success ? cb(<Success email={data.email} linkedOpacity={linkedOpacity} theme={theme}></Success>) : cb();
                });
        }}>
            {/* Have an account? Log in.*/}
            <DefaultText style={{ color: theme.accent, marginTop: 12 }}>
                Have an account?&nbsp;
                <Link to="/login" style={{ color: theme.accent }}>
                    <DefaultText font='bold' style={{ color: theme.accent }}>Log in.</DefaultText>
                </Link>
            </DefaultText>

            {/* By registering an account... */}
            <DefaultText style={{ color: theme.text.secondary, fontSize: 12 }}>
                By registering an account with Atlas Industries, Inc. you agree to our&nbsp;
                <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                </Link>
                &nbsp;and&nbsp;
                <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                </Link>
            </DefaultText>
        </Form>
    );
}