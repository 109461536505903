import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useToast from '../../../contexts/toast';
import useAuth from '../../../contexts/auth';
import axios from 'axios';
import { SPOTIFY_CLIENT_ID, SPOTIFY_CLIENT_SECRET, SPOTIFY_REDIRECT_URL } from '@env';

function failure(push: any, navigate: any) {
	push("Failed to link spotify account.", "error");
	return navigate("/dashboard/tunebox");
}

export default function TuneboxSpotifyCb() {
	const navigate = useNavigate();
	const push = useToast();
	const { request } = useAuth();
	const code = useSearchParams()[0].get("code");
	const state = useSearchParams()[0].get("state");

	React.useEffect(() => {
		if (!code || !state) { return failure(push, navigate); }

		const queryString = new URLSearchParams({
			grant_type: "authorization_code",
			code: code,
			redirect_uri: SPOTIFY_REDIRECT_URL
		}).toString();

		const config = {
			headers: {
				"Authorization": `Basic ${Buffer.from(`${SPOTIFY_CLIENT_ID}:${SPOTIFY_CLIENT_SECRET}`).toString("base64")}`,
				"Content-Type": "application/x-www-form-urlencoded",
			}
		}

		// Request refresh & access token from authorization code.
		axios.post(`https://accounts.spotify.com/api/token`, queryString, config)
			.then((response) => {
				// Update spotify credentials in database.
				request.current.put("/services/tunebox/" + state, {
					spotify: {
						accessToken: response.data.access_token,
						refreshToken: response.data.refresh_token,
						expiresAt: Date.now() + response.data.expires_in * 1000
					}
				}).then(() => {
					push("Successfully linked spotify account.", "success");
					return navigate("/dashboard/tunebox");
				}).catch(() => { return failure(push, navigate); });
			}).catch(() => { return failure(push, navigate); });
	}, []);

	return <Loading text="Linking spotify account..." />;
}
