import React from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';

export default function Interactive({ style, onEnter, onLeave, onPress, children }: { style?: any, onEnter?: () => void, onLeave?: () => void, onPress?: () => void, children: React.ReactComponentElement<any> }) {
    const press = (e) => { e.preventDefault(); onPress?.(); }
    return (
        // @ts-ignore
        <View style={{ cursor: 'pointer', ...style }} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <TouchableWithoutFeedback style={{ width: '100%' }} onPress={press}>
                <View>{children}</View>
            </TouchableWithoutFeedback>
        </View>
    );
}