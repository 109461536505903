import React from 'react';
import { Animated, TouchableHighlight, StyleSheet, View } from 'react-native';
import useTheme from '../contexts/theme';
import { DefaultText } from './Text';
import { Octicons } from '@expo/vector-icons';

export default function Button({ title, onPress, icon, style }: { title: string | JSX.Element, onPress: () => void, icon?: keyof typeof Octicons.glyphMap, style?: { button?: Object, text?: Object } }) {
    const { theme } = useTheme();
    const styles = StyleSheet.create({
        button: {
            // width: '100%',
            // padding: 6,
            paddingVertical: 6,
            paddingHorizontal: 28,
            borderRadius: 100,
            borderColor: theme.accent,
            borderWidth: 2,
            backgroundColor: theme.background,
            alignItems: 'center',
            ...style?.button
        },
        text: {
            fontSize: 14,
            ...style?.text
        },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        }
    });

    const AnimatedTouchable = Animated.createAnimatedComponent(TouchableHighlight);
    return (
        <AnimatedTouchable
            activeOpacity={0.6}
            underlayColor={theme.alternative}
            onPress={onPress}
            style={styles.button}
        >
            <View style={styles.container}>
                {icon && <Octicons name={icon} size={16} color={theme.accent} style={{ marginRight: 8 }} />}
                <DefaultText font='bold' style={styles.text}>{title}</DefaultText>
            </View>
        </AnimatedTouchable>
    );
}