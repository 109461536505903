import React from 'react';
import { View, Image } from 'react-native';
import Modal from '../components/Modal';

import { useRouteError } from "react-router-dom";
import useTheme from '../contexts/theme';
import Background from '../components/Background';
import { DefaultText } from '../components/Text';
import Divider from '../components/Divider';
import Title from '../components/Title';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import useScale from '../contexts/scale';

export default function DashboardHome() {
    const { theme } = useTheme();
    const error = useRouteError() as any;
    const navigate = useNavigate();
    const { style } = useScale();

    // Validate error.
    if (!error.status) { error.status = 500; error.statusText = 'Error' }
    // Set website title.
    React.useEffect(() => { document.title = `${error.status} | Atlas`; }, []);

    return (
        <Background>
            <View style={{ height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ minWidth: '30%', maxWidth: '80%' }}>
                    {/* <View style={{ width: '50%', marginHorizontal: 'auto' }}><Title title="Home" fontSize={20} /></View> */}
                    {/* <Image source={theme.logo} style={{ marginHorizontal: 'auto', width: 100, height: 100 }} /> */}
                    <Modal style={{ container: { flexDirection: 'column', alignItems: 'center', borderWidth: 2, borderColor: theme.accent, backgroundColor: theme.background } }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <DefaultText font='bold' style={{ ...style({ fontSize: 28 }, 'any'), ...style({ fontSize: 48 }, 'sm') }}>{`⚠️ ${error.status}: ${error.statusText}`}</DefaultText>
                        </View>
                        <Divider style={{ backgroundColor: theme.alternative }} />
                        <DefaultText style={{ ...style({ fontSize: 20 }, 'any'), ...style({ fontSize: 24 }, 'sm'), textAlign: 'center' }}>
                            We're sorry - something went wrong.
                        </DefaultText>
                        <Button title="Go home" onPress={() => { navigate('/') }} style={{ button: { width: '50%', marginTop: 12 } }}></Button>
                    </Modal>
                </View>
            </View>
        </Background>
    )
}