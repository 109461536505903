import React from 'react';
import Form, { Field } from '../../../components/Form';
import { DefaultText } from '../../../components/Text';
import useTheme from '../../../contexts/theme';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import { Link } from 'react-router-dom';
import { handleFailure } from '../../../utility/network';

export default function DashboardHome() {
    const { theme } = useTheme();
    const push = useToast();
    const { request, user, requestProfile } = useAuth();

    const fields: (Field | Field[])[] = [
        {
            label: 'Email address',
            value: user?.email,
            disabled: true,
            placeholder: 'Email address',
            maxLength: 64,
            payloadKey: 'email',
        },
        [
            {
                label: 'First Name',
                value: user?.profile?.firstName,
                disabled: user?.profile?.hasOwnProperty('firstName'),
                placeholder: 'John',
                maxLength: 64,
                payloadKey: 'firstName',
            },
            {
                label: 'Last Name',
                value: user?.profile?.lastName,
                disabled: user?.profile?.hasOwnProperty('lastName'),
                placeholder: 'Doe',
                maxLength: 64,
                payloadKey: 'lastName',
            },
        ],
        [
            {
                label: 'Phone Number',
                value: user?.profile?.phoneNumber,
                disabled: user?.profile?.hasOwnProperty('phoneNumber'),
                placeholder: '+12345678900',
                maxLength: 64,
                payloadKey: 'phoneNumber',
            },
            {
                label: 'Company Name',
                value: user?.profile?.companyName,
                disabled: user?.profile?.hasOwnProperty('companyName'),
                placeholder: 'Atlas Industries, Inc.',
                maxLength: 64,
                payloadKey: 'companyName',
            },
        ],
        [
            {
                label: 'Address Line 1',
                value: user?.profile?.addressOne,
                disabled: user?.profile?.hasOwnProperty('addressOne'),
                placeholder: '1234 Main St.',
                maxLength: 64,
                payloadKey: 'addressOne',
            },
            {
                label: 'Address Line 2',
                value: user?.profile?.addressTwo,
                disabled: user?.profile?.hasOwnProperty('addressTwo'),
                placeholder: 'STE 123',
                maxLength: 64,
                payloadKey: 'addressTwo',
            },
        ],
        [
            {
                label: 'City',
                value: user?.profile?.city,
                disabled: user?.profile?.hasOwnProperty('city'),
                placeholder: 'Austin',
                maxLength: 64,
                payloadKey: 'city',
            },
            {
                label: 'Region',
                value: user?.profile?.region,
                disabled: user?.profile?.hasOwnProperty('region'),
                placeholder: 'Texas',
                maxLength: 64,
                payloadKey: 'region',
            },
        ],
        [
            {
                label: 'Postal Code',
                value: user?.profile?.postalCode,
                disabled: user?.profile?.hasOwnProperty('postalCode'),
                placeholder: '12345',
                maxLength: 64,
                payloadKey: 'postalCode',
            },
            {
                label: 'Country',
                value: user?.profile?.country,
                disabled: user?.profile?.hasOwnProperty('country'),
                placeholder: 'US',
                maxLength: 2,
                payloadKey: 'country',
            }
        ]
    ]

    return (
        <Form title="Profile" fields={fields} submissionText="UPDATE" style={{modal: {width: '80%'}}} onPress={async (data, cb) => { 
            request.current.post('/users/profile', data)
            .then((response: any) => {
                push("Profile updated successfully!", "success");
                requestProfile();
            })
            .catch((response: any) => {
                handleFailure(response, push);
                // if (!response.response?.data) { push("Failed to update profile.", "error"); return;}
                // push(`${response.response.data.message}: ${response.response.data.errors.map((e: any) => { return e.field; }).join(', ')}. (${response.response.status})`, 'error');
            }).finally(() => { cb(); })
        }}>
            {/* By registering a profile... */}
            <DefaultText style={{ color: theme.text.secondary, fontSize: 12, marginTop: 12 }}>
                By registering a profile with Atlas Industries, Inc. you agree to our&nbsp;
                <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                </Link>
                &nbsp;and&nbsp;
                <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                </Link>
            </DefaultText>
        </Form>
    )
}