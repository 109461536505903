import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from './Text';
import useTheme from '../contexts/theme';
import { Link } from 'react-router-dom';

export default function ProfileCircle({ content }: { content: string }) {
    const { theme } = useTheme();

    const styles = StyleSheet.create({
        container: {
            // marginLeft: 'auto',
        },
        profile: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: theme.alternative,
            // borderColor: theme.text.color,
            // borderWidth: 2,
            ...theme.shadowPrimary
        },
    });

    return (
        <View style={styles.container}>
            {/* Temp */}
            <Link to={"/dashboard/profile"} style={{ textDecoration: 'none'}}>
                <View style={styles.profile}>
                    <DefaultText font="bold" style={{ color: theme.accent, fontSize: 18 }}>{content}</DefaultText>
                </View>
            </Link>
        </View>
    );
}
// Click 
// Email
// > View Profile
// > Settings
// > Sign Out