import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText, ItalicText, TextBreak, TextLinkInteractive, TextSpace } from '../../../components/Text';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import { Section, Title, Styles } from '../Components';

export default function GeneralTerms() {
    const navigate = useNavigate();
    const { theme } = useTheme();

    return (
        <View style={{ gap: 16 }}>
            {/* Title */}
            <Title title="General Privacy Policy" date="01/29/2023" />

            {/* Content */}
            <View style={{ gap: 16 }}>

                {/* Preamble */}
                <DefaultText>This document (“Privacy Policy”) explains the privacy rules applicable to all information collected or submitted when you access, install, or use the Atlas Services.</DefaultText>
                <DefaultText>All definitions and capitalized words used in this Privacy Policy are defined here or in our General Terms.</DefaultText>
                <DefaultText>By visiting our websites, by submitting your personal data to us, and by accessing, installing, and/or using the Services, you confirm that you have read this Privacy Policy and agree to be bound by this Privacy Policy. If you disagree with the rules of this Privacy Policy, please do not use our services.</DefaultText>
                <DefaultText>Information in this Privacy Policy is provided in layers, meaning that all information related to our general personal data processing practices is provided here. Meanwhile, additional Service-specific information applicable to separate Atlas products or websites are accessible via links in this Privacy Policy.</DefaultText>

                {/* Section I. Collection and Processing of Data */}
                <Section>I. Collection and Processing of Data</Section>
                <DefaultText>Atlas processes user data to a limited scope - for provision of the Services, processing payments for the Services, as well as the functioning of our websites and mobile applications. This data is used to provide better services, improve website functionality, and personalize the user experience.</DefaultText>
                <DefaultText>We process and collect the information described below.</DefaultText>

                {/* Profile and account registration */}
                <DefaultText>
                    <ItalicText>(1) Profile and account registration:</ItalicText><TextBreak />

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(a) Account registration:</ItalicText><TextSpace /><TextSpace />
                            We ask for your email address as part of your registration. It is necessary for establishing an Atlas account, retrieving a lost password, and using our services.
                        </DefaultText>
                    </View>

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(b) Profile registration:</ItalicText><TextSpace /><TextSpace />
                            Information entered by users, such as full name, address, organization, phone number, and other relevant information.
                        </DefaultText>
                    </View>
                </DefaultText>

                {/* Payment related information: */}
                <DefaultText>
                    <ItalicText>(2) Payment related information:</ItalicText><TextBreak />

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(a) Payment data (If using paid Services):</ItalicText><TextSpace /><TextSpace />
                            This information is necessary to collect payments for our Services. In addition to the traditional payment methods, such as credit cards, users can buy our Service via bank transfer. Our payment processing partners process basic billing information for payment processing and refund requests (such as date of purchase, payer’s IP address, zip code, card owner’s full name, credit card information, and other required data).
                        </DefaultText>
                    </View>

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(b) Referral Information:</ItalicText><TextSpace /><TextSpace />
                            If a referral, partner, or discount code is used upon purchasing one of our Services, this information will be recorded so that the amount due may be calculated, and if applicable, so the proper party may receive their commission.
                        </DefaultText>
                    </View>
                </DefaultText>

                {/* Communication data: */}
                <DefaultText>
                    <ItalicText>(3) Communication data:</ItalicText><TextBreak />

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(a) Email address and phone number:</ItalicText><TextSpace /><TextSpace />
                            We may use these forms of contact to:
                            <View style={Styles.indent}>
                                <DefaultText>(i) Send you important updates and announcements related to your use of our Services.</DefaultText>
                                <DefaultText>(ii) Respond to your requests or inquiries.</DefaultText>
                                <DefaultText>(iii) Send you offers, surveys, and other marketing content (that you can opt-out of at any time).</DefaultText>
                                <DefaultText>(iv) Any other required communication as deemed by Atlas management.</DefaultText>
                            </View>
                        </DefaultText>
                    </View>
                </DefaultText>

                {/* Information collected on our website and/or applications:: */}
                <DefaultText>
                    <ItalicText>(4) Information collected on our website and/or applications:</ItalicText><TextBreak />

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(a) Access logs:</ItalicText><TextSpace /><TextSpace />
                            As most websites on the internet, our website collects access logs (such as IP address, browser type, operating system) to operate our services and ensure their secure, reliable, and robust performance. This information is also essential for fighting against DDoS attacks, scanning, and similar hacking attempts.
                        </DefaultText>
                    </View>

                    <View style={Styles.indent}>
                        <DefaultText>
                            <ItalicText>(b) Forms:</ItalicText><TextSpace /><TextSpace />
                            All forms are submitted voluntarily and are not required. Any information provided via the form will be recorded.
                        </DefaultText>
                    </View>
                </DefaultText>

                <DefaultText>Providing this information is voluntary, however if omitted, we reserve the right to refuse services and not register your account. By using our website and entering your information, you consent to the collection and use of this information in accordance with this privacy policy.</DefaultText>

                {/* Section II. Additional Information per Product */}
                <Section>II. Additional Information per Product</Section>
                <DefaultText>As our products cater to different user needs, they may collect different data points during their performance. The following links (when applicable) will provide further information.</DefaultText>

                {/* Section III. */}
                <Section>III. Sharing your Personal Data</Section>
                <DefaultText>We do not share your personal information with third parties except as described in this Privacy Policy.</DefaultText>
                <DefaultText>
                    <ItalicText>(1) Requests for data:</ItalicText><TextSpace /><TextSpace />
                    Any request for user data should follow an appropriate official legal process to be recognized by the laws of the United States. We carefully review each request to make sure it satisfies laws applicable to our company, laws of requesting country, international norms, and our internal policies. In cases where, following an appropriate legal process, we are able to identify a specific person, we will provide the limited data we process as per our Privacy Policy.
                </DefaultText>

                {/* Section IV. Choices Related to your Data */}
                <Section>IV. Choices Related to your Data</Section>
                <DefaultText>If you'd like to edit your profile information, delete your account or data, or request to provide you with a copy of your personal information, please contact our support team. Please note that you may need to pass through a couple of account verification steps so that we can verify you are the owner of the account. These requests ultimately are made by Atlas in its sole and absolute discretion.</DefaultText>
                <DefaultText>If you wish to unsubscribe from our marketing communication, you can opt out at any time by clicking the unsubscribe link at the bottom of each message or contacting us at <DefaultText font='bold'>support@atlasnxs.com</DefaultText>.</DefaultText>
                <DefaultText>You can control the use of cookies at the individual browser level. To disable cookies, follow your browser's instructions on how to block or clear cookies.</DefaultText>
                <DefaultText>If you disagree with the processing of your data by Atlas, please do not use our Services. You can request us to discontinue processing your personal data, in which case your data will be processed only as much as it is necessary to effect the discontinuation of your use of the Services (e.g., final settlement), or finalizing other Atlas’ legal relationship with you (e.g., record keeping, accounting, processing refunds).</DefaultText>
                <DefaultText>If you wish to use the Services again, you will have to accept and agree to this Privacy Policy anew.</DefaultText>

                {/* Section V. Data Security */}
                <Section>V. Data Security</Section>
                <DefaultText>We take data security very seriously and take all steps reasonably necessary to secure your data (whether technical, physical, or administrative). However, no company can guarantee the absolute security of internet communications. By using the Services, you expressly acknowledge that we cannot guarantee the security of any data provided to or received by us through the Services and that any information received from you through the website or our Services is provided at your own responsibility.</DefaultText>

                {/* Section VI. Information for Users in the European Economic Area (EEA) */}
                <Section>VI. Information for Users in the European Economic Area (EEA)</Section>
                <DefaultText>The European Union's General Data Protection Regulation (“GDPR”) requires that we provide notice in a specific way to our European users about their privacy rights. Please find all the necessary information <TextLinkInteractive navigate={navigate} link='/legal/privacy/gdpr' style={{ color: theme.accent, textDecorationLine: 'underline' }}>here</TextLinkInteractive>.</DefaultText>

                {/* Section VII. Information for Users in California */}
                <Section>VII. Information for Users in California</Section>
                <DefaultText>If you are a California resident, you can implement your rights as provided in the California Consumer Privacy Act (CCPA) by contacting us at <DefaultText font='bold'>support@atlasnxs.com</DefaultText>. As per definitions in the CCPA, please note that Atlas does not sell your personal information.</DefaultText>

                {/* Section VIII. Other Terms */}
                <Section>VIII. Other Terms</Section>
                <DefaultText>The Privacy Policy is governed by and shall be construed in accordance with the laws provided in our General Terms.</DefaultText>
                <DefaultText>To ensure the security of personal data, Atlas employs various administrative, technical, and physical security measures; however, it is your responsibility to exercise caution and reason when using the Services. You will be personally responsible if such action violates any third party’s privacy or any other rights, or any applicable law. Under no circumstances is Atlas liable for the consequences of your unlawful activities, your willful and negligent activities that violate applicable laws or third-party rights, and any circumstances that may not have been reasonably controlled or foreseen.</DefaultText>
                <DefaultText>Our website may include links to other websites whose privacy practices may be different from ours. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</DefaultText>
                <DefaultText>This Privacy Policy may be modified and updated at any time, at our sole discretion, for any or no reason, and without liability as indicated below. The date of the most current wording of the Privacy Policy is indicated at the top of the text. We ask all users to ensure that they are familiar with the most current wording of our Privacy Policy. The amendment of the Privacy Policy may be communicated to you via means as outlined in the Terms and/or by publishing the updated Privacy Policy on the website. Updates of the Privacy Policy come into force as of the moment when they are published.</DefaultText>
                <DefaultText>You may not assign or transfer your rights or obligations under this Privacy Policy to any third party.</DefaultText>

            </View>
        </View>
    )
}