import React from 'react';
import useAuth, { MasterPrivileges, User } from '../contexts/auth';
import { useLocation } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { satisfies, getSetFromUser, computeNodeFromNames, userHasPrivilege } from '../utility/privilege';
import useToast, { ToastFunction } from '../contexts/toast';

import Unauthorized from '../routes/Unauthorized';

type ProtectedProps = {
    auth: boolean,
    user: User,
    args: Array<string>,
    masterPrivileges: MasterPrivileges,
    push: ToastFunction,
    location: any
}

// todo: move these to separate files.
const conditions = {
    'auth': {
        'validate': ({ auth }: { auth: boolean }) => auth,
        'redirect': () => '/login',
    },
    '!auth': {
        'validate': ({ auth }: { auth: boolean }) => !auth,
        'redirect': () => '/dashboard',
    },
    'registered': {
        'validate': ({ auth, user }: { auth: boolean, user: User }) => auth && user?.profile,
        'redirect': ({ auth, user, push }: ProtectedProps) => {
            if (!auth) { return '/login'; }
            if (!user?.profile) {
                push('Profile registration required.', 'info');
                return '/dashboard/profile';
            }
            return '/'; // To please TypeScript, will never execute.
        }
    },
    'privilege': {
        'validate': ({ auth, user, args, masterPrivileges }: { auth: boolean, user: User, args: Array<string>, masterPrivileges: MasterPrivileges }) => {
            if (!auth || !user?.profile || user.privileges.length == 0) { return false; }

            const set = args.shift() as string;

            return userHasPrivilege(set, args, user, masterPrivileges);
        },
        'redirect': () => { return '/unauthorized' }
    },
    'subscribed': {
        'validate': ({ auth, user, args }: { auth: boolean, user: User, args: Array<string> }) => {
            return auth && user?.subscriptions.includes(args[0]);
        },
        'replace': ({ auth, location }: { auth: boolean, location: any }) => {
            return auth ? <Unauthorized /> : <Navigate to={"/login"} replace state={{ path: location.pathname }} />;
        }
    }
} as { [key: string]: { validate: (payload: ProtectedProps) => boolean, redirect?: (payload: ProtectedProps) => string, replace?: (payload: ProtectedProps) => React.ReactComponentElement<any> } };

export default function Protected({ condition, args, children }: { condition: keyof typeof conditions, args?: Array<string>, children: React.ReactComponentElement<any> }) {
    const { auth, user, masterPrivileges } = useAuth();
    const location = useLocation();
    const push = useToast();

    // Deep copy.
    const _args = args ? JSON.parse(JSON.stringify(args)) : [];

    const payload = { auth, user, args: _args, masterPrivileges, push, location } as ProtectedProps;
    const status = conditions[condition].validate(payload);

    // @ts-ignore
    return status ? children : (conditions[condition].redirect ? <Navigate to={conditions[condition].redirect(payload)} replace state={{ path: location.pathname }} /> : conditions[condition].replace(payload));
}