import React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';

// AsyncStorage.getItem('token')
// AsyncStorage.setItem('token', response.data.token);

const DataContext = React.createContext({} as any);

type DataType = {
    getStatic: (key: string) => Promise<any>,
    setStatic: (key: string, value: any, data: Object, setData: Function) => undefined,
    getEph: (key: string) => any,
    setEph: (key: string, value: any) => undefined,
    dataLink: React.MutableRefObject<number>
}

const _data = {
    static: {},
    eph: {
        // Default values
        dashMenuClosedOverride: false,
    },
} as {
    static: {[key: string]: any},
    eph: {[key: string]: any},
}

// Static storage - stored and retrieved in browser storage.
async function getStatic(key: string) {
    // See if there is local reference.
    if (_data.static.hasOwnProperty(key)) { return _data.static[key]}

    // Attempt retrieval from storage, update local if applicable.
    const value = await AsyncStorage.getItem(key);

    // Evaluate if boolean (from string)
    const trueValue = (value === 'true' || value === 'false') ? value === 'true' : value
    _data.static[key] = trueValue;

    // Return data.
    return trueValue;
}

function setStatic(key: string, value: any, dataObj: any, setData: Function) {
    // Do not refresh if value is the same.
    if (value === _data.static[key]) { return; }

    dataObj.dataLink.current = Date.now();

    // Update local reference.
    _data.static[key] = value;

    // Set storage.
    AsyncStorage.setItem(key, value);

    // Trigger refresh.
    setData({...dataObj})
}

// Ephemeral storage - alive only for application run.

function getEph(key: string) {
    return _data.eph[key];
}

function setEph(key: string, value: any, dataObj: any, setData: Function) {
    // Do not refresh if value is the same.
    if (value === getEph(key)) { return; }

    dataObj.dataLink.current = Date.now();

    _data.eph[key] = value;

    setData({...dataObj});
}


export function DataProvider({ children }: { children: React.ReactNode }) {
    const [data, setData] = React.useState({
        getStatic: async (key: string) => { return await getStatic(key); },
        setStatic: (key: string, value: any) => { setStatic(key, value, data, setData); },
        getEph: (key: string) => { return getEph(key); },
        setEph: (key: string, value: any) => { setEph(key, value, data, setData); },
        dataLink: React.useRef(Date.now())
    } as DataType);

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    );
}

export default function DataConsumer() {
    return React.useContext(DataContext);
}