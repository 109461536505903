import React from 'react';
import { TextInput as ReactTextInput, View, StyleSheet } from 'react-native';
import useTheme from '../contexts/theme';

export default function TextInput({ style, maxLength = 20, placeholder = 'Text', value, secureTextEntry, editable = true, onChangeText }: { style?: Object, maxLength?: number, placeholder?: string, value?: string, secureTextEntry?: boolean, editable?: boolean, onChangeText?: (value: any) => void }) {
    const { theme } = useTheme();
    const [outlineWidth, setOutlineWidth] = React.useState(0);
    const styles = StyleSheet.create({
        input: {
            backgroundColor: theme.background,
            borderRadius: 8,
            padding: 12,
            height: 42,
            fontSize: 16,
            borderColor: theme.accent,
            // outline: 'none',
            color: theme.text.color,
            outlineWidth: 0,
            ...style
        },
        container: {
            outlineStyle: 'solid',
            outlineColor: theme.accent,
            outlineWidth: outlineWidth,
            borderRadius: 8,
        }
    });

    // TODO: Look into placeholder and text not vertically centered.

    return (
        <View style={styles.container}>
            <ReactTextInput 
            editable={editable}
            autoCorrect={false}
            maxLength={maxLength}
            placeholder={placeholder}
            placeholderTextColor={theme.text.secondary}
            selectionColor={theme.accent}
            style={styles.input}
            onFocus={() => setOutlineWidth(2)}
            onBlur={() => setOutlineWidth(0)}
            onChangeText={onChangeText}
            value={value}
            secureTextEntry={secureTextEntry}
            />
        </View>
    );
}