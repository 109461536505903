import React from 'react';
import { View } from 'react-native';
import { DefaultText } from '../components/Text';
import { ModalTitle } from '../components/Modal';
import Divider from '../components/Divider';
import useTheme from '../contexts/theme';
import Title from '../components/Title';
import { Link } from 'react-router-dom';
import useScale from '../contexts/scale';

export default function Construction({ note }: { note?: string }) {
    const { theme } = useTheme();
    const { style } = useScale();

    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ ...style({ width: '80%' }, 'any'), ...style({ width: '60%' }, 'md'), ...style({ width: '56%' }, 'lg'), ...style({ width: '45%' }, 'xl') }}>
                <View style={{ width: 'auto', marginHorizontal: 'auto' }}><Title title="Construction" fontSize={20} /></View>
                <ModalTitle title="🚧 Under Construction" style={{ title: { ...style({ fontSize: 20 }, 'any'), ...style({ fontSize: 28 }, 'sm'), ...style({ fontSize: 36 }, 'lg') } }}>
                    {/* <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <DefaultText font='bold' style={{ fontSize: 18, ...style({ fontSize: 24 }, 'sm'), ...style({ fontSize: 36 }, 'xl') }}>🚧 Under Construction</DefaultText>
                    </View>
                    <Divider style={{ backgroundColor: theme.text.secondary }} /> */}

                    {note && <DefaultText style={{ textAlign: 'center', fontSize: 18, marginBottom: 12, fontStyle: 'italic' }}>{note}</DefaultText>}

                    <DefaultText style={{ textAlign: 'center', fontSize: 14, ...style({ fontSize: 18 }, 'sm') }}>
                        This page isn't quite finished yet.{"\n"}
                        Check back later for more information.
                    </DefaultText>

                    <DefaultText style={{ textAlign: 'center', marginTop: 12, fontSize: 12, ...style({ fontSize: 14 }, 'sm') }}>
                        Find something broken?&nbsp;
                        <Link to="/contact" style={{ color: theme.accent }}>
                            <DefaultText font='bold' style={{ color: theme.accent }}>Contact us</DefaultText>
                        </Link>
                        .
                    </DefaultText>
                </ModalTitle>
            </View>
        </View>
    )
}