import React from 'react';
import { View } from 'react-native';
import useTheme from '../../../contexts/theme';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import ProfileCircle from '../../../components/ProfileCircle';
import { DefaultText } from '../../../components/Text';
import Table from '../../../components/Table';
import { compactDate } from '../../../utility/date';
import Status from '../../../components/InvoiceStatus';

export default function Home() {
    const { theme } = useTheme();
    const { request } = useAuth();
    const push = useToast();

    const [users, setUsers] = React.useState({ data: [] });
    React.useEffect(() => {
        request.current.get('/admin/users')
            .then((response: any) => {
                setUsers(response.data);
            })
            .catch((response: any) => {
                push("Failed to retrieve users.", 'error');
                console.log(response.data)
            });
    }, []);

    return (
        <View style={{}}>
            <Table title="Users" columns={["ID", "Name", "Email", "Registered", "Status", "Created At"]} data={users.data} link="/admin/users/" linkRoute="uuid" style={{ entryRow: { paddingVertical: 8 } }} populate={
                (entry: any) => { return [entry.id, `${entry.profile?.firstName || '---'} ${entry.profile?.lastName || '---'}`, entry.email, entry.isRegistered,  <Status status={entry.status} type={'accountState'} style={{ fontSize: 10, marginLeft: 'auto', alignSelf: 'flex-end', paddingVertical: 2, paddingHorizontal: 4 }} />, compactDate(entry.createdAt)] }
            } />
        </View >
    )
}

// set children [0] and [1] to be before and after respectively.