import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import useTheme from '../contexts/theme';
import { DefaultText } from './Text';
import useScale from '../contexts/scale';

import useToast from '../contexts/toast';
import Button from './Button';

export default function CheckoutForm({ returnURL }: { returnURL: string }) {
    const stripe = useStripe();
    const elements = useElements();
    const push = useToast();
    const { theme } = useTheme();
    const { style } = useScale();

    async function confirmPayment() {
        if (!stripe || !elements) { return; }

        const results = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnURL
            }
        })

        if (results.error) {
            push(results.error.message, 'error');
            console.log(results.error.message);
        }
    }

    return (
        <View>
            <PaymentElement />
            <Button title={'Confirm Payment'} style={{ button: { ...style({width: '100%'}, 'any'), ...style({width: '50%'}, 'sm'), paddingVertical: 8, marginTop: 12, marginBottom: 6 } }} onPress={() => { confirmPayment(); }}></Button>
            {/* By registering an account... */}
            <DefaultText style={{ color: theme.text.secondary, fontSize: 12 }}>
                By paying an invoice to Atlas Industries, Inc. you agree to our&nbsp;
                <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                </Link>
                &nbsp;and&nbsp;
                <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                </Link>
            </DefaultText>
        </View>

    )
}