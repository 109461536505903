import { MasterPrivileges, User } from '../contexts/auth';

/**
 * Compares whether the permission node satisfies the reference node.
 * @param node The hexadecimal permission node to compare to the reference node.
 * @param referenceNode The hexadecimal reference node to be compared against.
 */
export function satisfies(node: string, referenceNode: string): boolean {
    // parseInt will default to base 16 if '0x' is present, however we add it for good measure.
    const hexReferenceNode: bigint = BigInt(parseInt(referenceNode, 16));
    return (BigInt(parseInt(node, 16)) & hexReferenceNode) === hexReferenceNode;
}

/**
 * Computes the privilege node from the privilege names.
 * @param {string} set - The privilege set to compute the node from.
 * @param {Array<string>} names - The privilege name requirements.
 * @returns {string} - The computed privilege node.
 */
export function computeNodeFromNames(set: string, names: string[], masterPrivileges: MasterPrivileges): string {
    let nodeSum: bigint = 0x0n;
    for (const node of names) {
        // Validate privilege node.

        // If unknown node, return administrative requirement.
        // - however this should never happen, as userHasPrivilege should be called.
        if (!masterPrivileges[set]?.[node]) { return '0xffffffff'; };

        // Add node to sum.
        nodeSum |= BigInt(masterPrivileges[set][node]);
    }

    // Convert sum to hex string.
    return '0x' + nodeSum.toString(16);
}

/**
 * Retrieves the privilege set from the user.
 * @param {string} set - The privilege set to retrieve.
 * @note The privileges must be loaded on the user first.
 * @returns {string} - The privilege set.
 */
export function getSetFromUser(set: string, user: User): string {
    if (!user?.privileges) { return '0x0'; } // If no privileges, return no privileges.

    for (const entry of user.privileges) { if (entry.set === set) { return entry.privilege; } }
    return '0x0';
}

export function userHasPrivilege(set: string, privilege: Array<string>, user: User, masterPrivileges: MasterPrivileges) {
    if (!masterPrivileges[set]) { return false; }
    return satisfies(getSetFromUser(set, user), computeNodeFromNames(set, privilege, masterPrivileges));
}