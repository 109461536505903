import React from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { DefaultText } from '../components/Text';
import useTheme from '../contexts/theme';

export default function StatusBar({ title, status, style }: { title: string, status: number, style?: {container: Object} }) {
    const { theme } = useTheme();

    const width = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
        Animated.timing(width, {
            toValue: status,
            duration: 500,
            useNativeDriver: false
        }).start();
    }, [status]);

    const styles = StyleSheet.create({
        container: { ...style?.container } as Object,
        barWrapper: {
            width: '100%',
            height: 12,
            backgroundColor: theme.background,
            borderColor: theme.background,
            borderWidth: 3,
            borderRadius: 20,
        },
        bar: {
            backgroundColor: theme.accent,
            height: '100%',
            width: width.interpolate({
                inputRange: [0, 33, 66, 100],
                outputRange: ['0%', '16.5%', '49.5%', '100%'],
            }) as any, // temp error resolution.
            borderRadius: 20,
        }
    });

    return (
        <View style={styles.container}>
            <DefaultText font='medium' style={{ marginBottom: 4 }}>{title}</DefaultText>
            <View style={styles.barWrapper}>
                <Animated.View style={styles.bar}></Animated.View>
            </View>
        </View>
    )
}