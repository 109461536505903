import React from 'react';
import { Text, StyleSheet, Animated } from 'react-native';
import useTheme from '../contexts/theme';
import Interactive from './Interactive';
import { AnimateRef } from '../utility/animation';
import { useNavigate, Navigate } from 'react-router-dom';
type fonts = 'regular' | 'semibold' | 'medium' | 'bold';

export function DefaultText({ font = 'regular', style, children, animated = false }: { font?: fonts, style?: any, children?: any, animated?: boolean }) { // string | string[] | React.ReactComponentElement<any> 
    const { theme } = useTheme();
    const styles = StyleSheet.create({
        text: {
            fontFamily: 'nunito-' + font,
            color: theme.text.color,
            // lineHeight: 24,
            ...style
        }
    });

    const _Text = animated ? Animated.Text : Text;
    return (
        <_Text style={styles.text}>{children}</_Text>
    );
}

type TextInteractiveCallbackFn = (setStyle: Function, style: any) => void;
type TextInteractions = {
    onEnter?: TextInteractiveCallbackFn,
    onLeave?: TextInteractiveCallbackFn,
    onPress?: TextInteractiveCallbackFn
}
export function TextInteractive({ font, style, children, interaction }: { font?: fonts, style?: any, children?: any, interaction?: TextInteractions }) {

    // Opacity animation.
    const opacityValue = React.useRef(new Animated.Value(1)).current;
    function setOpacity(opacity: number) { AnimateRef(opacityValue, opacity, 100).start(); }

    // Updatable text styles.
    const [textStyle, setStyle] = React.useState({ ...style, opacity: opacityValue });
    React.useEffect(() => { setStyle({ ...style, opacity: opacityValue }); }, [style])

    // Callback functions.
    const { onEnter, onLeave, onPress } = { onEnter: () => { setOpacity(0.8); }, onLeave: () => { setOpacity(1); }, ...interaction };

    function cb(fn: TextInteractiveCallbackFn | undefined) { fn?.(setStyle, textStyle); }
    return (
        <Interactive onEnter={() => { cb(onEnter); }} onLeave={() => { cb(onLeave); }} onPress={() => { cb(onPress); }} >
            <DefaultText font={font} animated={true} style={textStyle}>{children}</DefaultText>
        </Interactive >
    );
}

export function TextLinkInteractive({ font, style, children, interaction, link, navigate }: { font?: fonts, style?: any, children?: any, interaction?: TextInteractions, link: string, navigate?: any }) {
    return <TextInteractive font={font} style={style} interaction={{ onPress: () => { navigate(link); }, ...interaction }}>{children}</TextInteractive>
}

export function ItalicText({ font = 'regular', style, children, animated = false }: { font?: fonts, style?: any, children?: any, animated?: boolean }) {
    return <DefaultText font={font} style={{ fontStyle: 'italic', ...style }} animated={animated}>{children}</DefaultText>
}

export function TextBreak() { return (<DefaultText>{'\n'}</DefaultText>) }
export function TextSpace() { return (<DefaultText>{' '}</DefaultText>) }
export function BulletPoint() { return (<DefaultText>{'\u2022'}<TextSpace/></DefaultText>)}