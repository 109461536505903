export function formatDate(time: number) {
    const date = new Date(time * 1000);
    return `${new Intl.DateTimeFormat("en-US", { month: 'long' }).format(date)} ${date.getDate()}, ${date.getFullYear()}`;
}

export function compactDate(timestamp: string, useLocalTimeZone = true) {
    const date = new Date(Date.parse(timestamp));
    const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
        timeZone: useLocalTimeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC'
    };

    // @ts-ignore
    return date.toLocaleString('en-US', options);
}

export function compactDay(timestamp: number) {
    const date = new Date(timestamp);
    const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
    };

    // @ts-ignore
    return date.toLocaleString('en-US', options);
}