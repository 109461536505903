import React from 'react';
import { View } from 'react-native';
import useTheme from '../contexts/theme';
import Button from './Button';
import { DefaultText } from './Text';
import { compactDate } from '../utility/date';
import useScale from '../contexts/scale'
import useToast from '../contexts/toast';
import useAuth from '../contexts/auth';
import { handleFailure } from '../utility/network';
import TextInput from './TextInput';

export type Token = {
    id: number,
    name: string,
    type: string,
    ipAddress: string,
    userAgent: string | null,
    usedAt: string | null,
    expiresAt: string | null,
    createdAt: string,
    location: any
}

function getUserAgent(userAgent: string) {
    if (!userAgent) { return null; }

    try {
        // Temp
        const os = userAgent.match(/(iPhone|iPad|Android|Windows Phone|BlackBerry|WebOS|Opera Mini|Opera Mobile|Kindle|Silk|Nokia|Symbian|Palm|iPod|iPega|Neo|macOS|Linux|Chrome OS|FreeBSD|OpenBSD|NetBSD|Solaris|Haiku|Windows NT|Windows Phone|Windows CE|Windows 10|Windows 11|Tizen|KaiOS|WebOS TV)/i);
        const browser = userAgent.match(/(Chrome|Firefox|Safari|Opera|IE|Edge|Brave|Vivaldi|Opera GX|UC Browser|Dolphin|Samsung Internet|UC Browser Mini|Amazon Silk|Yandex Browser)/i);

        return `${browser ? browser[0] : "Unknown"} on ${os ? os[0] : "Unknown"}`;
    } catch (err) {
        return "Unknown"
    }
}

export default function Token({ token, refresh }: { token: Token, refresh: Function }) {
    const { style } = useScale();
    const { theme } = useTheme();
    const push = useToast();
    const { request, requestProfile } = useAuth();

    function revoke() {
        request.current.delete(`/users/tokens/${token.id}`, { data: { type: token.type } })
            .then(async (response: any) => {
                push("Token revoked", "success");
                const profile = await requestProfile();
                if (profile) { refresh(); }
            })
            .catch((response: any) => { handleFailure(response, push); });
    }

    return (
        <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ height: '100%', flexDirection: 'row', alignItems: 'center' }}>

                    {/* Entry Dot */}
                    <View style={{ width: 10, height: 10, borderRadius: 40, backgroundColor: theme.accent, borderRightColor: theme.alternativeSecondary }} />

                    {/* Vertical Divider */}
                    <View style={{ borderColor: theme.alternative, borderWidth: 1, borderRadius: 4, marginHorizontal: 8, height: '100%', width: 2 }}></View>

                    {/* Token Data */}
                    <View>
                        {/* Token Name */}
                        <DefaultText font='bold' style={{ fontSize: 16 }}>{token.name === "Opaque Access Token" ? "Standard User Token" : token.name}</DefaultText>

                        {/* Last used */}
                        <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>Last used {token.usedAt ? compactDate(token.usedAt) : "Unknown"}</DefaultText>

                        {/* Created */}
                        <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>Created {compactDate(token.createdAt)}</DefaultText>

                        {/* Expires */}
                        {token.expiresAt && <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>Expires {compactDate(token.expiresAt)}</DefaultText>}

                        {/* Location */}
                        {
                            token.location && (
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
                                    <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>Location: </DefaultText>
                                    <DefaultText style={{ fontSize: 12 }}>{token.location.city}, {token.location.regionName}, {token.location.country}</DefaultText>
                                </View>
                            )
                        }
                        {
                            token.userAgent && (
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>User Agent: </DefaultText>
                                    <DefaultText style={{ fontSize: 12 }}>{getUserAgent(token.userAgent)}</DefaultText>
                                </View>
                            )
                        }

                        {/* Revoke Button */}
                        {!style({}, 'xl') && (<View style={{ marginTop: 8, width: 'fit-content' }}>
                            <Button title="Revoke" onPress={revoke} style={{ button: { paddingVertical: 4, paddingHorizontal: 16 } }} />
                        </View>)}
                    </View>
                </View>

                {/* Revoke Button */}
                {style({}, 'xl') && (<View style={{ width: 'fit-content' }}>
                    <Button title="Revoke" onPress={revoke} style={{ button: { paddingVertical: 4, paddingHorizontal: 16 } }} />
                </View>)}
            </View>
        </View>
    )

}

export function CreateToken({ refresh }: { refresh: (token: string) => void }) {
    const { style } = useScale();
    const { theme } = useTheme();
    const push = useToast();
    const { request } = useAuth();

    function create() {
        console.log(name.current, expiration.current)
        request.current.post('/users/tokens/service', { name: name.current, expiration: expiration.current })
            .then((response: any) => {
                push("Service token created", "success");
                refresh(response.data.data.token);
            })
            .catch((response: any) => { handleFailure(response, push); });
    }

    const name = React.useRef<string | null>(null);
    const expiration = React.useRef<string | null>(null);

    return (
        <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ height: '100%', flexDirection: 'row', alignItems: 'center' }}>

                    {/* Entry Dot */}
                    <View style={{ width: 10, height: 10, borderRadius: 40, backgroundColor: theme.accent, borderRightColor: theme.alternativeSecondary }} />

                    {/* Vertical Divider */}
                    <View style={{ borderColor: theme.alternative, borderWidth: 1, borderRadius: 4, marginHorizontal: 8, height: '100%', width: 2 }}></View>

                    {/* Token Data */}
                    <View style={{ width: 'min-content', ...style({ width: null }, 'md') }}>
                        {/* Token Name */}
                        <DefaultText font='bold' style={{ fontSize: 16 }}>Pending Token</DefaultText>
                        <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>*Once the service token is created, the token will only be displayed once.</DefaultText>

                        <View style={{ marginTop: 8, width: 'fit-content', ...style({ width: null }, 'md') }}><TextInput onChangeText={(val) => { name.current = val; }} placeholder="Token Name" style={{ height: 35 }} /></View>
                        <View style={{ marginTop: 8, width: 'fit-content', ...style({ width: null }, 'md') }}><TextInput onChangeText={(val) => { expiration.current = val; }} placeholder="Token TTL (ms)" style={{ height: 35 }} /></View>
                        {/* Revoke Button */}
                        {!style({}, 'xl') && (<View style={{ marginTop: 8, width: 'fit-content' }}>
                            <Button title="Create" onPress={create} style={{ button: { paddingVertical: 4, paddingHorizontal: 16 } }} />
                        </View>)}
                    </View>
                </View>

                {/* Revoke Button */}
                {style({}, 'xl') && (<View style={{ width: 'fit-content' }}>
                    <Button title="Create" onPress={create} style={{ button: { paddingVertical: 4, paddingHorizontal: 16 } }} />
                </View>)}
            </View>
        </View>
    )
}

export function RawToken({ token }: { token: string }) {
    const { theme } = useTheme();
    const { style } = useScale();

    return (
        <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ height: '100%', flexDirection: 'row', alignItems: 'center' }}>

                    {/* Entry Dot */}
                    <View style={{ width: 10, height: 10, borderRadius: 40, backgroundColor: theme.accent, borderRightColor: theme.alternativeSecondary }} />

                    {/* Vertical Divider */}
                    <View style={{ borderColor: theme.alternative, borderWidth: 1, borderRadius: 4, marginHorizontal: 8, height: '100%', width: 2 }}></View>

                    {/* Token Data */}
                    <View style={{ width: 'min-content', ...style({ width: null }, 'md') }}>
                        {/* Token Name */}
                        <DefaultText font='bold' style={{ fontSize: 16 }}>New Token</DefaultText>
                        <DefaultText style={{ fontSize: 12, color: theme.text.secondary }}>*This token will only be displayed once.</DefaultText>
                        <DefaultText style={{ fontSize: 14, marginTop: 4 }}>{token}</DefaultText>
                    </View>
                </View>
            </View>
        </View>
    )
}