import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from './Text';
import useTheme from '../contexts/theme';

const defaultInterval = 500;
const emojis = {
    wave: [
        '👋',
        '👋🏻',
        '👋🏼',
        '👋🏽',
        '👋🏾',
        '👋🏿',
    ],
    clock: [
        '🕛',
        '🕐',
        '🕑',
        '🕒',
        '🕓',
        '🕔',
        '🕕',
        '🕖',
        '🕗',
        '🕘',
        '🕙',
        '🕚',
    ]
}
export default function AnimatedEmoji({ emoji, interval = defaultInterval }: { emoji: keyof typeof emojis, interval?: number }) {
    const [currentEmoji, setEmoji] = React.useState(emojis[emoji][0]);
    const count = React.useRef<number>(0);

    React.useEffect(() => {
        const int = setInterval(() => {
            count.current = count.current + 1;
            count.current %= emojis[emoji].length;
            setEmoji(emojis[emoji][count.current]);
        }, interval);

        return () => { clearInterval(int); }
    }, [])

    return (
        <DefaultText>{ currentEmoji }</DefaultText>
    );
}