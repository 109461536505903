import { useLocation } from 'react-router-dom';

export function getTree() {
    const tree = useLocation().pathname.split('/').filter((item) => item !== '');
    tree.unshift('/');
    return tree;
}

export function isRouteActive(path: string, tree: string[]) {
    return (tree.length > 1 ? '/' : '') + path === tree.join('/')
}