import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from '../components/Text';
import CheckoutForm from '../components/CheckoutForm';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../contexts/auth';
import useToast from '../contexts/toast';
import { STRIPE_KEY, WEB_LINK } from '@env';
import { loadStripe } from '@stripe/stripe-js';
import useTheme from '../contexts/theme';
import { Elements } from '@stripe/react-stripe-js';
import Modal from '../components/Modal';
import Divider from '../components/Divider';
import Title from '../components/Title';
import { InvoiceDetails } from '../components/InvoiceDetails';
import useScale from '../contexts/scale';

const stripePromise = loadStripe(STRIPE_KEY);
export default function Checkout() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { request } = useAuth();
    const push = useToast();
    const { style } = useScale();

    const [paymentIntent, setPaymentIntent] = React.useState(null);

    // Validate initial state.
    React.useEffect(() => {
        if (state?.object !== 'invoice') { return navigate('/dashboard/invoices'); }
        if (state.status !== 'open') { return navigate('/dashboard/invoices'); }
        request.current.get('/billing/stripe/payment-intents/' + state.payment_intent)
            .then((response: any) => { setPaymentIntent(response.data.data); })
            .catch((response: any) => {
                push("Failed to fetch payment intent.", "error")
                console.log(`Failed to fetch payment intent: ${response.data}`);
                navigate('/dashboard/invoices');
            });
    }, []);

    // Loading...
    if (!paymentIntent) { return <View></View> }

    const options = {
        clientSecret: paymentIntent.client_secret,
        appearance: {
            theme: 'flat',
            variables: {
                fontFamily: ' "Gill Sans", sans-serif',
                fontLineHeight: '1.5',
                borderRadius: '10px',
                colorBackground: theme.alternative,
                colorPrimaryText: theme.accent,
                colorText: theme.text.color,
            },
            rules: {
                '.Block': {
                    backgroundColor: 'var(--colorBackground)',
                    boxShadow: 'none',
                    padding: '12px'
                },
                '.Input': {
                    padding: '12px'
                },
                '.Input:disabled, .Input--invalid:disabled': {
                    color: 'lightgray'
                },
                '.Tab': {
                    padding: '10px 12px 8px 12px',
                    border: 'none'
                },
                '.Tab--selected, .Tab--selected:hover': {
                    border: '2px solid',
                    borderColor: theme.accent,
                    boxShadow: 'none',
                    backgroundColor: theme.background,
                },
                '.Tab--selected:focus': { boxShadow: 'none' },
                '.Tab:focus': { boxShadow: 'none' },
                '.Input:focus': {
                    border: '2px solid',
                    borderColor: theme.accent,
                    boxShadow: 'none'
                },
                '.Label': {
                    fontWeight: '500'
                }
            }
        }

    }

    return (
        <View >

            {/* Header */}
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                    <Title title="Checkout" />
                </View>
            </View>

            <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 12, marginBottom: 24 }} />

            <View style={{ ...style({ flexDirection: 'column-reverse' }, 'any'), ...style({ flexDirection: 'row' }, 'lg') }}>

                {/* Checkout Form */}
                <View style={{ ...style({ flex: 'auto' }, 'any'), ...style({ flex: 3 }, 'lg') }}>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm returnURL={`${WEB_LINK}/dashboard/invoices/view-invoice/${state.id}`} />
                    </Elements>
                </View>

                {/* Invoice Information */}
                <View style={{ ...style({ flex: 'auto', marginBottom: 24 }, 'any'), ...style({ flex: 1, marginLeft: 24, marginBottom: 0 }, 'lg'), }}>
                    <InvoiceDetails invoice={state} />
                </View>
            </View>
        </View>
    )
}