import React from 'react';
import { View, StyleSheet } from 'react-native';
import { BulletPoint, DefaultText, ItalicText, TextBreak, TextLinkInteractive, TextSpace } from '../../../components/Text';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import { Section, Title, Styles } from '../Components';

export default function GeneralTerms() {
    const navigate = useNavigate();
    const { theme } = useTheme();

    return (
        <View style={{}}>
            {/* Title */}
            <Title title="GDPR" date="01/29/2023" useDivider={false} />

            {/* Content */}
            <View style={{ gap: 16 }}>

                {/* Section I. Information for Users in the European Economic Area (EEA) */}
                <Section>I. Information for Users in the European Economic Area (EEA)</Section>
                <DefaultText>Please note that unless expressly noted otherwise, Atlas (as defined in the General Terms) shall act as the responsible personal data controller for any data processed.</DefaultText>
            
                {/* Section II. Grounds for Data Processing */}
                <Section>II. Grounds for Data Processing</Section>
                <DefaultText>Your personal data is mainly processed for the purpose of providing you the best Services under the legal basis of performance of the contract between you and Atlas, as provided in our General Terms.</DefaultText>
                
                <DefaultText>We also process your personal data under the legal basis of our legitimate interest:</DefaultText>
                <View style={Styles.indent}>
                    <DefaultText><BulletPoint/> to properly administer business communication with users;</DefaultText>
                    <DefaultText><BulletPoint/> to operate our services and ensure their secure, reliable, and robust performance;</DefaultText>
                    <DefaultText><BulletPoint/> to receive knowledge of how our website and applications are being used.</DefaultText>
                </View>
                <DefaultText>Atlas may process your personal data for marketing purposes in the following cases: when we obtain your consent for such processing (the legal basis for processing, in this case, is your consent) or when applicable law permits us to contact you without separate consent (under the legal basis of a legitimate interest).</DefaultText>

                {/* Section III. Term for Storing Personal Data */}
                <Section>III. Term for Storing Personal Data</Section>
                <DefaultText>We keep your personal data for as long as reasonably necessary for the purposes set out in this Privacy Policy. The data might be kept longer if required for tax, accounting, payment processing purposes, to ensure we would be able to defend or raise a claim, or where we have a specific need - though we will generally not keep personal data for longer than two years following the last date of communication with you, unless the data is necessary in order to provide the Service.</DefaultText>

                {/* Section IV. International Transfers */}
                <Section>IV. International Transfers</Section>
                <DefaultText>Atlas is based outside the EEA and has service providers established in various countries. These locations may not guarantee the same level of protection of personal data as the one in which you live. We assess the circumstances involving all cross-border data transfers and have suitable safeguards in place to require that your personal data will remain protected in accordance with this Privacy Policy. If necessary, we use standard contractual clauses approved by the European Commission to transfer your personal information from the EEA to other countries.</DefaultText>

                {/* Section V. Your Rights */}
                <Section>V. Your Rights</Section>
                <DefaultText>Users based in the European Economic Area (EEA) receive additional rights related to their personal data. You may: </DefaultText>
                <View style={Styles.indent}>
                    <DefaultText><BulletPoint/> request us to erase your personal data;</DefaultText>
                    <DefaultText><BulletPoint/> object to the processing of your personal data which is done on the basis of our legitimate interests (e.g. for marketing purposes);</DefaultText>
                    <DefaultText><BulletPoint/> request us to provide you with a copy of your personal data in a structured, commonly used, and machine-readable format or to transmit (if technically feasible) your personal data to another controller (only where our processing is based on your consent and carried out by automated means);</DefaultText>
                    <DefaultText><BulletPoint/> restrict the processing of your personal data (when there is a legal basis for doing so);</DefaultText>
                    <DefaultText><BulletPoint/> withdraw your consent where processing is based on a consent you have previously provided;</DefaultText>
                    <DefaultText><BulletPoint/> exercise your rights by contacting us directly or, if all else fails, by lodging a complaint with a supervisory authority.</DefaultText>
                </View>
            </View>
        </View>
    )
}





