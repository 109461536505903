import React from 'react';
import { View } from 'react-native';
import { DefaultText } from '../../../components/Text';
import Modal, { ModalTitle } from '../../../components/Modal';
import Button from '../../../components/Button';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import { handleFailure } from '../../../utility/network';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import Divider from '../../../components/Divider';
import { Octicons } from '@expo/vector-icons';
import useScale from '../../../contexts/scale'

import TokenComponent, { CreateToken, RawToken, Token } from '../../../components/Token';

export default function Settings() {
    const { logout, request, user } = useAuth();
    const push = useToast();
    const navigate = useNavigate();
    const { theme, toggle } = useTheme();
    const { style } = useScale();
    const [createToken, setCreateToken] = React.useState(false);

    const [tokens, setTokens] = React.useState({ api: [], lease: [], service: [] } as { api: Token[], lease: Token[], service: Token[] });

    const rawServiceToken = React.useRef<string | null>(null);

    function requestTokens() {
        request.current.get('/users/tokens')
            .then((response: any) => {
                response.data.data.lease = response.data.data.lease.filter((token: Token) => token.name !== "Administration");
                setTokens(response.data.data);
            })
            .catch((response: any) => { handleFailure(response, push); });
    }

    React.useEffect(() => {
        requestTokens();
    }, []);

    const themeButton = (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Octicons style={{ marginRight: 8, transform: [{ scaleX: -1 }] }} name={theme.name == 'dark' ? 'moon' : 'sun'} size={14} color={theme.text.color} />
            <DefaultText>{theme.name == 'dark' ? 'Dark mode' : 'Light mode'}</DefaultText>
        </View>
    )

    return (
        <View style={{ flexDirection: 'column', ...style({ flexDirection: 'row', justifyContent: 'space-between' }, 'md') }}>

            {/* Settings */}
            <Modal style={{ container: { marginBottom: 12 }, ...style({ container: { height: 'fit-content', width: '49.5%' } }, 'md') }}>
                {/* Title */}
                <DefaultText font='bold' style={{ fontSize: 20, textTransform: 'uppercase' }}>⚙️ Settings</DefaultText>
                <DefaultText font='semibold' style={{ color: theme.text.secondary }}>General Atlas client settings.</DefaultText>
                <Divider style={{ backgroundColor: theme.alternativeSecondary }} />

                {/* Theme */}
                <View style={{ width: 'fit-content' }}>
                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>{theme.name == 'dark' ? '🌝' : '🌞'} Theme</DefaultText>
                    <DefaultText font='semibold' style={{ color: theme.text.secondary }}>Toggle the platform's theme.</DefaultText>

                    <View style={{ marginTop: 12 }}>
                        <Button title={themeButton} onPress={() => { toggle(); }} />
                    </View>
                </View>
                <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 16 }} />

                {/* Password reset */}
                <View style={{ width: 'fit-content' }}>
                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🔑 Reset Password</DefaultText>
                    <DefaultText font='semibold' style={{ color: theme.text.secondary }}>Send a password reset request to your email.</DefaultText>

                    <View style={{ marginTop: 12 }}>
                        <Button title="Request password reset" onPress={() => {
                            request.current.post('/users/reset-password-request', { email: user!.email })
                                .then((response: any) => { push('Password reset request sent.', 'success'); })
                                .catch((response: any) => { handleFailure(response, push) });
                        }} />
                    </View>
                </View>
                <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 16 }} />

                {/* Logout */}
                <View style={{ width: 'fit-content' }}>
                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🔒 Logout</DefaultText>
                    <DefaultText font='semibold' style={{ color: theme.text.secondary }}>Log out of your current account session.</DefaultText>

                    <View style={{ marginTop: 12 }}>
                        <Button title="Log out" onPress={async () => {
                            const req = await logout();
                            if (req.success) {
                                push('Logged out successfully', 'success');
                                navigate('/login');
                            } else {
                                handleFailure(req.response, push);
                            }
                        }} />
                    </View>
                </View>
            </Modal>

            {/* Sessions */}
            <Modal style={{ ...style({ container: { width: '49.5%' } }, 'md') }}>
                {/* Title */}
                <DefaultText font='bold' style={{ fontSize: 20, textTransform: 'uppercase' }}>💻 Sessions</DefaultText>
                <DefaultText font='semibold' style={{ color: theme.text.secondary }}>Manage Atlas account access.</DefaultText>
                <Divider style={{ backgroundColor: theme.alternativeSecondary }} />

                {/* User Tokens */}
                <View style={{}}>
                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>👤 User Tokens</DefaultText>
                    <DefaultText font='semibold' style={{ color: theme.text.secondary, marginBottom: 12 }}>User access tokens.</DefaultText>

                    {tokens.api.length > 0 && tokens.api.map((token: Token, index: number) => {
                        return (
                            <View key={index} style={{ marginBottom: 8 }}>
                                <TokenComponent token={token} refresh={requestTokens} />
                            </View>
                        )
                    })}

                    {
                        tokens.api.length == 0 && (
                            <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
                                <DefaultText font='semibold' style={{ color: theme.text.secondary }}>No user tokens found.</DefaultText>
                            </View>
                        )
                    }
                </View>
                <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 16 }} />


                {/* Lease Tokens */}
                <View style={{}}>
                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🏷️ Leased Tokens</DefaultText>
                    <DefaultText font='semibold' style={{ color: theme.text.secondary, marginBottom: 12 }}>Tokens leased to other services.</DefaultText>

                    {tokens.lease.length > 0 && tokens.lease.map((token: Token, index: number) => {
                        return (
                            <View key={index} style={{ marginBottom: 8 }}>
                                <TokenComponent token={token} refresh={requestTokens} />
                            </View>
                        )
                    })}

                    {
                        tokens.lease.length == 0 && (
                            <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
                                <DefaultText font='semibold' style={{ color: theme.text.secondary }}>No leased tokens found.</DefaultText>
                            </View>
                        )
                    }
                </View>
                <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 16 }} />

                {/* Service Tokens */}
                <View style={{}}>
                    <View style={{ ...style({ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }, 'md'), marginBottom: 12 }}>
                        <View>
                            <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🔱 Service Tokens</DefaultText>
                            <DefaultText font='semibold' style={{ color: theme.text.secondary }}>Tokens created for external use.</DefaultText>
                        </View>
                        {!createToken && (<View style={{ marginTop: 4, ...style({ marginTop: 0 }, 'md') }}>
                            <Button title="Create" onPress={() => { setCreateToken(true); }} style={{ button: { paddingVertical: 4, paddingHorizontal: 16 } }} />
                        </View>)}
                    </View>

                    {createToken && (
                        <View style={{marginBottom: 8}}>
                            <CreateToken refresh={(token: string) => { console.log(token); rawServiceToken.current = token; setCreateToken(false); requestTokens(); }} />
                        </View>
                    )}

                    {rawServiceToken.current && (
                        <View style={{ marginBottom: 8 }}>
                            <RawToken token={rawServiceToken.current} />
                        </View>
                    )}

                    {tokens.service.length > 0 && tokens.service.map((token: Token, index: number) => {
                        return (
                            <View key={index} style={{ marginBottom: 8 }}>
                                <TokenComponent token={token} refresh={requestTokens} />
                            </View>
                        )
                    })}

                    {
                        tokens.service.length == 0 && (
                            <View style={{ paddingVertical: 8, paddingHorizontal: 8, borderRadius: 8, backgroundColor: theme.alternativeSecondary }}>
                                <DefaultText font='semibold' style={{ color: theme.text.secondary }}>No service tokens found.</DefaultText>
                            </View>
                        )
                    }
                </View>
            </Modal>
        </View>
    )
}