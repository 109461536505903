import React from 'react';
import { View, Image, Platform } from 'react-native';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import Title from '../../../components/Title';
import { InvoiceDetails } from '../../../components/InvoiceDetails';
import Button from '../../../components/Button';
import useAuth from '../../../contexts/auth';
import { DefaultText } from '../../../components/Text';
import Modal from '../../../components/Modal';
import Divider from '../../../components/Divider';
import Status from '../../../components/InvoiceStatus';
import { useParams } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import printJS from 'print-js';
import useScale from '../../../contexts/scale';
import { Dimensions } from 'react-native';
import useData from '../../../contexts/data';

type Invoice = {
    invoice: any,
    pdf: string,
    png: { source: string, size: { width: number, height: number } }
}

async function getInvoiceData(state: any, id: string, request: any): Promise<Invoice> {
    const invoice = { invoice: state } as Invoice;

    if (id) {
        await request.current.get(`/billing/stripe/invoices/${id}`)
            .then((response: any) => { invoice.invoice = response.data.data; });

        const history = createBrowserHistory()
        history.push('/dashboard/invoices/view-invoice', invoice.invoice)
        history.go()
    }

    await request.current.get(`/billing/invoices/${invoice.invoice.id}/document`)
        .then((response: any) => {
            const pdf = "" + response.data.data.pdf;
            const png = {
                source: "data:image/png;base64," + response.data.data.png[0].base64,
                size: {
                    width: Number(response.data.data.png[0].size.split('x')[0]),
                    height: Number(response.data.data.png[0].size.split('x')[1])
                }
            }
            invoice.pdf = pdf;
            invoice.png = png;
        });
    return invoice;
}

export default function ViewInvoice() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { request } = useAuth();
    const { style } = useScale();
    const { setEph } = useData();

    const { id } = useParams();

    const [invoice, setInvoice] = React.useState({} as Invoice);

    const [scale, setsScale] = React.useState(1);

    React.useEffect(() => {
        if (!id && state?.object !== 'invoice') { return navigate('/dashboard/invoices'); }

        getInvoiceData(state, id, request)
            .then((invoice: Invoice) => { setInvoice(invoice); })
            .catch(() => { return navigate('/dashboard/invoices'); })

        // Disable sidebar
        setEph('dashMenuClosedOverride', true);
        
        // Re-enable sidebar if previously enabled
        return (() => { setEph('dashMenuClosedOverride', false); });
    }, []);

    React.useEffect(() => {

        setsScale(Dimensions.get('window').width / 1920);
        // console.log(Dimensions.get('window').width, scale.current)
    }, [Dimensions.get('window').width])

    if (!invoice.invoice) { return <View></View> }

    return (
        <View >

            {/* Header */}
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                    <Title title="Invoice" />
                </View>
            </View>

            {/* <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 12, marginBottom: 24 }} /> */}

            <View style={{ ...style({ flexDirection: 'column-reverse', gap: 20, alignItems: 'center' }, 'any'), ...style({ flexDirection: 'row', justifyContent: 'space-between', alignItems: null, gap: 0 }, 900) }}>
                
                {/* <View style={{ ...style({ flex: 'auto', }, 'any'), ...style({ flex: 2 }, 'sm') }}> */}
                <View style={{...style({ width: '90%', }, 'any'), ...style({ width: '30%' }, 900), ...style({ width: '25%' }, 'lg'), ...style({ width: '22%' }, '2xl')}}>
                    <Modal>
                        {/* Header */}
                        <DefaultText font='medium' style={{ fontSize: 16 }}>Document Options</DefaultText>
                        <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 8 }} />

                        <DefaultText style={{ marginBottom: 4 }}>Download invoice:</DefaultText>
                        <Button title="Download" icon="download" onPress={() => {
                            if (Platform.OS !== 'web') { return; }
                            const link = document.createElement('a');
                            link.href = 'data:application/p;base64,' + invoice.pdf;
                            link.setAttribute('download', `INVOICE-${invoice.invoice.number}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }} />

                        <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 16, marginBottom: 12 }} />

                        <DefaultText style={{ marginBottom: 4 }}>Print invoice:</DefaultText>
                        <Button title="Print" icon="desktop-download" onPress={() => {
                            if (Platform.OS !== 'web') { return; }
                            printJS({
                                printable: invoice.pdf,
                                type: 'pdf',
                                base64: true
                            });
                        }} />
                    </Modal>
                </View>

                {/* <View style={{ ...style({ flex: 'auto', }, 'any'), ...style({ flex: 5 }, 'sm'), flexDirection: 'row', justifyContent: 'center' }}> */}
                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                    {invoice.png && <Image source={{ uri: invoice.png.source }} style={{
                        ...style({ width: invoice.png.size.width / 2.5, height: invoice.png.size.height / 2.5 }, 'any'),
                        ...style({ width: invoice.png.size.width / 1.5, height: invoice.png.size.height / 1.5}, 'sm'),
                        ...style({ width: invoice.png.size.width / 2.5, height: invoice.png.size.height / 2.5}, 900),
                        ...style({ width: invoice.png.size.width * scale, height: invoice.png.size.height * scale }, 'lg'),
                        ...style({ width: invoice.png.size.width, height: invoice.png.size.height }, '2xl'),
                        borderWidth: 8,
                        borderColor: theme.alternative,
                        borderRadius: 8
                    }} />}
                    {!invoice.png && <DefaultText>Generating Invoice...</DefaultText>}
                </View>

                {/* <View style={{ ...style({ flex: 'auto', }, 'any'), ...style({ flex: 2 }, 'sm') }}> */}
                <View style={{...style({ width: '90%', }, 'any'), ...style({ width: '30%' }, 900), ...style({ width: '25%' }, 'lg'), ...style({ width: '22%' }, '2xl')}}>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        <DefaultText font='medium' style={{ fontSize: 24, marginBottom: 12 }}>Status:</DefaultText>
                        <Status status={invoice.invoice.status} type={'invoices'} style={{ fontSize: 24, marginBottom: 12 }} />
                    </View>

                    <InvoiceDetails invoice={invoice.invoice} payButton={invoice.invoice.status == 'open'} />
                </View>
            </View>
        </View>
    )
}