import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText, ItalicText, TextBreak, TextLinkInteractive, TextSpace } from '../../../components/Text';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import { Section, Title, Styles } from '../Components';

export default function GeneralTerms() {
    const navigate = useNavigate();
    const { theme } = useTheme();

    return (
        <View style={{ gap: 16 }}>
            {/* Title */}
            <Title title="General Terms of Service" date="01/29/2023" />

            {/* Content */}
            <View style={{ gap: 16 }}>

                {/* Preamble */}
                <DefaultText>These General Terms of Service (“General Terms”), along with any applicable Additional Terms (see Section I below) (collectively, the “Terms”) govern your use of and access to our websites, any interactive areas, software, applications, and other services (collectively, the “Services”).</DefaultText>
                <DefaultText>These Services are provided by the entity specified in Section XIII of these General Terms (“we”, “us”, “our”, or “Atlas”). Please note that the Terms constitute a binding legal agreement between you and Atlas. By visiting our websites, registering an account, and/or using the Services, you acknowledge that you have read the Terms, understand them, and agree to be bound by these Terms. If you are using the Services on behalf of an organization, you are agreeing to these Terms for that organization and acknowledging that you have the authority to act on behalf of that organization and commit to these Terms on behalf of that organization. If you do not agree with these Terms or any provisions hereof, please do not use our Services.</DefaultText>

                {/* Section I. Your Agreement with Atlas */}
                <Section>I. Your Agreement with Atlas</Section>

                {/* (1) Additional Terms */}
                <DefaultText>
                    <ItalicText>(1) Additional Terms:</ItalicText>
                    <TextSpace /><TextSpace />
                    Our Services are subject to one or more of the additional terms below (“Additional Terms”). If there is any conflict between the terms in the General Terms and the Additional Terms, then the Additional Terms govern in relation to that Service.
                </DefaultText>

                {/* (a) Subscription Terms */}
                <DefaultText style={Styles.indent}>
                    (a)
                    <TextSpace />
                    <TextLinkInteractive navigate={navigate} link='/legal/terms/subscription' font='bold' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Subscription Terms</TextLinkInteractive>
                </DefaultText>

                {/* Update to Terms */}
                <DefaultText>
                    <ItalicText>(2) Update to Terms:</ItalicText>
                    <TextSpace /><TextSpace />
                    Atlas may modify the Terms from time to time. The most recent version is the version that applies to your use of the Services. If the changes include material changes that affect your rights or obligations, we will notify you in advance of the changes by reasonable means, which could include notification through the client applications, website, or via email. Unless it is stated by us otherwise, each update of the Terms comes into force as of the moment when amended Terms are published on this web page. You understand and agree that any continued use and access to the Services after any updates of these Terms are published, means that you voluntarily agree to be bound by the updated Terms.
                </DefaultText>

                {/* Privacy */}
                <DefaultText>
                    <ItalicText>(3) Privacy:</ItalicText>
                    <TextSpace /><TextSpace />
                    For information about how we process information about you and your use of our Services, please see our <TextLinkInteractive navigate={navigate} link='/legal/privacy' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Privacy Policy</TextLinkInteractive>.
                </DefaultText>

                {/* Section II. Our Services */}
                <Section>II. Our Services</Section>
                <DefaultText>To use any of our Services, you must set up an account. You are solely responsible for all usage or activity on your account including, but not limited to, use of the account by any person who uses your account, with or without authorization, or who has access to any computer on which your account resides or is accessible.</DefaultText>
                <DefaultText>You are responsible for maintaining the confidentiality of your Atlas account email and password, and for restricting access to your account. You may not share your password or other account access information with any other party, temporarily or permanently, and you agree to accept responsibility for all activities that occur under your account, whether or not authorized by you. You agree to immediately notify us of any unauthorized use of your account, email, or password, as the case may be.</DefaultText>
                <DefaultText>Your use of the Services is at your own risk. The Services may be modified, updated, interrupted or suspended at any time without notice or liability. We do not bear any liability for any harm or other adverse consequences to you, caused by this. Atlas, its owners, employees, agents, and others that are involved with the Services are not in any way or form liable for any harm of any kind executed or intended, resulting from or arising through or from the use of any account registered with Services.</DefaultText>

                {/* Section III. License Conditions */}
                <Section>III. License Conditions</Section>
                <DefaultText>Subject to the terms and conditions of these Terms, we grant you a limited, revocable, non-exclusive, personal, non-transferable, non-sublicensable, fixed-term license to: (1) download and use a copy of our software; and (2) use the Services, including, without limitation, the products and services made available on or through our software or our website. No other right or license, express or implied, of any kind, is granted to you hereunder with respect to the Services. The license provided herein is effective until terminated. This license automatically terminates if you fail to comply with these Terms.</DefaultText>
                <DefaultText>The Services, including, but not limited to, our software, mobile application, and all other products, are owned and copyrighted by Atlas and protected worldwide. We retain all rights, title, and interest in and to the Services and any portion thereof, including, without limitation, all copyrights, trademarks, service marks, patents, trade secrets, and other intellectual property rights. You shall not take any action to jeopardize, limit or interfere in any manner with our ownership of and rights with respect to the Services. All rights are reserved unless otherwise noted.</DefaultText>
                <DefaultText>By accessing and using this website and by using the Services, you accept and acknowledge that the Services, including the appearance, content, selection, assembly and functionality and any other parts or specifics of our websites and the Services, is the ownership of Atlas (despite whether the specific content is individually protected by copyright).</DefaultText>
                <DefaultText>The version of the Services and software available at your renewal date may be different from the version available when you first purchased your license from us.</DefaultText>

                {/* Section IV. Payments */}
                <Section>IV. Payments</Section>
                <DefaultText>All our paid Services are subscribed on a service period basis. You choose the payment method yourself when you sign up for the paid Services. If you did not explicitly choose a one-time subscription, your service will automatically be renewed, and your chosen payment method will be charged at the end of each service period unless you decide to cancel your automatic payments for the Services before the end of the then-current subscription. All prices, including recurring subscription fees, are subject to change. Any increased changes in pricing will be communicated to you in advance if you have an active subscription. If you update payment details in your account, the updated information will be regarded as your preferred method for any future payments.</DefaultText>
                <DefaultText>In some cases, we or others on our behalf may offer a free trial for our paid Services. The duration of the free trial period and other details will be specified during checkout. We determine your free trial eligibility at our sole discretion, and to the extent permitted under applicable law, we may limit or withdraw a free trial option at any time without prior notice. You acknowledge and agree that we will automatically charge you for our paid Services at the end of the free trial period on a recurring basis. If you do not want to be charged, you must cancel the paid subscription before the end of the free trial by contacting our support team. If you received a free trial from a third party, you may also cancel the paid subscription through that third party.</DefaultText>
                <DefaultText>Any fees charged by us are exclusive of taxes. However, we may calculate and add any taxes and/or additional fees, including, but not limited to sales tax, value-added tax, and other taxes or fees under laws applicable to you. Such taxes and fees will be calculated according to the billing information provided by you to Atlas at the time of purchase.</DefaultText>

                {/* Section V. Refund and Cancellation Policy */}
                <Section>V. Refund and Cancellation Policy</Section>
                <DefaultText>You have a right to cancel your subscription. You can cancel a recurring subscription on our website. Once the subscription is canceled you will have access to the respective Service until the service period ends.</DefaultText>
                <DefaultText>Refunds are handled solely on a case-by-case basis. We do not provide a guarantee that a refund will be issued to you. All refund requests are subject to the discretion of Atlas and its management.</DefaultText>

                {/* Section VI. Minors */}
                <Section>VI. Minors</Section>
                <DefaultText>The services are not directed to minors, and any use by minors should only be done with the guidance, supervision, and consent of their parents or guardians. Further, we rely on parents and guardians to ensure minors only use the Services if they can understand their rights and responsibilities as stated in these Terms and our Privacy Policy.</DefaultText>
                <DefaultText>Consistent with applicable law, Atlas does not knowingly collect personal information from minors without parental consent. If we learn that we have inadvertently obtained information in violation of applicable laws prohibiting the collection of information from children without such consent, we will promptly delete it.</DefaultText>

                {/* Section VII. Restrictions */}
                <Section>VII. Restrictions</Section>
                <DefaultText>Your access to and use of the Services is subject to these Terms and all applicable laws and regulations. We reserve the right, at any time, in our sole discretion, with or without notice, to terminate the accounts of, and block access to the Services to any users who infringe any applicable laws or these Terms.</DefaultText>
                <DefaultText>You agree that you shall not use, assist, encourage, or enable others to:</DefaultText>

                <DefaultText style={Styles.indent}>(a) Attempt to gain unauthorized access to the Services, user accounts, computer systems, or networks connected to the Services through hacking, password mining, brute force, or any other means.</DefaultText>
                <DefaultText style={Styles.indent}>(b) Interfere with or disrupt the integrity or performance of the Service.</DefaultText>
                <DefaultText style={Styles.indent}>(c) Attempt to circumvent any technological measure implemented by Atlas.</DefaultText>
                <DefaultText style={Styles.indent}>(d) Take any action that imposes or may impose an unreasonable or disproportionately large load on our infrastructure.</DefaultText>
                <DefaultText style={Styles.indent}>(e) Use any robot, spider, scraper, or other automated means to access our website or Services for any purpose without prior written permission.</DefaultText>
                <DefaultText style={Styles.indent}>(f) Claim that you are the representative or agent of any of the Services, including any of its functionality.</DefaultText>
                <DefaultText style={Styles.indent}>(g) Sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any third party the Services in any way.</DefaultText>
                <DefaultText style={Styles.indent}>(h) Use the Services for any illegal purpose in violation of any applicable local, state, national or international law.</DefaultText>
                <DefaultText style={Styles.indent}>(i) Violate, infringe, or misappropriate other people's intellectual property, privacy, or other legal rights.</DefaultText>
                <DefaultText style={Styles.indent}>(j) Share anything that is illegal, abusive, harassing, or otherwise objectionable.</DefaultText>
                <DefaultText style={Styles.indent}>(k) Transmit any viruses or other computer instructions or technological means that disrupt, damage, or interfere with the use of computers or related systems.</DefaultText>
                <DefaultText style={Styles.indent}>(l) Build a product using similar ideas, features, functions, or graphics of the Services or copy any ideas, features, functions, or graphics of the Services.</DefaultText>
                <DefaultText style={Styles.indent}>(m) Violate general ethic or moral norms, good customs, and fair conduct norms.</DefaultText>
                <DefaultText style={Styles.indent}>(n) To otherwise infringe or circumvent these Terms.</DefaultText>

                <DefaultText>We reserve the right to refuse service, suspend accounts, or limit access to the Services at our sole and absolute discretion. Such suspension or access limitation may be implemented by Atlas instantly and without any indication, notice, or refund. We may suspend your account for clarification, investigation, or request you to explain your actions and provide additional information. If your account has been suspended, you must contact us for further information. We may suspend your user account for a reasonable period of time before we terminate a user account permanently.</DefaultText>
                <DefaultText>Your access and use of the Services in your country is with your own initiative, and you solely are responsible for complying with your local laws and regulations if and to the extent such laws are applicable. We reserve the right to limit, in our sole discretion, the availability of the Services or any portion thereof, to any person, entity, geographic area, and jurisdiction, at any time.</DefaultText>
                <DefaultText>You are disallowed to connect and use the Services if you are a competitor of our business, if you have been or are prohibited to access the Services, or if your account has been suspended or closed due to any reason.</DefaultText>
                <DefaultText>We encourage you to let us know about the violation of these Terms by any of Atlas users; in case of such violations, we may take appropriate action at our sole discretion.</DefaultText>

                {/* Section VIII. Disclaimer of Warranties */}
                <Section>VIII. Disclaimer of Warranties</Section>
                <DefaultText>Reasonable efforts are taken to improve the accuracy and integrity of the Services, but complex software is never wholly free from defects, errors, and bugs. We give no warranty or representation that the Services will be wholly free from defects, errors, and bugs, such as downtime, loss of data, corrupt data, service delay, mistakes, out-of-date information, or others. Notwithstanding any other provision of these Terms, we reserve the right to change, suspend, remove, or disable access to the Services, or any functionality comprising a part of the Services at any time without notice. In no event will we be liable for making these changes. As a registered user in good standing, you may be provided with limited service by Atlas. We do not warrant and will not have any liability or responsibility for your use of the Services or other products or services we provide. We may also impose limits on the use of or access to the Services, for any reason and without notice or liability. Our Services may be unavailable from time to time due to human, digital, mechanical, telecommunication, software, and other failures. We cannot predict or control when such downtime may occur and cannot control the duration of such downtime.</DefaultText>
                <DefaultText>The Services (including, without limitation, our software, mobile applications, services, and website) are provided “as is” and with all faults. We make no representation or warranty whatsoever regarding the completeness, accuracy, adequacy, suitability, functionality, availability, or operation of the services, You acknowledge that we do not have control over your use of the Services, and we do not warrant the performance or results that may be obtained through your use of the Services. You assume all risks and responsibility for your use of the Services and for any loss or errors in any data or information. To the full extent permissible by applicable law, we disclaim all warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising through the course of dealing or usage of trade. Without limiting the foregoing, we neither warrant nor represent that your use of the Services will not infringe the rights of any third parties, nor that the Services will be available for your access or use, nor that operation of the Services will be error-free or uninterrupted.</DefaultText>
                <DefaultText>We shall not be responsible or have any liability for any delay or failure to perform to the extent due to unforeseen circumstances or causes beyond our reasonable control, including, without limitation, failures of third party software (whether open or not), failures of your telecommunication or internet service providers, force majeure, earthquakes, fires, floods, embargoes, labor disputes and strikes, riots, war, the novelty of product manufacture or other unanticipated product development problem, and acts of civil and military authorities.</DefaultText>

                {/* Section IX. Limitation of Liability */}
                <Section>IX. Limitation of Liability</Section>
                <DefaultText>There are inherent risks in relying upon, using, transmitting, or retrieving any data and/or content on the internet, and we urge you to make sure you understand these risks before using the Services.</DefaultText>
                <DefaultText>Your use of the Services is at your own risk. Neither Atlas nor any of its subsidiaries or affiliates, nor any of their employees, officers, or directors, shall be liable for any direct, indirect, punitive, incidental, special, consequential, or other damages (including, without limitation, loss of data or information of any kind, loss of business, lost profits, interruption of business, cost of cover, or any other damages) arising out of or in any way related to this agreement or the use or inability to use the services, whether based on contract, tort, strict liability, or otherwise, even if we have been advised of the possibility of such damages. In any case, the liability of Atlas shall not exceed what the client paid to Atlas and shall not include attorney fees or court costs irrespective of any laws or statutes that may prescribe otherwise.</DefaultText>

                {/* Section X. Indemnification */}
                <Section>X. Indemnification</Section>
                <DefaultText>You agree, at your own expense, to indemnify, defend, and hold harmless Atlas, its subsidiaries and affiliates, and their officers, directors, employees, agents, distributors, and licensees, from and against any judgments, losses, deficiencies, damages, liabilities, costs, claims, demands, suits, and expenses (including, without limitation, reasonable attorneys' fees, expert witness fees, and expenses) incurred in, arising out of or in any way related to your breach of these Terms, your use of the Services, or any of your other acts or omissions.</DefaultText>

                {/* Section XI. Electronic Communications */}
                <Section>XI. Electronic Communications</Section>
                <DefaultText>You agree to receive communications from us electronically, e.g., by email, website notification, application message, etc., and consent that such notifications satisfy any legal requirement for communication in writing. You agree that we may communicate to you for the purpose of advising you of changes or additions to the Services, Terms, about any of our products or services, or for such other purposes that are reasonable or required as seen fit by Atlas management.</DefaultText>
                <DefaultText>When communicating with our customer support or representatives, you agree to be respectful and kind. If we feel that your behavior towards any of our customer support representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account.</DefaultText>

                {/* Section XII. Class Action Waiver */}
                <Section>XII. Class Action Waiver</Section>
                <DefaultText>Where permitted under the applicable law, class action lawsuits, class-wide arbitrations, private attorney-general actions, and any other proceeding where someone acts in a representative capacity are not allowed. Unless both you and Atlas agree, no arbitrator or judge may consolidate more than one person's claims or otherwise preside over any form of representative or class proceeding.</DefaultText>

                {/* Section XIII. Contracting Entity */}
                <Section>XIII. Contracting Entity</Section>
                <DefaultText>The Atlas entity with which you are contracting under these Terms is Atlas Industries, Inc.</DefaultText>

                {/* Section IX. Miscellaneous */}
                <Section>IX. Miscellaneous</Section>
                <DefaultText>We reserve the right to modify and update these Terms at our sole discretion, at any time, for any reason, and without liability.</DefaultText>
                <DefaultText>We also reserve the right to modify or update the operation of the Services at our sole discretion, at any time, for any reason, and without notice or liability. We may even suspend the Services entirely, in which event we will notify you in advance unless extenuating circumstances, such as safety or security concerns, prevent us from doing so.</DefaultText>
                <DefaultText>The Terms constitute an agreement between you and us regarding the use of the Services. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</DefaultText>
                <DefaultText>If any provision of the Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.</DefaultText>
                <DefaultText>You may not assign these Terms or any rights or interest under these Terms or delegate any obligations to be performed under these Terms, without Atlas' prior written consent. Atlas can assign its rights or obligations under these Terms to selected third parties without your consent, including but not limited to, in cases of a corporate reorganization, merger, acquisitions, sale, or transfer of all or substantially all of the company assets.</DefaultText>
                <DefaultText>These Terms shall be governed in all respects by the substantive laws of Texas, without regard to its choice of law provisions. You agree that the courts of general jurisdiction located in Texas will have exclusive jurisdiction over any and all disputes arising out of our relating in any way to these Terms or the Services.</DefaultText>
                <DefaultText>For all purposes, the English language version of these Terms shall be the original, governing instrument and understanding between you and us. In the event of any conflict between this English language version of the Terms and any subsequent translation into any other language, the English language version shall govern and control.</DefaultText>
            </View>
        </View>
    )
}