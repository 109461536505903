import React from 'react';
import { View } from 'react-native';
import { DefaultText } from '../components/Text';
import Modal from '../components/Modal';
import Divider from '../components/Divider';
import useTheme from '../contexts/theme';
import Title from '../components/Title';
import { Link } from 'react-router-dom';
import AnimatedEmoji from '../components/AnimatedEmoji';
import useScale from '../contexts/scale';

export default function Home() {
    const { theme } = useTheme();
    const { style } = useScale();

    // const test = React.useRef({...style({width: '80%'}, 'any'), ...style({width: '30%'}, 'any')})
    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ ...style({ width: '80%' }, 'any'), ...style({ width: '50%' }, 'md'), ...style({ width: '30%' }, 'xl') }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}><Title title="Home" fontSize={20} /></View>
                <Modal>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <DefaultText font='bold' style={{ ...style({fontSize: 28}, 'any'), ...style({fontSize: 36}, 'sm') }}><AnimatedEmoji emoji="wave" /> Greetings.</DefaultText>
                    </View>
                    <Divider style={{ backgroundColor: theme.text.secondary }} />
                    <DefaultText style={{ textAlign: 'center', fontSize: 16 }}>
                        Welcome to the website of Atlas Industries, Inc. We are currently in the process of finalizing our public-facing website - check back later for more information.
                    </DefaultText>

                    <DefaultText style={{ textAlign: 'center', marginTop: 12, fontSize: 14 }}>
                        If you have an active service or are a new client, please visit the&nbsp;
                        <Link to="/dashboard" style={{ color: theme.accent }}>
                            <DefaultText font='bold' style={{ color: theme.accent }}>dashboard</DefaultText>
                        </Link>
                        .
                    </DefaultText>
                </Modal>
            </View >
        </View >
    )
}