import React from 'react';
import { View, Animated, StyleSheet, TouchableOpacity } from 'react-native';
import { DefaultText } from '../components/Text';
import Modal from '../components/Modal';
import TextInput from '../components/TextInput';
import Divider from '../components/Divider';
import useTheme from '../contexts/theme';
import SpinIcon from '../components/SpinIcon';

import { AnimateRef } from '../utility/animation';

import Button from '../components/Button';
import useScale from '../contexts/scale';

export type Field = {
    label: string,
    placeholder: string,
    maxLength: number,
    payloadKey: string,
    value?: string,
    disabled?: boolean,
    secureTextEntry?: boolean,
    onChangeText?: (value: string) => void,
}

/**
 * FormInput Component
 */
function FormInput({ field, style, data }: { field: Field, style?: Object, data: {[key: string]: any} }) {
    const styles = StyleSheet.create({
        field: {
            opacity: field.disabled ? 0.6 : 1,
            cursor: field.disabled ? 'not-allowed' : ''
        }
    });
    return (
        <View style={{ flexGrow: 1, marginBottom: 16, ...style }}>
            <DefaultText font="semibold" style={{ fontSize: 16, marginBottom: 4 }}>{field.label}</DefaultText>
            <TextInput value={field.value} placeholder={field.placeholder} maxLength={field.maxLength} secureTextEntry={field.secureTextEntry} style={styles.field} editable={!field.disabled} onChangeText={ (value: any) => { 
                data[field.payloadKey] = value;
                field.onChangeText?.(value);
            } }/>
        </View>
    )
}

export default function Form({ title, fields, customFields, submissionText, onPress, style, children }: 
    { 
        title: string, 
        fields: (Field | Field[])[], 
        customFields?: React.ReactNode[] | React.ReactNode, 
        submissionText: string, 
        onPress: (data: {[key: string]: any}, callback: (content?: React.ReactNode) => void, linkedOpacity: Animated.Value) => void, 
        style?: {modal?: Object}, 
        children?: React.ReactNode[] | React.ReactNode 
    }
    ) {
    const { theme } = useTheme();
    
    const { style: mStyle } = useScale();

    // Store form data.
    const data: {[key: string]: any} = React.useRef({}).current

    // Working state.
    const [working, setWorking] = React.useState(false);

    // Button opacity.
    const buttonOpacity = React.useRef(new Animated.Value(1)).current;
    if (working) { AnimateRef(buttonOpacity, 0.5).start(); }

    // Content opacity.
    const contentOpacity = React.useRef(new Animated.Value(1)).current;

    const form = (
        <Animated.View style={{width: '100%', height: '100%', opacity: contentOpacity}}>
            {fields.map((field, index) => {
                // Standard field display.
                if (!Array.isArray(field)) { return <FormInput key={index} field={field} data={data}/> }
                
                // Multi-field display.
                return(
                    <View key={index} style={{ ...mStyle({ flexDirection: 'column' }, 'any'), ...mStyle({ flexDirection: 'row' }, 'lg') }}>
                        {field.map((subField, subIndex) => {
                            const margin = subIndex < (field.length - 1) ? 16 : 0;
                            return <FormInput field={subField} key={`${index}-${subIndex}`} style={mStyle({ marginRight: margin }, 'lg') } data={data}/>;
                        })}
                    </View>
                );
            })}

            {/* Custom Fields */}
            {customFields}

            {/* Submission Button */}
            <Button title={submissionText} style={{button: { alignSelf: 'baseline', opacity: buttonOpacity }}} onPress={working ? () => {} : () => {
                setWorking(true);
                onPress(data, (content: React.ReactNode) => {
                    // Update working state, animate button opacity.
                    setWorking(false);
                    AnimateRef(buttonOpacity, 1).start();

                    // If content, animate to new content if applicable.
                    if (!content) { return; }
                    AnimateRef(contentOpacity, 0, 250).start(() => {
                        setContent(content);
                        AnimateRef(contentOpacity, 1, 250).start();
                    });
                }, contentOpacity);
            }}/>
            
            {/* Footer */}
            {children}
        </Animated.View>
    );

    // Content display.
    const [content, setContent] = React.useState(null as React.ReactNode);
    /**
     * Form Display
     */
    return (
        <View style={{ ...mStyle({ width: '80%' }, 'any'), ...mStyle({ width: '50%' }, 'md'), ...mStyle({ width: '35%' }, 'xl'), marginHorizontal: 'auto', flexDirection: 'column', alignItems: 'center', ...style?.modal }}>
            {/* Title */}
            <View>            
                <DefaultText font="bold" style={{ fontSize: 18, paddingHorizontal: 30 }}>Atlas - {title}</DefaultText>
                <Divider style={{ marginTop: 8, backgroundColor: theme.accent }}/>
            </View>

            {/* Form Content */}
            <Modal>
                {content || form}
                {/* Working Indicator */}
                <SpinIcon active={working} style={{position: 'absolute', right: 0, bottom: 0, marginRight: 12, marginBottom: 12}}/>
            </Modal>
        </View>
    )
}