import React from 'react';
import { View, Image, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import { DefaultText, TextLinkInteractive } from './Text';
import useTheme from '../contexts/theme';
import { Link, useNavigate } from 'react-router-dom';
import Divider from './Divider';
import useScale from '../contexts/scale';

export default function Footer() {
    // todo: default line height?
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { style } = useScale();

    const styles = StyleSheet.create({
        container: {
            marginTop: 'auto',
            marginHorizontal: 24,
            marginBottom: 32
        },
        body: {
            // backgroundColor: '#292929',
            // marginHorizontal: 32,
            padding: 24,
            borderRadius: 8,
            borderColor: theme.alternative,
            // todo: theme.alternative or accent?
            borderWidth: 2,
            height: '100%',
            ...theme.shadowPrimary,
        },
        logo: {
            width: 35,
            height: 35,
            marginRight: 4
        },
        itemContainer: {
            ...style({
                flexDirection: 'row',
                flexWrap: 'wrap',
            }, 'any'),
            ...style({
                flexDirection: 'row',
                justifyContent: 'space-between'
            }, 'sm'),
        },
        item: {
            ...style({
                flex: '50%',
            }, 'any'),
            ...style({
                flex: 'auto'
            }, 'sm'),
        }
    });

    const categories = [
        {
            name: 'Atlas',
            links: [
                { name: 'About', path: '/about' },
                { name: 'Contact', path: '/contact' },
                { name: 'Dashboard', path: '/dashboard' },
                { name: 'Services', path: '/services' },
            ]
        },
        {
            name: 'Community',
            links: [
                { name: 'News', path: '/news' },
                { name: 'GitHub', path: '/github' },
                { name: 'Twitter', path: '/twitter' },
            ]

        },
        {
            name: 'Legal',
            links: [
                { name: 'Terms of Service', path: '/legal/terms' },
                { name: 'Privacy Policy', path: '/legal/privacy' },
            ]
        },
        {
            name: 'Support',
            links: [
                { name: 'Ticket Dashboard', path: '/dashboard/tickets' },
                { name: 'Service Status', path: '/status' },
            ]
        }
    ];

    return (
        <View style={styles.container}>
            <View style={styles.body}>
                { /* Logo */ }
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image source={theme.logo} style={styles.logo} />
                        { /* Title */ }
                        <DefaultText font="bold" style={{fontSize: 20}}>Atlas</DefaultText>
                    </View>
                </Link>
                <Divider style={{ height: 3}}/>

                {/* Item Container */}
                <View style={styles.itemContainer}>
                    {categories.map((category, index) => (
                        <View key={index} style={styles.item}>
                            <DefaultText font="bold" style={{color: theme.accent, textTransform: 'uppercase'}}>{category.name}</DefaultText>
                            <View style={{marginVertical: 8}}>
                                {category.links.map((link, index) => (
                                    <TextLinkInteractive key={index} font='medium' style={{lineHeight: 24}} link={link.path} navigate={navigate}>{link.name}</TextLinkInteractive>
                                ))}
                            </View>
                        </View>
                    ))}
                    <View></View>
                </View>

                {/* Company information */}
                {/* Todo: Decide color. */}
                <View>
                    <DefaultText font="bold" style={{color: theme.text.secondary}}>Copyright © {new Date().getFullYear().toString()} Atlas Industries, Inc.</DefaultText>
                    <DefaultText style={{color: theme.text.secondary}}>5900 Balcones Drive, Suite 100, Austin, TX, 78731, USA</DefaultText>
                </View>

                {/* Trustpilot */}
                {/* <TouchableOpacity style={{ position: 'absolute', bottom: 0, right: 0}} onPress={() => {
                    Linking.openURL('https://www.trustpilot.com/review').catch((err: any) => console.error('An error occurred', err));
                }}>
                    <DefaultText>See our reviews on</DefaultText>
                    <Image style={{ width: 20, marginLeft: 4, marginRight: 2}} source={require('../assets/images/icons/trustpilot.svg')}></Image>
                    <DefaultText>Trustpilot</DefaultText>
                </TouchableOpacity> */}

                {/* <div class="md:absolute md:bottom-0 md:right-0">
                    <a class="flex justify-center" href="https://www.trustpilot.com/review" target="_blank">
                        See our reviews on
                        <img class="w-5 ml-1 mr-0.5" src="/images/icons/trustpilot.svg">
                        Trustpilot
                    </a>
                </div> */}


            </View>
        </View>
    );
}