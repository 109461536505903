import React from "react";
import { connect, Socket } from "socket.io-client";
import { WEBSOCKET_URL } from '@env';

export type Camera = {
    id: string,
    uuid: string,
    ip: string,
    type: string,
    src: string,
    machine: {
        name: string,
        dimensions: {width: number, height: number},
    },
    data: {
        time: {
            lastCameraFrame: number,
            lastFrame: number,
        },
        fps: number,
        latency: number,
    },
    predictions: Object[]
}

export type Store = {
    camera: Camera[]
}

type WebSocketExport = {
    socket: typeof Socket,
    // store: Store,
    // setStore: (store: Store) => void,
    // getCameraFromId: (id: string) => number | null
}

const WebSocketContext = React.createContext({} as WebSocketExport);
// const socket = connect(WEBSOCKET_URL);
// const socket = io('127.0.0.1:8080', {
//     // secure: false,
//     // transports: ['websocket']
// })

function useWebSocket(): WebSocketExport {
    // const [store, setStore] = React.useState({camera: []} as Store);
    const [socket, setSocket] = React.useState({} as Socket);

    function register(ws: Socket) {
        console.log("Registering device...");
        ws.emit('register', 'web', {'name': 'chrome'}, '9Fd747EbZ1YLX2n12680rV0pNp18Rn7g');
    }


    React.useEffect(() => {
        if (!WEBSOCKET_URL) { return; }
        console.log("Attempting connection...");
        const ws = connect(WEBSOCKET_URL)
        setSocket(ws);

        ws.on('connect', () => { 
            console.log('connected', ws.id);
            register(ws);
        });

        ws.on('disconnect', (reason) => { 
            console.log('disconnected', reason)
        });

        ws.on("reconnect", () => {
            console.log("reconnected")
            register(ws);
        });

        ws.on('connect_error', (err) => {
            // console.log('ws error', err)
        });
    }, []);

    return {
        socket: socket as Socket,
        // getCameraFromId
    };
}

export function WebSocketProvider({ children }: { children: React.ReactNode }) {
    const exp: WebSocketExport = useWebSocket();
    return <WebSocketContext.Provider value={exp}>{children}</WebSocketContext.Provider>;
}

export default function WebSocketConsumer() {
    return React.useContext(WebSocketContext);
}