import React from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import useTheme from '../contexts/theme';

import WebScrollbar from './WebScrollbar';
import { useLocation } from "react-router-dom";

export default function Background({ children }: { children?: React.ReactNode | React.ReactNode[] }) {
    const { theme } = useTheme();
    WebScrollbar()
    const styles = StyleSheet.create({
        background: {
            backgroundColor: theme.background,
            width: '100%',
            height: '100vh',
            // height: '100%',
            // height: '100vh',
        }
    });


    // Scroll to top on page change.
    const scrollViewRef = React.useRef(null as any);
    const { pathname } = useLocation();
    React.useEffect(() => { scrollViewRef.current?.scrollTo({ y: 0, animated: true }); }, [pathname]);

    return (
        <ScrollView ref={scrollViewRef} style={styles.background} contentContainerStyle={{ flexGrow: 1 }}>
            {children}
        </ScrollView>
    );
}