import React from "react";
import { Appearance } from 'react-native';
import { shadowLight, shadowDark } from "../styles/shadow";
import AsyncStorage from '@react-native-async-storage/async-storage';

const selectedTheme = Appearance.getColorScheme() || 'dark';

type AvailableThemes = 'light' | 'dark';

type ThemeList = {
    [key in AvailableThemes]: {
        name: AvailableThemes;
        background: string,
        alternative: string,
        alternativeSecondary: string,
        accent: string,
        accentSecondary: string,
        text: {
            color: string,
            secondary: string,
        },
        invoices: {
            open: string,
            paid: string,
            void: string,
        },
        subscriptions: {
            active: string,
            incomplete: string,
            incomplete_expired: string,
            past_due: string,
            unpaid: string,
            canceled: string,
            trialing: string,
        },
        accountState: {
            active: string,
            pending: string,
            banned: string,
        }
        shadowPrimary: any,
        logo: any,
    }

}

type Theme = {
    theme: ThemeList[keyof ThemeList],
    toggle: () => void
}

const toggleSwitch: AvailableThemes[] = ['dark', 'light'];
const themes: ThemeList = {
    light: {
        name: 'light',
        background: '#ffffff',
        
        alternative: '#f2f2f2',
        alternativeSecondary: '#E6E5E5',

        accent: '#c9b670',
        accentSecondary: '#A18949',

        text: {
            color: '#0F0F0F',
            secondary: '#888888',
        },
        
        invoices: {
            'open': '#E63939',
            'paid': '#5cb85c',
            'void': '#888888',
        },

        subscriptions: {
            'active': '#5cb85c',
            'incomplete': '#E63939',
            'incomplete_expired': '#E63939',
            'past_due': '#E63939',
            'unpaid': '#E63939',
            'canceled': '#888888',
            'trialing': '#2D6DFF'
        },

        accountState: {
            'active': '#5cb85c',
            'pending': '#E69B39',
            'banned': '#E63939',
        },

        shadowPrimary: shadowLight,

        logo: require('../assets/images/logos/logo-dark.svg')
    },
    dark: {
        name: 'dark',
        background: '#0F0F0F',
        
        alternative: '#272727',
        alternativeSecondary: '#303030',

        accent: '#c9b670',
        accentSecondary: '#A18949',

        text: {
            color: '#F1F1F1',
            secondary: '#888888',
        },

        invoices: {
            'open': '#E63939',
            'paid': '#5cb85c',
            'void': '#888888',
        },
        
        subscriptions: {
            'active': '#5cb85c',
            'incomplete': '#E63939',
            'incomplete_expired': '#E63939',
            'past_due': '#E63939',
            'unpaid': '#E63939',
            'canceled': '#888888',
            'trialing': '#2D6DFF'
        },

        accountState: {
            'active': '#5cb85c',
            'pending': '#E69B39',
            'banned': '#E63939',
        },

        shadowPrimary: shadowDark,
        
        logo: require('../assets/images/logos/logo-light.svg')
    }
}

const ThemeContext = React.createContext({} as Theme);

function useTheme(): Theme {
    const [theme, setTheme] = React.useState(themes[selectedTheme]);

    // Restore preferences.
    React.useEffect(() => {
        AsyncStorage.getItem('theme')
        .then((theme: string | null) => {
            if (!theme) { return; }
            if (!themes.hasOwnProperty(theme)) { return AsyncStorage.removeItem('theme'); }

            // @ts-ignore
            setTheme(themes[theme]);
        });
    }, [])

    return {
        theme,
        toggle: () => { 
            const newTheme = toggleSwitch[Number(!toggleSwitch.indexOf(theme.name))];
            setTheme(themes[newTheme]); 
            AsyncStorage.setItem('theme', newTheme); 
        }
    };
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {


    const theme: Theme = useTheme();
    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export default function ThemeConsumer() {
    return React.useContext(ThemeContext);
}