import React from 'react';
import useAuth from '../../../contexts/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useToast from '../../../contexts/toast';
import { View } from 'react-native';
import { ModalTitle } from '../../../components/Modal';
import Title from '../../../components/Title';
import useScale from '../../../contexts/scale';
import AnimatedEmoji from '../../../components/AnimatedEmoji';
import { DefaultText, TextBreak } from '../../../components/Text';
import { makeTitle } from '../../../utility/string';
import useTheme from '../../../contexts/theme';
import Button from '../../../components/Button';

export default function Subscribe() {
    const { request } = useAuth();
    const { id } = useParams();
    const push = useToast();
    const { style } = useScale();
    const { theme } = useTheme();
    const [product, setProduct] = React.useState({} as any);
    const navigate = useNavigate();
    
    // Retrieve product.
    React.useEffect(() => {
        request.current.get(`/billing/products/${id}`)
            .then((response: any) => {
                setProduct(response.data.data);
            })
            .catch((response: any) => {
                push("Failed to fetch product.", "error")
                setProduct(null);
                console.log(`Failed to fetch product: ${response.data}`);
            });
    }, []);

    function createSubscription() {
        request.current.post(`/billing/stripe/subscriptions`, { productSid: product.productSid, priceSid: product.price.priceSid, quantity: 1 })
            .then((response: any) => {
                push("Invoice generated for subscription.", "success")
                navigate('/dashboard/invoices');
            })
            .catch((response: any) => {
                push("Failed to subscribe to product.", "error")
                console.log(`Failed to subscribe to product: ${response.data}`);
            });
    }

    const modalStyle = { title: { ...style({ fontSize: 20 }, 'any'), ...style({ fontSize: 28 }, 'sm'), ...style({ fontSize: 28 }, 'lg') } };
    return (
        <View style={{ ...style({ width: '80%' }, 'any'), ...style({ width: '60%' }, 'md'), ...style({ width: '56%' }, 'lg'), ...style({ width: '45%' }, 'xl'), marginHorizontal: 'auto' }}>
            <View style={{ width: 'auto', marginHorizontal: 'auto' }}><Title title="Subscribe" fontSize={20} /></View>

            {product === null && (
                <ModalTitle title={"🚫 Product not found."} style={modalStyle}>
                    <DefaultText style={{ textAlign: 'center', fontSize: 16 }}>
                        The product you have selected does not exist.
                    </DefaultText>
                </ModalTitle>
            )}

            {product?.id ? (
                <ModalTitle title={`🔖 ${makeTitle(product.name)} Subscription`} style={modalStyle}>
                    <DefaultText style={{ textAlign: 'center', fontSize: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <View style={{ flexDirection: 'row' }}><DefaultText font='bold'>Product: </DefaultText><DefaultText>{makeTitle(product.name)}</DefaultText></View>
                        <View style={{ flexDirection: 'row' }}><DefaultText font='bold'>Description: </DefaultText><DefaultText>{product.description}</DefaultText></View>
                        <View style={{ flexDirection: 'row' }}><DefaultText font='bold'>Price: </DefaultText><DefaultText>${(product.price.amount / 100).toFixed(2)}/{product.price.interval}</DefaultText></View>

                        <Button title="Subscribe" style={{ button: { marginTop: 16, width: '50%' } }} onPress={() => {createSubscription()}} />
                        <DefaultText style={{ fontSize: 14, marginTop: 6 }}>After subscribing to this product, you will be billed ${(product.price.amount / 100).toFixed(2)} {product.price.interval}ly.</DefaultText>
                        <DefaultText style={{ fontSize: 14 }}>
                            An invoice will be generated and must be paid within 24 hours on the <Link to="/dashboard/invoices" style={{ color: theme.accent }}>dashboard</Link>.
                        </DefaultText>
                    </DefaultText>


                    {/* By registering an account... */}
                    <DefaultText style={{ color: theme.text.secondary, fontSize: 12, marginTop: 16 }}>
                        By subscribing to this product you agree to the Atlas Industries, Inc.&nbsp;
                        <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                            <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                        </Link>
                        &nbsp;and&nbsp;
                        <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                            <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                        </Link>
                    </DefaultText>
                </ModalTitle>
            ) : (
                <>
                    {product !== null && (
                        <ModalTitle title={<DefaultText font='bold'><AnimatedEmoji emoji="clock" /> Loading Product...</DefaultText>} style={modalStyle}>
                            <DefaultText style={{ textAlign: 'center', fontSize: 16 }}>
                                Please wait while we load the product you have selected.
                            </DefaultText>
                        </ModalTitle>
                    )}
                </>
            )}
        </View>
    )
}