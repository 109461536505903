import React from 'react';
import { ModalTitle } from '../components/Modal';
import { DefaultText } from '../components/Text';
import { View } from 'react-native';
import Title from '../components/Title';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import useTheme from '../contexts/theme';
import useScale from '../contexts/scale';

export default function Unauthorized() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { style } = useScale();
    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ ...style({ width: '85%' }, 'any'), ...style({ width: '60%' }, 'md'), ...style({ width: '40%' }, 'lg'), }}>
                <View style={{ ...style({ width: '75%' }, 'any'), ...style({ width: '70%' }, 'lg'), ...style({ width: '45%' }, '2xl'), marginHorizontal: 'auto' }}><Title title="Unauthorized" fontSize={20} /></View>
                <ModalTitle title="🚫 Unauthorized">
                    <View style={{ flexDirection: 'column', alignItems: 'center', }}>
                        <DefaultText style={{ textAlign: 'center', ...style({ fontSize: 16 }, 'any'), ...style({ fontSize: 20 }, 'lg') }}>
                            You do not have access to this page.{"\n"}
                        </DefaultText>
                        <DefaultText style={{ marginTop: 12, fontSize: 14, textAlign: 'center' }}>
                            Believe this is a mistake?
                        </DefaultText>
                        <DefaultText style={{}}>
                            <Link to="/contact" style={{ color: theme.accent }}>
                                <DefaultText font='bold' style={{ color: theme.accent }}>Contact us</DefaultText>
                            </Link>
                            .
                        </DefaultText>
                        <Button title="Go home" onPress={() => { navigate('/') }} style={{ button: { ...style({ width: '100%' }, 'any'), ...style({ width: '50%' }, 'sm'), ...style({ width: '25%' }, '2xl'), marginTop: 12 } }}></Button>
                    </View>
                </ModalTitle>
            </View>
        </View>
    )
}