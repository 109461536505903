import React from 'react';
import Form, { Field } from '../components/Form';
import { DefaultText } from '../components/Text';
import { Link } from 'react-router-dom';
import useTheme from '../contexts/theme';
import useAuth from '../contexts/auth';
import useToast from '../contexts/toast';
import { useLocation, useNavigate } from "react-router-dom";
import { handleFailure } from '../utility/network';
import Construction from '../components/Construction';

const fields: Field[] = [
    {
        label: 'Email address',
        placeholder: 'Email address',
        maxLength: 64,
        payloadKey: 'email',
    },
    {
        label: 'Password',
        placeholder: 'Password',
        maxLength: 64,
        payloadKey: 'password',
        secureTextEntry: true,
    },
]

export default function Login() {
    const { theme } = useTheme();
    const { login } = useAuth();
    const push = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <Form title="Login" fields={fields} submissionText="LOG IN" onPress={async (data, cb) => { 
            const req = await login(data.email, data.password);

            if (req.success) {
                push("Logged in successfully.", 'success');
                navigate(state?.path || '/dashboard'); // this is actually handled by protected routes.
            } else {
                handleFailure(req.response, push);
            }
            
            // if (!req.response.data) { push(requestFailureMessages[req.response.code] || requestFailureMessages.default, 'error'); }
            // else { push("Invalid credentials.", 'error'); }

            cb();
        }}>
            {/* New to Cypher? Register */}
            <DefaultText style={{ color: theme.accent, marginTop: 12 }}>
                New to Atlas?&nbsp;
                <Link to="/register" style={{ color: theme.accent }}>
                    <DefaultText font='bold' style={{ color: theme.accent }}>Register.</DefaultText>
                </Link>
            </DefaultText>

            {/* Can't access your account */}
            <DefaultText style={{ color: theme.text.secondary }}>
                Can't access your account?&nbsp;
                <Link to="/reset-password" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Learn more.</DefaultText>
                </Link>
            </DefaultText>
        </Form>
    );
}