import React from 'react';
import { View, StyleSheet, Animated, Dimensions } from 'react-native';
import { DefaultText } from './Text';
import useTheme from '../contexts/theme';

import { Octicons } from '@expo/vector-icons'; 
import useScale from '../contexts/scale';

export type ToastType = 'info' | 'success' | 'error';
export type ToastAnimation = {
    sequence: number[];
    stepDuration: number;
}

export default function Toast({ content, icon = 'info', duration = 5, animation }: { content: string, icon?: ToastType, duration?: number, animation: ToastAnimation }) {
    const { theme } = useTheme();
    const { style } = useScale();

    // Icon mapping.
    const icons: { [key in ToastType]: string } = {
        info: 'info',
        success: 'issue-closed',
        error: 'x-circle'
    }
    const octicon = (icons[icon] || 'info') as keyof typeof Octicons.glyphMap;

    // Animation setup.
    const mt = React.useRef(new Animated.Value(animation.sequence[0])).current;
    React.useEffect(() => { buildAnimation().start(); }, []);
    setTimeout(() => { buildAnimation(true).start(); }, duration * 1000);

    /**
     * Builds the animation sequence from the animation object.
     * @param inverse - Reverse the animation.
     * @returns {Animated.CompositeAnimation} - The animation sequence.
     */
    function buildAnimation(inverse = false) {
        const anim = [];
        for (let i = 0; i < animation.sequence.length; i++) {
            anim.push(Animated.timing(mt, {
                toValue: animation.sequence[i],
                duration: animation.stepDuration,
                useNativeDriver: true
            }));
        }
        return Animated.sequence(inverse ? anim.reverse() : anim);
    }

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            right: 10,
            zIndex: 100,
            maxWidth: '95%',
            ...style({maxWidth: '30%'}, 'sm'),
        },
        toast: {
            borderWidth: 3,
            borderColor: theme.accent,
            borderRadius: 8,

            paddingVertical: 12,
            paddingHorizontal: 24,

            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',

            marginRight: 16,
            marginTop: mt as unknown as number,

            backgroundColor: theme.background,
            ...theme.shadowPrimary
        }
    });
    return (
        <View style={styles.container} pointerEvents="none">
            <Animated.View style={styles.toast}>
                <Octicons name={octicon} style={{ marginRight: 12 }} size={24} color={theme.text.color} />
                {/* Allow text to wrap as needed. */}
                <View style={{flex: 1}}> 
                    <DefaultText style={{ fontSize: 20 }}>{content}</DefaultText>
                </View>
            </Animated.View>
        </View>
    )
}