import React from 'react';

import { View, StyleSheet } from 'react-native';
import { ScrollableModal } from './Modal';
import Title from './Title';
import { DefaultText } from './Text';
import Interactive from './Interactive';
import Divider from './Divider';
import useTheme from '../contexts/theme';
import { useNavigate } from 'react-router-dom';
import useScale from '../contexts/scale';

type Style = {
    entryRow?: any
}

export default function Table({ title, columns, data, link, linkRoute, style, populate, onPress }: { title: string, columns: string[], data: any[], link?: string, linkRoute?: any, style?: Style, populate: (entry: any) => Array<any>, onPress?: (index: number) => void }) {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { style: _style } = useScale();

    const [active, setActive] = React.useState(-1);

    // Styles
    const styles = StyleSheet.create({
        table: {
            flexDirection: 'row',
        },
        container: {
            ..._style({ width: '200%' }, 'any'),
            ..._style({ width: '100%' }, 'sm')
        },
        entryRow: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 32,
            borderRadius: 4,
            ...style?.entryRow
        },
        entry: {
            flex: 1,
        },
        activeEntry: {
            textDecorationLine: 'underline',
        },
    });

    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>

            {/* Header */}
            <View style={{ flexDirection: 'row', alignContent: 'center' }}><Title title={title} /></View>

            {/* Modal */}
            <ScrollableModal>
                <View style={styles.container}>
                    {/* Column Titles */}
                    <View style={{ ...styles.table, paddingHorizontal: 32 }}>
                        {columns.map((column, index) => (<DefaultText key={index} font='bold' style={styles.entry}>{column}</DefaultText>))}
                    </View>

                    <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 4, marginBottom: 10 }} />

                    {/* Data Display */}
                    {data.length > 0 && data.map((entry: any, index) => (
                        <View key={index} style={{ ...styles.table }}>
                            <Interactive onEnter={() => { if (link) setActive(index) }} onLeave={() => setActive(-1)} onPress={() => { if (link) navigate(`${link}${entry[linkRoute]}`) }} style={{ width: '100%', cursor: link ? 'pointer' : null }}>
                                {/* Data Entry */}
                                <View style={{ ...styles.entryRow, ...styles.entry, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary }}>
                                    {populate(entry).map((entryData: any, entryIndex: number) => {
                                        return <DefaultText key={entryIndex} style={{ ...styles.entry, ...(active == index ? styles.activeEntry : null) }}>{entryData}</DefaultText>
                                    })}
                                </View>
                            </Interactive>
                        </View>
                    ))}

                    {/* No data message. */}
                    {data.length === 0 && <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}><DefaultText style={{ color: theme.text.secondary }}>No data found.</DefaultText></View>}
                </View>
            </ScrollableModal>
        </View>
    )
}