import React from "react";
import { Dimensions } from 'react-native';

type ScaleType = {
    dimensions: {
        width: number,
        height: number,
    },
    style: StyleFunction
}

type StyleFunction = (style: Object, breakpoint: keyof typeof breakpoints) => any | null;

const ScaleContext = React.createContext({} as ScaleType);

const breakpoints = {
    'any': '0px',
    'sm': '640px',
    'md': '768px',
    'lg': '1024px',
    'xl': '1280px',
    '2xl': '1536px',
};

function regulateStyle(style: any, breakpoint: keyof typeof breakpoints | number, dimensions: { width: number, height: number }) {
    const limit = typeof(breakpoint) === 'number' ? breakpoint : parseInt(breakpoints[breakpoint]);
    if (!limit && limit !== 0) { return null; }
    if (dimensions.width >= limit) { return style; }
    return null;
}

export function ScaleProvider({ children }: { children: React.ReactNode }) {
    const [scale, setScale] = React.useState({
        dimensions: Dimensions.get('window'),
        style: (style, breakpoint) => { return regulateStyle(style, breakpoint, Dimensions.get('window')); }
    } as ScaleType);

    React.useEffect(() => {
        Dimensions.addEventListener('change', (e) => { 
            setScale({ 
                dimensions: e.window, 
                style: (style, breakpoint) => { return regulateStyle(style, breakpoint, e.window); }
            });
        });
    }, []);

    return (
        <ScaleContext.Provider value={scale}>
            {children}
        </ScaleContext.Provider>
    );
}

export default function ScaleConsumer() {
    return React.useContext(ScaleContext);
}