import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from './Text';
import useTheme from '../contexts/theme';
import { Octicons } from '@expo/vector-icons';
import useScale from '../contexts/scale';

export default function Banner({ emoji, text, style }: { emoji: string, text: string, style?: Object }) {
    const [visible, setVisible] = React.useState(true);
    const { style: _style } = useScale();

    const { theme } = useTheme();
    const styles = StyleSheet.create({
        divider: {
            width: '100%',
            backgroundColor: theme.alternative,
            borderRadius: 8,
            paddingHorizontal: 24,
            paddingVertical: 12,
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 24,
            ...theme.shadowPrimary,
            ...style
        },
        icon: {
            padding: 4,
            borderRadius: 8,
            backgroundColor: theme.background,
            marginRight: 12
        }
    });
    if (!visible) { return null; }
    return (
        <View style={styles.divider}>
            <View style={styles.icon}>
                <DefaultText style={{ fontSize: 24 }}>{emoji}</DefaultText>
            </View>
            <DefaultText style={{ fontSize: 14, ..._style({ fontSize: 18 }, 'md') }}>{text}</DefaultText>
            <Octicons style={{ marginLeft: 'auto', paddingLeft: '15px' }} name='x' size={24} color={theme.text.color} onPress={() => setVisible(false)} />
        </View>
    );
}