import React from 'react';

import { View } from 'react-native';
import Modal from '../components/Modal';
import { DefaultText } from '../components/Text';
import { Link, useParams, useLocation } from 'react-router-dom';
import useAuth from '../contexts/auth';
import useToast from '../contexts/toast';
import { handleFailure } from '../utility/network';
import useTheme from '../contexts/theme';
import useScale from '../contexts/scale';

import Title from '../components/Title';
export default function VerifyEmail({}) {
    const { request } = useAuth();
    const push = useToast();
    const { theme } = useTheme();

    const location = useLocation();
    const { email } = useParams();
    const signature = new URLSearchParams(location.search).get('signature')
    const { style } = useScale();

    const [verificationStatus, setVerificationStatus] = React.useState('Verifying email address...');
    const [verified, setVerified] = React.useState(false);

    React.useEffect(() => {
        request.current.get(`/users/verify-email/${email}?signature=${signature}`)
        .then(() => {
            push('Email verified successfully', 'success');
            setVerificationStatus('Email verified successfully!');
            setVerified(true);
        })
        .catch((res: any) => {
            handleFailure(res, push);
            setVerificationStatus('Email verification failed.')
        });
    }, [])

    const tip = verified ? (
        <DefaultText style={{ color: theme.text.secondary }}>
            Welcome to Atlas.&nbsp;
            <br></br>
            <Link to="/login" style={{ color: theme.text.secondary }}>
                <DefaultText font='bold' style={{ color: theme.text.secondary }}>Log in.</DefaultText>
            </Link>
        </DefaultText>
    )
    : (
        <DefaultText style={{ color: theme.text.secondary }}>
            Can't access your account?&nbsp;
            <br></br>
            <Link to="/reset-password" style={{ color: theme.text.secondary }}>
                <DefaultText font='bold' style={{ color: theme.text.secondary }}>Learn more.</DefaultText>
            </Link>
        </DefaultText>
    );

    return (
        <View style={{flexDirection: 'column', alignItems: 'center'}}>
            <Title title="Email Verification"/>
            <Modal style={{container: {...style({ width: '80%' }, 'any'), ...style({ width: '50%' }, 'md'), ...style({ width: '35%' }, 'xl'), textAlign: 'center'}}}>
                <DefaultText font='semibold' style={{fontSize: 24}}>📧 Email Verification</DefaultText>
                <DefaultText style={{fontSize: 16, marginTop: 6}}>{verificationStatus}</DefaultText>
                <DefaultText style={{ color: theme.text.secondary}}>👤 {email}</DefaultText>
                <View style={{marginTop: 12}}>{tip}</View>
            </Modal>
        </View>
    )
}
