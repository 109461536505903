import React from 'react';
import { View } from 'react-native';
import useTheme from '../../../contexts/theme';
import useAuth, { User } from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import ProfileCircle from '../../../components/ProfileCircle';
import { DefaultText } from '../../../components/Text';
import Table from '../../../components/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalTitle } from '../../../components/Modal';
import Title from '../../../components/Title';
import useScale from '../../../contexts/scale'
import { compactDate, compactDay } from '../../../utility/date';
import Status from '../../../components/InvoiceStatus';
import Button from '../../../components/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';
import { useContext } from '../Root';

export default function Home() {
    const { theme } = useTheme();
    const { request, updateToken } = useAuth();
    const push = useToast();
    const { uuid } = useParams();
    const { style } = useScale();

    const [user, setUser] = React.useState(null as User);
    const [services, setServices] = React.useState(null as any);
    const [invoices, setInvoices] = React.useState(null as any);
    const navigate = useNavigate();

    const { setTitle, setCustomTree } = useContext();

    React.useEffect(() => {
        setTitle(`Client - ${user?.profile?.firstName || ''} ${user?.profile?.lastName || user?.email}`);
        setCustomTree([`${user?.profile?.firstName || ''} ${user?.profile?.lastName || user?.email}`])
        return () => {
            setTitle(null);
            setCustomTree(null);
        }
    }, [user])

    /**
     * Retrieves additional user data *only if* profile is present.
     * Services, invoices, etc.
     */
    React.useEffect(() => {
        if (!user?.profile) { return; }

        // @GET /admin/billing/stripe/subscriptions
        request.current.get(`/admin/billing/stripe/subscriptions/${user!.uuid}`)
            .then((response: any) => {
                setServices(response.data.data.data);
            })
            .catch((response: any) => {
                push("Failed to retrieve user services.", 'error');
                console.log(response.data)
            });

        // @GET /admin/billing/stripe/invoices
        request.current.get(`/admin/billing/stripe/invoices/${user!.uuid}`)
            .then((response: any) => {
                setInvoices(response.data.data.data);
            })
            .catch((response: any) => {
                push("Failed to retrieve user invoices.", 'error');
                console.log(response.data)
            });
    }, [user])

    React.useEffect(() => {
        request.current.get(`/admin/users/${uuid}`)
            .then((response: any) => {
                setUser(response.data.data);
                document.title = `${response.data.data.profile?.firstName || '?'} ${response.data.data.profile?.lastName || '?'} - Atlas`;
            })
            .catch((response: any) => {
                push("Failed to retrieve user data.", 'error');
                console.log(response.data)
            });
    }, []);

    const elementStyle = {
        title: style({ fontSize: 20 }, 'xl'),
        container: { flex: 1 }
    }

    const clientInformation = [
        { title: "Name", content: `${user?.profile?.firstName || '---'} ${user?.profile?.lastName || '---'}` },
        // { title: "Status", content: `${user?.status}` },
        { title: "Status", content: <Status status={user?.status || 'UNKNOWN'} type={'accountState'} style={{ fontSize: 8, marginLeft: 'auto', alignSelf: 'flex-end', border: '1px solid', paddingVertical: 2, paddingHorizontal: 4 }} /> },
        { title: "UUID", content: user?.uuid },
        { title: "Company", content: user?.profile?.companyName || 'NULL' },
        { title: "Email", content: user?.email },
        { title: "Phone", content: user?.profile?.phoneNumber },
        { title: "Address One", content: user?.profile?.addressOne },
        { title: "Address Two", content: user?.profile?.addressTwo || 'NULL' },
        { title: "City", content: user?.profile?.city },
        { title: "Region", content: user?.profile?.region },
        { title: "Country", content: user?.profile?.country },
        { title: "Postal Code", content: user?.profile?.postalCode },
        { title: "Created At", content: user?.createdAt ? compactDate(user?.createdAt) : "Unknown" },
        { title: "Updated At", content: user?.updatedAt ? compactDate(user?.updatedAt) : "Unknown" },
    ]

    const manageOptions = [
        { title: "✏️ Edit", description: "Edit a user's profile and privileges.", action: () => { } },
        { title: "🔑 Reset Password", description: "Trigger a password reset for the user.", action: () => { } },
        {
            title: "🎭 Log in as", description: "Log in as the user.", action: async () => {
                // Check current storage for "originToken"
                const hasOriginToken = await AsyncStorage.getItem('originToken');
                if (hasOriginToken) { push("You are already logged in as another user.", 'error'); return; }

                if (!user?.uuid) { push("Failed to retrieve user UUID.", 'error'); return; }

                // Request new token from the API.
                await request.current.post('/admin/users/login', { uuid: user.uuid, name: "Administration" })
                    .then(async (response: any) => {
                        // Store current token as "originToken"
                        // @ts-ignore
                        AsyncStorage.setItem('originToken', await AsyncStorage.getItem('token'));

                        // Update auth token.
                        updateToken(response.data.data.token);

                        // Navigate to dashboard.
                        navigate('/dashboard/');
                    })
                    .catch((response: any) => {
                        push("Failed to log in as user.", 'error');
                        console.log(response.data)
                    });
            }
        },
        { title: "✉️ Send Message", description: "Send a message to the user via the portal and email.", action: () => { } },
    ]

    return (
        <View style={{}}>
            {/* Header */}
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}><Title title={user?.email ? `${user?.profile?.firstName} ${user?.profile?.lastName}` : "User Management"} /></View>

            <View style={{ flexDirection: "row", gap: 12, marginBottom: 12 }}>
                {/* Client Information */}
                <ModalTitle title="Client Information" style={elementStyle}>
                    {
                        clientInformation.map((entry, index) => {
                            return (
                                <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 8, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary }}>
                                    <DefaultText font='bold' style={{ flex: 1, fontSize: 12 }}>{entry.title}</DefaultText>
                                    <DefaultText style={{ flex: 1, fontSize: 12 }}>{entry.content}</DefaultText>
                                </View>
                            )
                        })
                    }
                </ModalTitle>

                {/* Services */}
                <ModalTitle title={'Services' + ` (${services?.length || 0})`} style={elementStyle}>
                    {
                        services && services.map((entry, index) => {
                            return (
                                <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 8, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                        {/* Product Name */}
                                        <DefaultText font='bold' style={{ fontSize: 12 }}>{entry.plan.product.name}</DefaultText>
                                        {/* Quantity and Label */}
                                        <DefaultText style={{ fontSize: 12 }}>: x{entry.quantity} {entry.plan.product.unit_label ? entry.plan.product.unit_label : ''} @ </DefaultText>
                                        {/* Amount and Interval */}
                                        <DefaultText style={{ fontSize: 12 }}>${((entry.quantity * entry.plan.amount) / 100).toFixed(2) + `/${entry.plan.interval}`}</DefaultText>
                                        {/* Service Status */}
                                        <Status status={entry.status} type={'subscriptions'} style={{ fontSize: 10, marginLeft: 'auto', alignSelf: 'flex-end', paddingVertical: 2, paddingHorizontal: 4 }} />
                                    </View>
                                    <DefaultText style={{ flex: 1 }}>{entry.content}</DefaultText>
                                </View>
                            )
                        })
                    }
                    {(!services || services.length === 0) && <DefaultText style={{ color: theme.text.secondary }}>No services found.</DefaultText>}
                </ModalTitle>

                {/* Invoices */}
                <ModalTitle title={'Invoices' + ` (${invoices?.length || 0})`} style={elementStyle}>
                    {
                        invoices && invoices.map((entry, index) => {
                            return (
                                <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 8, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                        {/* Invoice Number */}
                                        <DefaultText font='bold' style={{ fontSize: 12 }}>{entry.number}</DefaultText>
                                        {/* Starting Date */}
                                        <DefaultText style={{ fontSize: 12 }}> | {compactDay(entry.created * 1000)} - </DefaultText>
                                        {/* Ending Date */}
                                        <DefaultText style={{ fontSize: 12 }}>{entry.due_date ? compactDay(entry.due_date * 1000) : compactDay((entry.created + 23 * 60 * 60) * 1000)} - </DefaultText>
                                        {/* Amount Due */}
                                        <DefaultText style={{ fontSize: 12 }}>${(entry.amount_due / 100).toFixed(2)}</DefaultText>
                                        {/* Service Status */}
                                        <Status status={entry.status} type={'invoices'} style={{ fontSize: 10, marginLeft: 'auto', alignSelf: 'flex-end', paddingVertical: 2, paddingHorizontal: 4 }} />
                                    </View>
                                    <DefaultText style={{ flex: 1 }}>{entry.content}</DefaultText>
                                </View>
                            )
                        })
                    }
                    {(!invoices || invoices.length === 0) && <DefaultText style={{ color: theme.text.secondary }}>No invoices found.</DefaultText>}
                </ModalTitle>

                {/* Manage Client */}
                <ModalTitle title="Manage Client" style={elementStyle}>
                    {
                        manageOptions.map((entry, index) => {
                            return (
                                <View key={index} style={{ width: 'fit-content', marginTop: 12 }}>
                                    <DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>{entry.title}</DefaultText>
                                    <DefaultText font='semibold' style={{ color: theme.text.secondary }}>{entry.description}</DefaultText>

                                    <View style={{ marginTop: 4 }}>
                                        <Button title={entry.title} onPress={entry.action} />
                                    </View>
                                </View>
                            )
                        })
                    }
                </ModalTitle>
            </View>

            <View style={{ flexDirection: "row", gap: 12 }}>
                {/* Tickets */}
                <ModalTitle title="Tickets" style={elementStyle}>
                    <DefaultText style={{ color: theme.text.secondary }}>No tickets found.</DefaultText>
                </ModalTitle>

                {/* Notes */}
                <ModalTitle title="Notes" style={elementStyle}>
                    <DefaultText style={{ color: theme.text.secondary }}>No notes found.</DefaultText>
                </ModalTitle>
            </View>
        </View >
    )
}

// set children [0] and [1] to be before and after respectively.

