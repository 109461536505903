import React from 'react';
import { View } from 'react-native';
import { DefaultText } from './Text';
import Divider from './Divider';
import useTheme from '../contexts/theme';
export default function Title({ title, fontSize }: { title: string, fontSize?: number }) {
    const { theme } = useTheme();
    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <DefaultText font="bold" style={{ textAlign: 'center', fontSize: fontSize || 18, paddingHorizontal: 30 }}>Atlas - {title}</DefaultText>
            <Divider style={{ marginTop: 8, backgroundColor: theme.accent }} />
        </View>
    );
}