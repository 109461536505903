import React from 'react';
import { View, StyleSheet } from 'react-native';
// import { useLocation } from 'react-router-dom';
import { getTree } from '../utility/location';

import { DefaultText, TextLinkInteractive } from './Text';
import useTheme from '../contexts/theme';

import { Link, useNavigate } from 'react-router-dom';
import { makeTitle } from '../utility/string';
import Interactive from './Interactive';

function RouteDivider({ style }: { style?: Object }) {
    return (
        <DefaultText font="bold" style={style}>/</DefaultText>
    );
}

type TreeStyles = {
    container: Object,
    text: Object,
    divider: Object,
}

function Breadcrumb({ tree, index, styles, navigate }: { tree: string[], index: number, styles: TreeStyles, navigate: any }) {
    const title = tree[index] === '/' ? 'Atlas' : makeTitle(tree[index]);

    const textStyle = index === 0 ? { ...styles.text, marginLeft: 0 } : styles.text;

    // const interaction = {
    //     onEnter: (set, style) => { set({ ...style, textDecorationLine: 'underline' }) },
    //     onLeave: (set, style) => { set({ ...style, textDecorationLine: 'none' }) }
    // }
    return (
        <View style={{ flexDirection: 'row' }}>
            <TextLinkInteractive font="semibold" style={textStyle} link={getParentURL(tree, index)} navigate={navigate}>
                {title}
            </TextLinkInteractive>
            {index < tree.length - 1 ? <RouteDivider style={styles.divider} /> : null}
        </View>
    );
}

function getParentURL(tree: string[], index: number) {
    let url = '';
    for (let i = 0; i <= index; i++) {
        url += tree[i];
        if (i < index && i) { url += '/'; }
    }
    return url;
}

export default function Tree({ customTree, style }: { customTree?: Array<string> | null, style?: Object }) {
    const { theme } = useTheme();
    const tree = getTree();
    const navigate = useNavigate();

    if (customTree) { tree.splice(tree.length - customTree.length, customTree.length, ...customTree); }
    
    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            ...style
        },
        text: {
            fontSize: 16,
            marginHorizontal: 8
        },
        divider: {
            color: theme.accent,
            fontSize: 16,
        }
    }) as TreeStyles;

    return (
        <View style={styles.container}>
            {tree.map((item, index) => {
                return (<Breadcrumb key={index} tree={tree} index={index} styles={styles} navigate={navigate} />);
            })}
        </View>
    );
}