import React from 'react';

import { View } from "react-native"
import Table from "../../../components/Table"
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import { DefaultText } from '../../../components/Text';
import { compactDate, compactDay } from '../../../utility/date';

export default function Tokens() {
    const { request } = useAuth();
    const push = useToast();

    const [tokens, setTokens] = React.useState([]);

    function requestTokens() {
        request.current.get('/admin/services/tokens')
        .then((response: any) => {
            setTokens(response.data.data);
        })
        .catch((response: any) => {
            push("Failed to retrieve service tokens.", 'error');
            console.log(response.data)
        });
    }

    React.useEffect(() => { requestTokens(); }, []);

    return (
        <View>
            <View style={{marginBottom: 24}}>
                <Table title="Service Tokens" columns={["ID", "Name", "Type", "Last Used", "Expires At", "Created At", "Revoke"]} data={tokens} style={{ entryRow: { paddingVertical: 8 } }} populate={
                    (entry: any) => { return [entry.id, entry.name, entry.type, compactDate(entry.usedAt), entry.expiresAt ? compactDate(entry.expiresAt) : "Never", compactDate(entry.createdAt), <Button title="Revoke" onPress={() => {
                        request.current.delete(`/admin/services/tokens/${entry.id}`)
                        .then((response: any) => {
                            requestTokens();
                            push("Service token revoked successfully.", 'success');
                        })
                        .catch((response: any) => {
                            push("Failed to revoke service token.", 'error');
                            console.log(response.data)
                        });
                    }}></Button>] }
                } />
            </View >
            <Form title="Create Service Token" submissionText="Create" fields={[
                { label: 'Name', placeholder: 'Token Name', maxLength: 32, payloadKey: 'name' },
                { label: 'Expiration', placeholder: 'Expiration in ms', maxLength: 32, payloadKey: 'expiration' }
            ]} onPress={(data: any, cb) => {
                request.current.post('/admin/services/tokens', data)
                    .then((response: any) => {
                        requestTokens();
                        push("Service token created successfully.", 'success');
                        cb(<View><DefaultText><DefaultText font='bold'>Service Token: </DefaultText>{response.data.data.token}</DefaultText></View>)
                    })
                    .catch((response: any) => {
                        push("Failed to create service token.", 'error');
                        console.log(response.data)
                    });
                
            }} />
        </View>
    )
}