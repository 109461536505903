import React from 'react';
import { View } from 'react-native';
import { DefaultText } from '../../../components/Text';
import useTheme from '../../../contexts/theme';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import Modal, { ModalList } from '../../../components/Modal';
import Divider, { VerticalDivider } from '../../../components/Divider';

import { compactDate } from '../../../utility/date';
import useScale from '../../../contexts/scale';
import { handleFailure } from '../../../utility/network';
import Button from '../../../components/Button';

import ColorPicker from 'react-native-wheel-color-picker'
import TextInput from '../../../components/TextInput';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URL, TUNEBOX_URL } from '@env';

/**
 * Tunebox service configuration input.
 * @returns {React.ReactNode}
 */
function ConfigInput({ title, description, value, placeholder, maxLength, onChangeText, children, showDivider = true }: { title: string, description: string, value: string | number, placeholder: string, maxLength: number, onChangeText: (value: any) => void, children?: React.ReactNode, showDivider?: boolean }) {
	const { theme } = useTheme();
	return (
		<>
			<View>
				<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>{title}</DefaultText>
				<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12, marginBottom: 2 }}>{description}</DefaultText>
				<TextInput value={value.toString()} placeholder={placeholder} maxLength={maxLength} onChangeText={onChangeText} />
				{children}
			</View>
			{showDivider && <Divider style={{ backgroundColor: theme.alternativeSecondary }} />}
		</>
	)
}

type Active = {
	id: number;
	userUuid: string;
	subscriptionUuid: string;
	slug: string;
	spotify: {
		accessToken: string | null;
		refreshToken: string | null;
		expiresAt: number | null;
	};
	config: {
		disabled: boolean;
		device: string;
		playlist: string;
		playlistSyncedAt: number;
		queue: number | string;
		pool: number | string;
		title: string;
		logo: string;
		color: string;
		hideUsers: boolean;
	};
	createdAt: string;
	updatedAt: string;
}

export default function Tunebox() {
	const { theme } = useTheme();
	const push = useToast();
	const { request, user, leaseToken, validateToken } = useAuth();
	const { style } = useScale();
	const [services, setServices] = React.useState([] as Active[]);
	const [active, setActive] = React.useState({} as Active);

	const [leasing, setLeasing] = React.useState(false);

	// Retrieve the latest config.
	React.useEffect(() => {
		request.current('/services/tunebox')
			.then((response: any) => { setServices([...response.data.data]); setActive(response.data.data[0]); })
			.catch((response: any) => { handleFailure(response, push); });
	}, []);

	/**
	 * Update with the latest config.
	 * @return {void}
	 */
	function updateConfig() {
		request.current.put("/services/tunebox/" + active.id, active).then((res: any) => {
			push("Configuration saved!");
			push("Changes may take up to 5m to take effect.", "info");
			request.current('/services/tunebox')
				.then((response: any) => {
					setServices([...response.data.data]);

					for (const key in response.data.data) {
						if (response.data.data[key].id === active.id) {
							setActive(response.data.data[key]);
							break;
						}
					}
				})
				.catch((response: any) => { handleFailure(response, push); });
		})
			.catch((response: any) => {
				handleFailure(response, push);
			});
	}

	/**
	 * Open the tunebox admin panel.
	 * @param {string} token - The atlas access token.
	 * @return {void}
	 */
	function openAdminPanel(token: string) {
		window.open(`${TUNEBOX_URL}/admin/${active.slug}?token=${token}`, '_blank');
		setLeasing(false);
		push("Tunebox admin panel opened.", "success");
	}

	/**
	 * All config options.
	 */
	const options = [
		[
			// URL Slug
			{
				title: '🧭 URL Route',
				description: 'The URL route for your Tunebox service.',
				value: active.slug ? '/' + active.slug : "",
				placeholder: '/slug',
				maxLength: 30,
				onChangeText: (text: string) => {
					if (text[0] === "/") text = text.substring(1);

					// Only allow alphanumeric characters, "-", and "/".
					text = text.replace(/[^a-zA-Z0-9/-]/g, "");
					setActive({ ...active, slug: text });
				}
			},
			// Logo
			{
				title: '🖼️ Logo',
				description: 'Provide an acessible image URL to be displayed.',
				children: <DefaultText style={{ marginTop: 2, fontSize: 12, color: theme.text.secondary }}>Recommended size: 500x500px</DefaultText>,
				value: active.config?.logo || "",
				placeholder: 'https://files.atlasnxs.com/logo.png',
				maxLength: 125,
				onChangeText: (text: string) => {
					active.config.logo = text.replaceAll(" ", "");
					setActive({ ...active });
				},
			},
			{
				title: '📝 Title',
				description: 'The title of your Tunebox service.',
				value: active.config?.title || "",
				placeholder: "Tunebox - Music Voter",
				maxLength: 50,
				onChangeText: (text: string) => {
					active.config.title = text;
					setActive({ ...active });
				}
			},
		],
		[
			// Queue
			{
				title: '🔁 Queue',
				description: 'How many song pools are available at a time.',
				value: active.config?.queue || (active.config?.queue === '' ? '' : 5),
				placeholder: "5",
				maxLength: 2,
				onChangeText: (text: string) => {
					active.config.queue = text === '' ? '' : text.replace(/[^0-9]+/g, '');
					setActive({ ...active });
				}
			},
			// Pool
			{
				title: '📚 Pool',
				description: 'How many songs are within each pool.',
				children: <DefaultText style={{ marginTop: 2, fontSize: 12, color: theme.text.secondary }}>Selected playlist must be larger than {active.config ? Number(active.config.pool) * Number(active.config.queue) : 0}.</DefaultText>,
				value: active.config?.pool || (active.config?.pool === '' ? '' : 5),
				placeholder: "5",
				maxLength: 2,
				onChangeText: (text: string) => {
					active.config.pool = text === '' ? '' : text.replace(/[^0-9]+/g, '');
					setActive({ ...active });
				}
			},
		],
		[
			// Color
			{
				title: '🎨 Color',
				description: 'Set an accent color.',
				value: active.config?.color || "",
				placeholder: '#ffffff',
				maxLength: 7,
				onChangeText: (text: string) => {
					if (text[0] !== "#") text = "#" + text;
					if (text.length > 7) text = text.substring(0, 7);

					// Only allow hex characters and # - replace non-hex characters with "f".
					text = text.replace(/[^a-fA-F0-9#]/g, "f");

					active.config.color = text;
					setActive({ ...active });
				},
				children: <ColorPicker
					color={active.config?.color.length === 7 ? active?.config?.color : "#000000"}
					onColorChangeComplete={(color) => {
						if (active.config?.color && color !== "#000000") {
							active.config.color = color;
							setActive({ ...active })
						}
					}
					}
					thumbSize={25}
					sliderSize={10}
					swatches={false}
					noSnap={true}
					row={false}
					gapSize={0}
				/>,
				showDivider: !style({}, 'md')
			}
		]
	] as {
		title: string,
		description: string,
		value: string | number,
		placeholder: string,
		maxLength: number,
		onChangeText: (text: string) => void,
		children?: React.ReactNode,
		showDivider?: boolean
	}[][];

	return (
		<View style={{ flexDirection: 'column', ...style({ flexDirection: 'row' }, 'md') }}>

			{/* Container for size/layout change. */}
			<View style={{ flexGrow: 1, flexDirection: 'column', gap: 12, ...style({ flexDirection: 'row' }, 'xl') }}>
				{/* Menu */}
				<View style={{ height: 'min-content', flexGrow: 1, flexDirection: 'column', ...style({ flexDirection: 'row' }, 'md') }}>
					<ModalList title={['Tunebox']} options={[services.map((item, index) => `Service #${index + 1}${item.slug ? ` - ${'/' + item.slug}` : ''}`)]}
						style={{ container: { width: '100%', ...style({ width: undefined }, 'md') } }} onPress={(titleIndex, optionIndex) => { setActive(services[optionIndex]); }} />

					{/* Tunebox Service */}
					<Modal style={{ container: { width: null, flexGrow: 1, marginTop: 12, ...style({ marginLeft: 12, marginTop: 0 }, 'md') } }}>
						<View style={{ width: 'fit-content' }}>

							{/* Title */}
							<DefaultText font='bold' style={{ fontSize: 20, textTransform: 'uppercase' }}>🔊 Tunebox</DefaultText>
							<DefaultText font='semibold' style={{ color: theme.text.secondary }}>Manage your tunebox service.</DefaultText>
							<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

							{/* Admin Login */}
							<View style={{ flexDirection: 'column', width: 'fit-content' }}>
								<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>📻 Admin Panel</DefaultText>
								<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12 }}>Open the Tunebox management panel.</DefaultText>
								<Button title='Log in' icon='cross-reference' style={{ button: { marginTop: 6 } }} onPress={async () => {
									if (leasing) { return; }
									setLeasing(true);
									push("Logging in to Tunebox...", "info");

									const leased = JSON.parse(await AsyncStorage.getItem('leased') || '{}');

									// If token is not expired
									if (leased[user.uuid]?.tunebox?.expiresAt > Date.now() && await validateToken(leased[user.uuid].tunebox.accessToken)) {
										// If token is still valid
										return openAdminPanel(leased[user.uuid].tunebox.accessToken);
									}

									// Lease a new token for 24h.
									const token = await leaseToken("Tunebox", 24 * 60 * 60 * 1000);
									if (!token) { push("Failed to lease token.", "error"); setLeasing(false); return; }

									// Update leased tokens.
									leased[user.uuid] = { ...leased[user.uuid], tunebox: { accessToken: token.token, expiresAt: new Date(token.expiresAt).getTime() } };
									await AsyncStorage.setItem('leased', JSON.stringify(leased));

									// Open admin panel.
									openAdminPanel(token.token);
								}} />
								<DefaultText style={{ marginTop: 4, fontSize: 12, color: theme.text.secondary }}>Playlist
									<DefaultText style={{ fontSize: 12, color: theme.accent }}>{active.config?.playlist ? ` synced${active.config.playlistSyncedAt ? ' ' + compactDate(new Date(active.config.playlistSyncedAt).toISOString()) : ''}` : " is not configured"}.</DefaultText>
								</DefaultText>
							</View>

							<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

							{/* Spotify Status */}
							<View style={{ flexDirection: 'column', width: 'fit-content' }}>
								<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🎶 Spotify Status</DefaultText>
								<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12 }}>Authorize Atlas to use your Spotify account.</DefaultText>
								<Button title={active.spotify?.refreshToken ? "Reset" : "Authorize"} icon='link' style={{ button: { marginTop: 6 } }} onPress={() => {

									// Reset token.
									if (active.spotify?.refreshToken) {
										active.spotify.accessToken = null;
										active.spotify.refreshToken = null;
										active.spotify.expiresAt = null;;
										setActive({ ...active });
										updateConfig();
										return;
									}

									// Authorize Spotify.
									// @ts-ignore
									const queryString = new URLSearchParams({
										response_type: 'code',
										client_id: SPOTIFY_CLIENT_ID,
										scope: "streaming user-read-currently-playing user-read-playback-state user-modify-playback-state playlist-read-private",
										redirect_uri: SPOTIFY_REDIRECT_URL,
										state: active.id
									}).toString();
									window.location.href = "https://accounts.spotify.com/authorize?" + queryString;
								}} />
								<DefaultText style={{ marginTop: 4, fontSize: 12, color: theme.text.secondary }}>*Spotify Premium required.</DefaultText>
							</View>

							<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

							{/* Tunebox Home */}
							<View style={{ flexDirection: 'column', width: 'fit-content' }}>
								<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🏠 Tunebox Home</DefaultText>
								<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12 }}>Open the main Tunebox service.</DefaultText>
								<Button title='Open' icon='cross-reference' style={{ button: { marginTop: 6 } }} onPress={async () => {
									window.open(`${TUNEBOX_URL}/${active.slug}`, '_blank');
								}} />
							</View>

							<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

							{/* Dates */}
							<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
								{/* Created At */}
								<View>
									<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>📅 Created At</DefaultText>
									<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12, ...style({ fontSize: null }, 'md'), }}>{active.createdAt ? compactDate(active.createdAt) : "Unknown"}</DefaultText>
								</View>

								<VerticalDivider />

								{/* Updated At */}
								<View>
									<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>🕑 Updated At</DefaultText>
									<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12, ...style({ fontSize: null }, 'md'), }}>{active.updatedAt ? compactDate(active.updatedAt) : "Unknown"}</DefaultText>
								</View>
							</View>
						</View>
					</Modal>
				</View>

				{/* Configuration */}
				<Modal style={{ container: { width: null, flexGrow: 1 } }}>

					{/* CONFIGURATION */}
					<DefaultText font='bold' style={{ fontSize: 20, textTransform: 'uppercase' }}>🛠️ Configuration</DefaultText>
					<DefaultText font='semibold' style={{ color: theme.text.secondary }}>Configure tunebox preferences.</DefaultText>
					<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

					{/* Options */}
					<View style={{ flexDirection: 'column', gap: 0, ...style({ flexDirection: 'row', gap: 12 }, 'md') }}>
						{options.map((row, index) => {
							return (
								<View key={index} style={style({ flex: 1 }, 'md')}>
									{row.map((option, index) => {
										return (
											<ConfigInput key={index} title={option.title} description={option.description} value={option.value} placeholder={option.placeholder} maxLength={option.maxLength} onChangeText={option.onChangeText} children={option.children} showDivider={option.showDivider} />
										)
									})}
								</View>
							)
						})}
					</View>

					{/* Toggle Options */}
					<View style={{ flexDirection: 'column', gap: 0, ...style({ flexDirection: 'row', gap: 12 }, 'md') }}>

						{/* Status */}
						<View style={{ flexDirection: 'column', flexGrow: 1, ...style({ flex: 1 }, 'md') }}>
							<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>{`🚦 Tunebox Status: ${active.config?.disabled ? "Disabled" : "Enabled"}`}</DefaultText>
							<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12 }}>Toggle the Tunebox service status.</DefaultText>
							<Button title={active.config?.disabled ? "Enable" : "Disable"} icon={active.config?.disabled ? 'check-circle' : 'x-circle'} style={{ button: { marginTop: 6 } }} onPress={() => {
								active.config.disabled = !active.config.disabled;
								setActive({ ...active });
							}} />
						</View>

						{/* Divider */}
						{style({}, 'md') ? <VerticalDivider /> : <Divider style={{ backgroundColor: theme.alternativeSecondary }} />}

						{/* Show Users toggle */}
						<View style={{ flexDirection: 'column', flexGrow: 1, ...style({ flex: 1 }, 'md') }}>
							<DefaultText font='bold' style={{ fontSize: 16, textTransform: 'uppercase' }}>{`👤 Show Users: ${active.config?.hideUsers ? "False" : "True"}`}</DefaultText>
							<DefaultText font='semibold' style={{ color: theme.text.secondary, fontSize: 12 }}>Toggle user and song vote visibility.</DefaultText>
							<Button title={active.config?.hideUsers ? "Enable" : "Disable"} icon={active.config?.hideUsers ? 'check-circle' : 'x-circle'} style={{ button: { marginTop: 6 } }} onPress={() => {
								active.config.hideUsers = !active.config.hideUsers;
								console.log(active)
								setActive({ ...active });
							}} />
						</View>
						<View style={{ ...style({ flex: 1 }, 'md') }}></View>
					</View>

					<Divider style={{ backgroundColor: theme.alternativeSecondary }} />

					{/* Save */}
					<View style={{ flexDirection: 'row' }}>
						<View style={{ flexGrow: 1, flex: 1 }}>
							<Button title='Save' icon='tools' onPress={() => { updateConfig(); }} />
						</View>
						<View style={{ ...style({ flex: 2 }, 'md') }}></View>
					</View>

				</Modal>
			</View>
		</View>
	)
}