import { ToastType } from '../components/Toast';

const requestFailureMessages: {[key: string]: string} = {
    'ERR_NETWORK': 'An unexpected error occurred. (timeout)',
    'ECONNABORTED': 'An unexpected error occurred. (timeout)',
    // 'E_TOO_MANY_REQUESTS': 'You are being rate limited. Try again later.',
    'default': 'An unexpected error occurred.'
}

export function handleFailure(response: any, toast: (content: string, icon?: ToastType | undefined, duration?: number | undefined) => void, defaultText?: string) {
    // Print provided errors if applicable.
    if (response.response?.data?.errors) {
        toast(`${response.response?.data.message}: ${response.response?.data.errors.map((e: any) => { return e.field; }).join(', ')}. (${response.response.status})`, 'error');
    } else {
        // Overrides take precedence.
        toast(requestFailureMessages[response.response?.data?.code] || requestFailureMessages[response.response?.code] || response.response?.data?.message || defaultText || requestFailureMessages.default, 'error');
    }
}