import React from 'react';
import Form, { Field } from '../components/Form';
import { DefaultText } from '../components/Text';
import { Link, useParams } from 'react-router-dom';
import useTheme from '../contexts/theme';
import useToast from '../contexts/toast';
import useAuth from '../contexts/auth';

import { View, Animated } from 'react-native';
import Modal from '../components/Modal';
import { passwordStrength, Options as PasswordStrengthOptions } from 'check-password-strength'
import StatusBar from '../components/StatusBar';

import { handleFailure } from '../utility/network';
import Construction from '../components/Construction';
import useScale from '../contexts/scale';

const passwordStrengths = [
    {
        id: 0,
        value: "Not viable",
        minDiversity: 0,
        minLength: 0
    },
    {
        id: 1,
        value: "Weak",
        minDiversity: 1,
        minLength: 8
    },
    {
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 10
    },
    {
        id: 3,
        value: "Strong",
        minDiversity: 4,
        minLength: 12
    }
] as PasswordStrengthOptions<string>;

function RequestSuccess({ email, linkedOpacity, theme }: { email: string, linkedOpacity: Animated.Value, theme: any }) {
    const { style } = useScale();

    return (
        <Animated.View style={{ width: '100%', marginHorizontal: 'auto', flexDirection: 'column', alignItems: 'center', textAlign: 'center', opacity: linkedOpacity }}>
            <DefaultText font='semibold' style={{ ...style({ fontSize: 20 }, 'any'), ...style({ fontSize: 24 }, 900) }}>🔑 Password Reset Request Sent</DefaultText>
            <DefaultText style={{ fontSize: 16 }}>If this account exists, a password reset request has been sent to your email!</DefaultText>
            <DefaultText style={{ fontSize: 16, marginTop: 12 }}>Please check your inbox for instructions on how to proceed.</DefaultText>
            <DefaultText style={{ fontStyle: 'italic' }}>If you have any additional questions, do not hesitate to <Link to="/contact" style={{ color: theme.accent }}>contact us</Link>.</DefaultText>
            <DefaultText font='medium' style={{ color: theme.text.secondary }}>👤 {email}</DefaultText>
        </Animated.View>
    )
}

function ResetSuccess({ email, linkedOpacity, theme }: { email: string, linkedOpacity: Animated.Value, theme: any }) {
    const { style } = useScale();

    return (
        <Animated.View style={{ width: '100%', marginHorizontal: 'auto', flexDirection: 'column', alignItems: 'center', textAlign: 'center', opacity: linkedOpacity }}>
            <DefaultText font='semibold' style={{ ...style({ fontSize: 20 }, 'any'), ...style({ fontSize: 24 }, 900) }}>🔑 Password Reset</DefaultText>
            <DefaultText style={{ fontSize: 16 }}>Your password has been successfully reset!</DefaultText>
            <DefaultText style={{ fontSize: 16, marginTop: 12 }}>You may now <Link to="/login" style={{ color: theme.accent }}>log in</Link> to your account.</DefaultText>
            <DefaultText style={{ fontStyle: 'italic' }}>If you have any additional questions, do not hesitate to <Link to="/contact" style={{ color: theme.accent }}>contact us</Link>.</DefaultText>
            <DefaultText font='medium' style={{ color: theme.text.secondary }}>👤 {email}</DefaultText>
        </Animated.View>
    )
}

export default function PasswordHandler() {
    const { email } = useParams();
    return email ? <ResetPassword email={email} /> : <RequestPasswordReset />
}

function RequestPasswordReset() {
    const { theme } = useTheme();
    const { request } = useAuth();
    const push = useToast();
    const { style } = useScale();
    const fields: (Field | Field[])[] = [
        {
            label: 'Email address',
            placeholder: 'Email address',
            maxLength: 64,
            payloadKey: 'email',
        },
    ];

    return (
        <Form title="Password Reset" fields={fields} style={{ modal: style({ width: '40%' }, 'xl') }} submissionText="RESET PASSWORD" onPress={(data, cb, linkedOpacity) => {
            request.current.post('/users/reset-password-request', data)
                .then((response: any) => {
                    push('Password reset request sent.', 'success');
                    return true;
                })
                .catch((response: any) => {
                    handleFailure(response, push);
                })
                .then((success: boolean | undefined) => {
                    success ? cb(<RequestSuccess email={data.email} linkedOpacity={linkedOpacity} theme={theme}></RequestSuccess>) : cb();
                });
        }}>
            {/* Remember your password? Log in.*/}
            <DefaultText style={{ color: theme.accent, marginTop: 12 }}>
                Remember your password?&nbsp;
                <Link to="/login" style={{ color: theme.accent }}>
                    <DefaultText font='bold' style={{ color: theme.accent }}>Log in.</DefaultText>
                </Link>
            </DefaultText>

            {/* By resetting your password... */}
            <DefaultText style={{ color: theme.text.secondary, fontSize: 12 }}>
                By resetting your password for Atlas Industries, Inc. you agree to our&nbsp;
                <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                </Link>
                &nbsp;and&nbsp;
                <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                </Link>
            </DefaultText>
        </Form>
    )
}

function ResetPassword({email}: {email: string}) {
    const { theme } = useTheme();
    const { request, requestProfile } = useAuth();
    const push = useToast();
    const { style } = useScale();
    const signature = new URLSearchParams(location.search).get('signature')

    const [status, setStatus] = React.useState(0);
    const [statusText, setStatusText] = React.useState('Password Strength: Unknown');

    const fields: (Field | Field[])[] = [
        {
            label: 'Password',
            placeholder: 'Password',
            maxLength: 64,
            payloadKey: 'password',
            secureTextEntry: true,
            onChangeText: (value: string) => {
                const strength = passwordStrength(value, passwordStrengths);
                setStatus((strength.id / 3) * 100);
                setStatusText(`Password Strength: ${strength.value}`);
            }
        },
        {
            label: 'Password confirmation',
            placeholder: 'Password confirmation',
            maxLength: 64,
            payloadKey: 'password_confirmation',
            secureTextEntry: true,
        },
    ]

    return (
        <Form title="Password Reset" fields={fields} style={{ modal: style({ width: '40%' }, 'xl') }} customFields={<StatusBar title={statusText} status={status} style={{ container: { marginBottom: 16 } }} />} submissionText="RESET PASSWORD" onPress={(data, cb, linkedOpacity) => {
            request.current.post(`/users/reset-password/${email}?signature=${signature}`, data)
                .then((response: any) => {
                    push('Successfully reset password.', 'success');
                    return true;
                })
                .catch((response: any) => {
                    handleFailure(response, push);
                })
                .then((success: boolean | undefined) => {
                    if (success) {
                        cb(<ResetSuccess email={email} linkedOpacity={linkedOpacity} theme={theme}></ResetSuccess>);
                        requestProfile();
                    } else {
                        cb();
                    }
                });
        }}>
            {/* Remember your password? Log in.*/}
            <DefaultText style={{ color: theme.accent, marginTop: 12 }}>
                Remember your password?&nbsp;
                <Link to="/login" style={{ color: theme.accent }}>
                    <DefaultText font='bold' style={{ color: theme.accent }}>Log in.</DefaultText>
                </Link>
            </DefaultText>

            {/* By resetting your password... */}
            <DefaultText style={{ color: theme.text.secondary, fontSize: 12 }}>
                By resetting your password for Atlas Industries, Inc. you agree to our&nbsp;
                <Link to="/legal/terms" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Terms of Service</DefaultText>
                </Link>
                &nbsp;and&nbsp;
                <Link to="/legal/privacy" style={{ color: theme.text.secondary }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary }}>Privacy Policy.</DefaultText>
                </Link>
            </DefaultText>
        </Form>
    );
}