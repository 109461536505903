import { Platform } from 'react-native';

function customShadow({ color, opacity, radius, offsetWidth, offsetHeight, elevation, webOverride }) {
    return Platform.select({
        ios: {
            shadowColor: color,
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            }
        },
        android: {
            elevation
        },
        web: {
            boxShadow: webOverride || `${offsetWidth}px ${offsetHeight}px ${radius}px ${color}`
        }
    });
};


// Note: The following shadows are the same other than webOverride.
// Light theme shadow.
const shadowLight = customShadow({
    elevation: 3,
    color: 'rgba(0, 0, 0, 0.1)',
    opacity: 0.1,
    offsetWidth: 0,
    offsetHeight: 4,
    radius: 6,
    webOverride: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px` // 0.1, light, 0.25+ dark
});

// Dark theme shadow.
const shadowDark = customShadow({
    elevation: 3,
    color: 'rgba(0, 0, 0, 0.1)',
    opacity: 0.1,
    offsetWidth: 0,
    offsetHeight: 4,
    radius: 6,
    webOverride: `rgba(0, 0, 0, 0.5) 0px 4px 6px -1px, rgba(0, 0, 0, 0.5) 0px 2px 4px -2px` // 0.1, light, 0.25+ dark
});

export {
    customShadow,
    shadowLight,
    shadowDark,
}