import React from 'react';
import { View, Platform } from 'react-native';
import { DefaultText } from '../components/Text';
import Modal from '../components/Modal';
import Divider from '../components/Divider';
import useTheme from '../contexts/theme';
import Title from '../components/Title';
import AnimatedEmoji from '../components/AnimatedEmoji';
import Interactive from '../components/Interactive';
import useScale from '../contexts/scale';

const departments = [
    {
        name: '☎️ Support & Customer Service',
        contact: 'support@atlasnxs.com',
        link: 'mailto:support@atlasnxs.com?subject=General%20Support'
    },
    {
        name: '🖥️ Services & Sales Inquiries',
        contact: 'sales@atlasnxs.com',
        link: 'mailto:sales@atlasnxs.com?subject=Sales%20Inquiry',
    },
    {
        name: '🌐 Network Operations Center',
        contact: 'noc@atlasnxs.com',
        link: 'mailto:noc@atlasnxs.com?subject=Abuse%20Report',
    },
    {
        name: '💼 Career Opportunities',
        contact: 'jobs@atlasnxs.com',
        link: 'mailto:jobs@atlasnxs.com?subject=Atlas,%20Inc.%20Application',
    },
]

function openLink(link) {
    if (Platform.OS !== 'web') { return }; // otherwise, using linking API. 
    window.location.href = link;
}

export default function Home() {
    const { theme } = useTheme();
    const { style } = useScale();

    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ ...style({ width: '95%' }, 'any'), ...style({ width: '60%' }, 'md'), ...style({ width: '30%' }, 'xl') }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}><Title title="Contact" fontSize={20} /></View>
                <Modal>
                    <View style={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <DefaultText font='bold' style={{ ...style({ fontSize: 24 }, 'any'), ...style({ fontSize: 28 }, 'sm') }}><AnimatedEmoji emoji="wave" /> Hello there.</DefaultText>
                        <DefaultText font='medium' style={{ ...style({ fontSize: 18 }, 'any'), ...style({ fontSize: 20 }, 'sm') }}>Let's get in touch!</DefaultText>
                        <DefaultText style={{ fontStyle: 'italic', ...style({ fontSize: 14 }, 'any'), ...style({ fontSize: 18 }, 'sm') }}>Below you'll find various ways to contact Atlas Industries, Inc.</DefaultText>

                    </View>
                    <Divider style={{ backgroundColor: theme.text.secondary }} />
                    <View style={{ flexDirection: 'column', gap: 16 }}>
                        {
                            departments.map((department, index) => {
                                return (
                                    <View key={index}>
                                        <DefaultText font='semibold' style={{ ...style({ fontSize: 18 }, 'any'), ...style({ fontSize: 24 }, 'sm') }}>{department.name}</DefaultText>
                                        <DefaultText style={{ ...style({ fontSize: 14 }, 'any'), ...style({ fontSize: 18 }, 'sm') }}>📧 Contact:&nbsp;
                                            <Interactive onPress={() => { openLink(department.link); }}>
                                                <DefaultText style={{ color: theme.accent, textDecorationLine: 'underline' }}>{department.contact}</DefaultText>
                                            </Interactive>
                                        </DefaultText>
                                    </View>
                                )
                            })
                        }
                    </View>
                </Modal>
            </View>
        </View>
    )
}