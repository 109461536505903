import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { DefaultText, TextInteractive, TextLinkInteractive } from './Text';
import useTheme from '../contexts/theme';
import useAuth from '../contexts/auth';
import { getTree, isRouteActive } from '../utility/location';
import { Link, useNavigate } from 'react-router-dom';
import ProfileCircle from './ProfileCircle';
import { Octicons } from '@expo/vector-icons';
import Button from './Button';
import useToast from '../contexts/toast';
import { userHasPrivilege } from '../utility/privilege';
import useScale from '../contexts/scale';
import Divider from './Divider';
import useData from '../contexts/data';

// Header times.
type HeaderItem = { name: string, path: string }
const headerItems: { [key: string]: HeaderItem[] } = {
    default: [
        { name: 'Home', path: '/' },
        { name: 'About', path: '/about' },
        { name: 'Contact', path: '/contact' },
    ],
    auth: [
        { name: 'Home', path: '/' },
        { name: 'About', path: '/about' },
        { name: 'Contact', path: '/contact' },
    ],
    dashboard: [
        { name: 'Home', path: '/dashboard' },
        { name: 'Tickets', path: '/dashboard/tickets' },
        { name: 'Invoices', path: '/dashboard/invoices' },
        { name: 'Settings', path: '/dashboard/settings' },
    ]
}

function MenuOptions() {
    const navigate = useNavigate();
    const { theme, toggle } = useTheme();
    const { style } = useScale();
    const push = useToast();
    const { auth, user, masterPrivileges } = useAuth();

    const styles = StyleSheet.create({
        buttonContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        container: {
            marginLeft: 'auto',
            flexDirection: 'row',
            alignItems: 'center',
        },
        adminButton: {
            marginRight: 24,
        },
        divider: {
            marginLeft: 16,
            marginRight: 20,
            borderRightColor: theme.text.color,
            borderRightWidth: 2,
        }
    });

    return (
        <View style={styles.container}>

            {/* Admin Button */}
            {userHasPrivilege('GLOBAL_FLAG', ['STAFF'], user, masterPrivileges) && (
                <View style={styles.adminButton}>
                    <TextLinkInteractive font="semibold" link="/admin" style={{ fontSize: 16 }} navigate={navigate}>Admin</TextLinkInteractive>
                </View>
            )}

            {/* Dashboard Button */}
            {auth && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Button style={{ button: { paddingHorizontal: 20 }, text: { fontSize: 16 } }} title="Dashboard" onPress={() => { navigate('/register') }} />
                    <View style={styles.divider}><DefaultText> </DefaultText></View>
                </View>
            )}

            {/* Toggle Theme */}
            <Octicons style={{ marginRight: 24, transform: [{ scaleX: -1 }] }} name={theme.name == 'dark' ? 'moon' : 'sun'} size={24} color={theme.text.color} onPress={() => {
                toggle();
            }} />

            {/* Notification Button */}
            <Octicons style={{ marginRight: 24 }} name="bell" size={24} color={theme.text.color} onPress={() => { push('Feature coming soon.', 'info'); }} />


            {auth ? (
                // Profile Button
                <View style={styles.buttonContainer}>
                    <ProfileCircle content={(user?.profile?.firstName && user?.profile?.firstName?.[0].toUpperCase() + user?.profile?.lastName?.[0].toUpperCase()) || '??'} />
                </View>
            ) : (
                // Login and Register Buttons
                <View style={styles.buttonContainer}>
                    <View style={{ borderRightColor: theme.text.color, borderRightWidth: 2, marginRight: 26 }}><DefaultText> </DefaultText></View>
                    <Link to="/login" style={{ textDecoration: 'none' }}><DefaultText font='bold' style={{ fontSize: 16 }}>Login</DefaultText></Link>
                    <Button style={{ button: { marginLeft: 24, paddingHorizontal: 20 }, text: { fontSize: 16 } }} title="Register" onPress={() => { navigate('/register') }} />
                </View>
            )}
        </View>
    )
}

function MenuLinks() {
    const { theme } = useTheme();
    const location = getTree();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { style } = useScale();

    const styles = StyleSheet.create({
        options: {
            marginLeft: 24,
            flexDirection: 'row',
            alignItems: 'center',
            ...style({visibility: 'hidden', width: 0}, 'any'),
            ...style({visibility: 'visible', width: 'auto'}, 900)
        },
        item: { marginRight: 32 },
    });

    return (
        <View style={styles.options}>
            {getHeaderLinks(location, auth).map((item, index) => (
                <View key={index} style={{ ...styles.item, borderBottomWidth: isRouteActive(item.path, location) ? 2 : 0, borderColor: theme.accent }}>
                    <TextLinkInteractive style={{ fontSize: 16 }} link={item.path} navigate={navigate}>{item.name}</TextLinkInteractive>
                </View>
            ))}
        </View>
    )
}

function MobileMenu() {
    const { theme } = useTheme();
    const location = getTree();
    const navigate = useNavigate();
    const { auth, user, masterPrivileges } = useAuth();

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 20,
        },
        item: {
            marginVertical: 4,
        }
    });
    return (
        <View style={styles.container}>
            {/* Navigational Links */}
            {getHeaderLinks(location, auth).map((item, index) => (
                <View key={index} style={{ ...styles.item, borderBottomWidth: isRouteActive(item.path, location) ? 2 : 0, borderColor: theme.accent }}>
                    <TextLinkInteractive style={{ fontSize: 16 }} link={item.path} navigate={navigate}>{item.name}</TextLinkInteractive>
                </View>
            ))}

            {/* Divider */}
            <Divider />

            {/* if !auth vs auth - display login/register */}
            <View style={{ flexDirection: 'column', alignItems: 'center', width: '100%', paddingHorizontal: 12 }}>

                {/* Admin Button */}
                {userHasPrivilege('GLOBAL_FLAG', ['STAFF'], user, masterPrivileges) && (
                    <View style={{ marginVertical: 4 }}>
                        <TextLinkInteractive font="semibold" link="/admin" style={{ fontSize: 16 }} navigate={navigate}>Admin</TextLinkInteractive>
                    </View>
                )}

                {/* Dashboard Button */}
                {auth && (
                    <Button style={{ button: { paddingHorizontal: 20, width: '100%', marginVertical: 4 }, text: { fontSize: 16 } }} title="Dashboard" onPress={() => { navigate('/dashboard') }} />
                )}

                {/* Login Button */}
                {!auth && (
                    <View style={{ marginVertical: 4 }}>
                        <TextLinkInteractive font="semibold" link="/login" style={{ fontSize: 16 }} navigate={navigate}>Login</TextLinkInteractive>
                    </View>
                )}
                
                {/* Register Button */}
                {!auth && (
                    <Button style={{ button: { paddingHorizontal: 20, width: '100%', marginVertical: 4 }, text: { fontSize: 16 } }} title="Register" onPress={() => { navigate('/register') }} />
                )}
            </View>
        </View>
    )
}

function MobileMenuButton({ status, cb }: { status: boolean, cb: () => void }) {
    const { theme, toggle } = useTheme();
    const push = useToast();

    const styles = StyleSheet.create({
        container: {
            marginLeft: 'auto',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            paddingVertical: 8,
            paddingHorizontal: 12,
            backgroundColor: theme.alternative,
            borderRadius: 8,

            width: 45,
            textAlign: 'center',

        }
    });
    return (
        <View style={styles.container}>
            {/* Toggle Theme */}
            <Octicons style={{ marginRight: 18, transform: [{ scaleX: -1 }] }} name={theme.name == 'dark' ? 'moon' : 'sun'} size={24} color={theme.text.color} onPress={() => {
                toggle();
            }} />

            {/* Notifications Button */}
            <Octicons style={{ marginRight: 18 }} name="bell" size={24} color={theme.text.color} onPress={() => { push('Feature coming soon.', 'info'); }} />

            {/* Menu Toggle Button */}
            <Octicons name={status ? "x" : "three-bars"} style={styles.icon} size={24} color={theme.text.color} onPress={cb} />
        </View>
    );
}

function getHeaderLinks(location: String[], auth: any): HeaderItem[] {
    for (const key in headerItems) { if (location.includes(key)) { return headerItems[key]; } }
    if (auth) { return headerItems.auth; }
    return headerItems.default;
}

export default function Header() {
    const { theme } = useTheme();
    const location = getTree();
    const { style } = useScale();
    const { setStatic, getStatic, getEph, dataLink, setEph } = useData();

    const [displayMobileMenu, setDisplayMobileMenu] = React.useState(false);

    const styles = StyleSheet.create({
        header: {
            width: '100%',
            height: 64,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.background,
            paddingHorizontal: 24,
            borderRadius: 3,
            ...theme.shadowPrimary
        },
        logo: {
            width: 35,
            height: 35,
            marginRight: 4
        },
        titleContainer: {
            paddingRight: 26,
            borderRightColor: theme.text.color,
            ...style({ borderRightWidth: 0 }, 'any'),
            ...style({ borderRightWidth: 2 }, 900)
        },
    });

    const [dashMenuActive, setdashMenuActive] = React.useState(true);

    // Retrieve sidebar status.
    React.useEffect(() => {
        (async () => {
            const staticState = await getStatic('dashboardMenuClosed');
            setdashMenuActive(getEph('dashMenuClosedOverride') ? false : !Boolean(staticState)); 
        })();
    }, [dataLink.current])

    return (
        <View>
            <View style={styles.header}>
                { /*Left Hambuger Menu*/}

                {(location.includes('dashboard') || location.includes('admin')) &&
                    <Octicons name={dashMenuActive ? 'x' : "three-bars"} style={{ marginRight: 18, width: 21 }} size={24} color={theme.text.color} onPress={async () => {
                        // If override is set, remove it.
                        getEph('dashMenuClosedOverride') && setEph('dashMenuClosedOverride', false);

                        // Update static storage.
                        setStatic('dashboardMenuClosed', dashMenuActive);
                    }} />
                }

                { /* Logo */}
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image source={theme.logo} style={styles.logo} />
                        { /* Title */}
                        <View style={styles.titleContainer}>
                            <DefaultText font="bold" style={{ fontSize: 18, lineHeight: 28 }}>Atlas</DefaultText>
                        </View>
                    </View>
                </Link>

                {style(true, 'sm') && <MenuLinks />}
                {style(true, 'sm') ? <MenuOptions /> : (<MobileMenuButton status={displayMobileMenu} cb={() => {
                    setDisplayMobileMenu(!displayMobileMenu);
                }} />)}
            </View>
            {!style(true, 'sm') && displayMobileMenu && <MobileMenu />}
        </View>
    );
}