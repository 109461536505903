import React from "react";
import ToastElement from '../components/Toast';
import { ToastType, ToastAnimation } from '../components/Toast';

const ToastContext = React.createContext({} as (content: string, icon?: ToastType, duration?: number) => void);

const defaultAnimation: ToastAnimation = {
    sequence: [-80, 112, 96],
    stepDuration: 300
}

export type ToastFunction = (content: string, icon?: ToastType, duration?: number) => void;

// Todo: Has some minor flaws, could be reworked. (Position mapping for toasts.)

export function ToastProvider({ children }: { children: React.ReactNode }) {
    const [toasts, setToasts] = React.useState([] as React.ReactNode[]);
    const active = React.useRef(0);
    const elementKey = React.useRef(-1);

    /**
     * Creates a new toast notification.
     * @param content - The content of the toast.
     * @param icon - The icon name to be displayed.
     * @param duration - The duration of the toast in seconds.
     * @returns {void}
     */
    function push(content: string, icon?: ToastType, duration = 5) {
        const animation = {
            sequence: [],
            stepDuration: defaultAnimation.stepDuration
        } as ToastAnimation;

        active.current++;
        elementKey.current++;

        // Set the animation sequence.
        for (let i = 0; i < defaultAnimation.sequence.length; i++) { animation.sequence.push(defaultAnimation.sequence[i] * (active.current)); }

        // Create the toast element.
        const newToast = <ToastElement key={elementKey.current} content={content} icon={icon} duration={duration} animation={animation}/>
        setToasts(arr => [...arr, newToast]);

        // Remove the toast after the duration.
        setTimeout(() => {
            active.current = active.current - 1;
            if (active.current > 0) { return; }
            active.current = 0;
            elementKey.current = -1;
            setToasts([]);
        }, (duration * 1000) + animation.stepDuration * animation.sequence.length);
    }

    return (
        <ToastContext.Provider value={push}>
            {toasts.map((toast) => toast)}
            {children}
        </ToastContext.Provider>
    );
}

export default function ToastConsumer() {
    return React.useContext(ToastContext);
}