import React from 'react';
import { Platform } from 'react-native';
import useTheme from '../contexts/theme';
export default function WebScrollbar() {
    if (Platform.OS !== 'web') return null;

    const { theme } = useTheme();
    const style = React.useRef(null as any);

    React.useEffect(() => {
        if (style.current) { document.head.removeChild(style.current); }
        style.current = document.createElement('style');
        style.current.innerHTML = `
        *::-webkit-scrollbar {
            width: 14px;
        }
        
        *::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            border: solid 4px ${theme.background};
        
        }
        
        *::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: ${theme.alternative};
            border: solid 4px ${theme.background};
        }
        
        *::selection {
            background: #c9b670;
        }
        
        img {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }`;
        document.head.appendChild(style.current);
    }, [theme]);

}