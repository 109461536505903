import React from 'react';

import { ScrollView, View, StyleSheet } from 'react-native';
import { Outlet, useLocation } from "react-router-dom";

import Background from '../components/Background';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Splash from './Splash';

import useTheme from '../contexts/theme';
import Exclude from '../components/Exclude';
import Banner from '../components/Banner';

import { getTree } from '../utility/location';
import { makeTitle } from '../utility/string';

import useAuth from '../contexts/auth';

// Router
export default function Root() {
    const { theme } = useTheme();

    const { initialized } = useAuth();

    // Manage website title.
    const location = getTree();
    React.useEffect(() => {
        if (location.length - 1 === 0) { return void (document.title = 'Atlas'); }
        document.title = `${makeTitle(location[location.length - 1])} - Atlas`;
    }, [location]);

    // Required styles.
    const styles = StyleSheet.create({
        container: {
            fontFamily: 'nunito-regular',
            color: theme.text.color,
            flexGrow: 1,
            // selectionColor: theme.accent,
        },
    });

    // show loading, api status, etc. 
    if (!initialized) { return <Splash />; }
    // if (initialized) { return <Splash/>; }

    // Base Layout.
    return (
        <View style={styles.container}>
            <Background>
                {/* Header */}
                <Header />
                <View style={{ flexGrow: 1, padding: 24, zIndex: 10 }}>

                    {/* Temporary Banner (exclude dashboard, require additional styling.) */}
                    {/* <Exclude routes={["dashboard", "admin"]}>
                        <Banner emoji="🚧" text="This website is under construction - everything is subject to change." />
                    </Exclude> */}

                    {/* Content */}
                    <Outlet/>
                </View>

                {/* Footer */}
                <Footer />
            </Background>
        </View>
    )
}