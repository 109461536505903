import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText } from '../../../components/Text';
import useTheme from '../../../contexts/theme';
import useAuth from '../../../contexts/auth';
import useToast from '../../../contexts/toast';
import { ScrollableModal } from '../../../components/Modal';
import Title from '../../../components/Title';
import Divider from '../../../components/Divider';

import { formatDate } from '../../../utility/date';
import Status from '../../../components/InvoiceStatus';
import useScale from '../../../contexts/scale';


export default function DashboardHome() {
    const { theme } = useTheme();
    const push = useToast();
    const { request } = useAuth();
    const { style } = useScale();

    // Retrieve invoices.
    const [services, setServices] = React.useState([]);

    // Retrieve services.
    React.useEffect(() => {
        request.current.get('/billing/stripe/subscriptions')
            .then((response: any) => {
                // Store services.
                setServices(response.data.data.data);
            })
            .catch((response: any) => {
                push("Failed to fetch services.", "error")
                console.log(`Failed to fetch services: ${response.data}`);
            });
    }, []);

    const styles = StyleSheet.create({
        table: {
            flexDirection: 'row',
        },
        container: {
            ...style({ width: '200%' }, 'any'),
            ...style({ width: '100%' }, 'sm')
        },
        entryView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 32,
            borderRadius: 4,
        },
        entry: { flex: 1 },
    });

    // Statuses when services are considered "active".
    const activeStatuses = ['active', 'trialing', 'unpaid'];

    return (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            {/* Header */}
            <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                <Title title="Services" />
            </View>

            {/* Services Modal */}
            <ScrollableModal>
                <View style={styles.container}>
                    {/* Active services notification. */}
                    <View style={{ marginBottom: 24 }}>
                        {services.length > 0 ? <DefaultText style={{ color: theme.text.secondary }}>You have {services.filter((service: any) => activeStatuses.includes(service.status)).length.toString()} active service(s).</DefaultText> : <DefaultText style={{ color: theme.text.secondary }}>You have no active services.</DefaultText>}
                    </View>

                    {/* Services table titles. */}
                    <View style={{ ...styles.table, paddingHorizontal: 32 }}>
                        <DefaultText style={styles.entry}>Service</DefaultText>
                        <DefaultText style={styles.entry}>Date Created</DefaultText>
                        <DefaultText style={styles.entry}>Next Due Date</DefaultText>
                        <DefaultText style={styles.entry}>Quantity</DefaultText>
                        <DefaultText style={styles.entry}>Recurring Cost</DefaultText>
                        <DefaultText style={styles.entry}>Status</DefaultText>
                    </View>
                    <Divider style={{ backgroundColor: theme.alternativeSecondary, marginBottom: 24 }} />

                    {/* Services display */}
                    {services.length > 0 && services.map((service: any, index) => (
                        <View key={index} style={{ ...styles.table }}>
                            {/* Service entry. */}
                            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', paddingVertical: 16, paddingHorizontal: 32, borderRadius: 4, backgroundColor: index % 2 ? theme.alternative : theme.alternativeSecondary }}>
                                <DefaultText style={styles.entry}>{service.plan.product.name}</DefaultText>
                                <DefaultText style={styles.entry}>{formatDate(service.created)}</DefaultText>
                                <DefaultText style={styles.entry}>{formatDate(service.current_period_end)}</DefaultText>
                                <DefaultText style={styles.entry}>{`x${service.quantity}${service.plan.product.unit_label ? ' ' + service.plan.product.unit_label : ''}`}</DefaultText>

                                <DefaultText style={styles.entry}>{'$' + ((service.quantity * service.plan.amount) / 100).toFixed(2) + `/${service.plan.interval}`}</DefaultText>
                                <DefaultText style={styles.entry}><Status status={service.status} type={'subscriptions'} /></DefaultText>
                            </View>
                        </View>
                    ))}

                    {/* No services notification. */}
                    {services.length === 0 && <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}><DefaultText style={{ color: theme.text.secondary }}>No services found.</DefaultText></View>}

                    {/* Cancellation Message */}
                    {services.length !== 0 && <DefaultText style={{ color: theme.text.secondary, fontSize: 12, marginTop: 8 }}>*To cancel a service, please contact support.</DefaultText>}
                </View>
            </ScrollableModal>
        </View>
    )
}