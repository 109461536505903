import { MenuItem } from "../../components/Sidebar";

const routes: MenuItem[] = [
    {
        name: 'Dashboard',
        path: null,
        icon: 'pin'
    },
    {
        name: 'Home',
        path: '/dashboard',
        icon: 'home',
    },
    {
        name: 'Tickets',
        path: '/dashboard/tickets',
        icon: 'comment-discussion'
    },
    {
        name: 'Profile',
        path: '/dashboard/profile',
        icon: 'person' // id-badge
    },
    {
        name: 'Settings',
        path: '/dashboard/settings',
        icon: 'gear'
    },
    
    // Billing
    // --------------------------------------------------------
    {
        name: 'Billing',
        path: null,
        icon: 'file-badge'
    },

    {
        name: 'Services',
        path: '/dashboard/services',
        icon: 'note'
    },

    {
        name: 'Invoices',
        path: '/dashboard/invoices',
        icon: 'tag'
    },

    // {
    //     name: 'Payment Methods',
    //     path: '/dashboard/billing/payment-methods',
    //     icon: 'credit-card'
    // },


    // Security Suite
    // --------------------------------------------------------
    // {
    //     name: 'Security Suite',
    //     path: null,
    //     icon: 'shield-check'
    // },

    // {
    //     name: 'Surveillance',
    //     path: '/dashboard/security/surveillance',
    //     icon: 'device-camera-video'
    // },
    // {
    //     name: 'Device Keys',
    //     path: '/dashboard/security/device-keys',
    //     icon: 'key'
    // },
    // --------------------------------------------------------

    // Services
    // --------------------------------------------------------
    {
        name: 'Services',
        path: null,
        icon: 'apps'
    },

    {
        name: 'Security Suite',
        path: '/dashboard/security/surveillance',
        icon: 'shield-check'
    },
    {
        name: 'Tunebox',
        path: '/dashboard/tunebox',
        icon: 'unmute'
    },
    // --------------------------------------------------------
    
    // Coming Soon
    // --------------------------------------------------------
    // {
    //     name: 'Under Development',
    //     path: null,
    //     icon: 'terminal'
    // },
    // {
    //     name: 'Assistant',
    //     path: '/coming-soon',
    //     icon: 'hubot'
    // },
    // {
    //     name: 'Wearables',
    //     path: '/coming-soon',
    //     icon: 'rss'
    // },
    // {
    //     name: 'Audit Log',
    //     path: '/coming-soon',
    //     icon: 'history' // database
    // },

    // Company
    // --------------------------------------------------------
    {
        name: 'Atlas Industries',
        path: null,
        icon: 'organization'
    },
    {
        name: 'News',
        path: '/news',
        icon: 'megaphone' // note
    },
    {
        name: 'Feedback',
        path: '/contact', /// '/feedback'
        icon: 'light-bulb' // note
    },
    {
        name: 'Contact',
        path: '/contact',
        icon: 'mail' // note
    },
]

export default routes;