import React from 'react';
import { StyleSheet } from 'react-native';
import { DefaultText } from './Text';
import useTheme from '../contexts/theme';

// Status element.
export default function Status({ status, type, style }: { status: string, type: string, style?: any }) {
    const { theme } = useTheme();
    status = status.toLowerCase();
    const styles = StyleSheet.create({
        status: {
            // @ts-ignore
            color: theme[type][status], 
            border: '2px solid',
            // @ts-ignore
            borderColor: theme[type][status], 
            borderRadius: 4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            ...style
        },
    });
    return <DefaultText font='bold' style={styles.status}>{status.toUpperCase()}</DefaultText>
}