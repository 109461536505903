import { MenuItem } from "../../components/Sidebar";

const routes: MenuItem[] = [
    {
        name: 'Admin Dashboard',
        path: null,
        icon: 'star'
    },
    {
        name: 'Home',
        path: '/admin',
        icon: 'home',
    },
    {
        name: 'Users',
        path: null,
        icon: 'people',
    },
    {
        name: 'View Users',
        path: '/admin/users',
        icon: 'person',
    },
    {
        name: 'Services',
        path: null,
        icon: 'apps',
    },
    {
        name: 'Tokens',
        path: '/admin/services/tokens',
        icon: 'key',
    }
]

export default routes;