import React from 'react';
import { View, StyleSheet } from 'react-native';
import Sidebar, { MenuItem } from '../../components/Sidebar';
import { Outlet } from "react-router-dom";
import Banner from '../../components/Banner';

import Tree from '../../components/Tree';
import Divider from '../../components/Divider';
import { DefaultText } from '../../components/Text';

import { getTree } from '../../utility/location';
import { makeTitle } from '../../utility/string';
import useScale from '../../contexts/scale';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    title: {
        fontSize: 24,
        marginBottom: 4
    }
});

export default function Root({ routes }: { routes: MenuItem[] }) {
    const location = getTree();
    const { style } = useScale();
    
    return (
        <View style={styles.container}>
            {/* Sidebar */}
            <View style={{ zIndex: 10 }}><Sidebar routes={routes} /></View>


            <View style={{ ...style({ flexGrow: 0, width: '100%' }, 'any'), ...style({ flexGrow: 1, width: 'min-content' }, 'sm') }}>
                {/* Temporary Notification */}
                {/* <Banner emoji="🚧" text="This website is under construction - everything is subject to change." /> */}

                {/* Page Header */}
                <DefaultText font='bold' style={styles.title}>{makeTitle(location[location.length - 1])}</DefaultText>
                <Tree />
                <Divider />

                {/* Page Content */}
                <Outlet />
            </View>
        </View>
    )
}