import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText, TextBreak } from '../../components/Text';
import Divider from '../../components/Divider';

export const Styles = StyleSheet.create({
    indent: {
        paddingLeft: 24
    }
});

export function Section({ children }: { children: any }) {
    return (
        <View style={{ gap: 16 }}>
            <Divider />
            <DefaultText font='bold' style={{ fontSize: 16, marginBottom: -4}}>{children}</DefaultText>
        </View>
    )
}

export function Title({ title, date, useDivider = true }: { title: string, date: string, useDivider?: boolean }) {
    return (
        <View style={{}}>
            <DefaultText font='bold' style={{ fontSize: 36 }}>{title}</DefaultText>
            <DefaultText font='semibold'>📅 Last Updated: {date}</DefaultText>
            {useDivider && <Divider />}
        </View>
    )
}