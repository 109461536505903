import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultText, ItalicText, TextBreak, TextLinkInteractive, TextSpace } from '../../../components/Text';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../../contexts/theme';
import { Section, Title, Styles } from '../Components';

export default function GeneralTerms() {
    const navigate = useNavigate();
    const { theme } = useTheme();

    return (
        <View style={{}}>
            {/* Title */}
            <Title title="Subscription Additional Terms" date="01/29/2023" useDivider={false}/>

            {/* Content */}
            <View style={{ gap: 16 }}>

                {/* Section I. Offer Terms */}
                <Section>I. Offer Terms</Section>
                <DefaultText>All of our paid services are subscribed on a service period basis. The length of the subscription term is outlined during checkout.</DefaultText>
                <DefaultText>If an exclusive offer includes a discount off the total price or a promotional code is used providing a discount off the total price the discount only applies to the initial subscription term and is not applicable to recurring subscriptions unless explicitly stated.</DefaultText>
                <DefaultText>By submitting your payment details, you (i) confirm your acceptance of the offer advertised; (ii) accept and agree to the Terms provided hereby; and (iii) acknowledge and agree to our <TextLinkInteractive navigate={navigate} link='/legal/terms' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Terms of Service</TextLinkInteractive> and <TextLinkInteractive navigate={navigate} link='/legal/privacy' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Privacy Policy</TextLinkInteractive>.</DefaultText>
            
                {/* Section II. Subscription and Automatic Renewal Terms */}
                <Section>II. Subscription and Automatic Renewal Terms</Section>
                <DefaultText>After the end of your initial plan, your subscription will be automatically renewed, and you will be charged the then-current price of the service. For more information read our <TextLinkInteractive navigate={navigate} link='/legal/terms' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Terms of Services</TextLinkInteractive>.</DefaultText>
                <DefaultText>For recurring plans, the chosen payment method will be charged within a 24-hour range of the previous date of charge unless you have decided to cancel your automatic payments for the services before the new charge is made.</DefaultText>

                {/* Section III. Price Terms */}
                <Section>III. Price Terms</Section>
                <DefaultText>All prices, including recurring subscription fees, are subject to change. Any changes in pricing will be provided in the initial offer or communicated to you in advance.</DefaultText>

                {/* Section IV. Cancellations and Refunds */}
                <Section>IV. Cancellations and Refunds</Section>
                <DefaultText>Refunds are handled solely on a case-by-case basis. We do not provide a guarantee that a refund will be issued to you. All refund requests are subject to the discretion of Atlas and its management.</DefaultText>
                <DefaultText>You can cancel a recurring subscription from your Atlas account. Canceled Services will not be refunded for the unused part of the ongoing service period. For further information about the cancellation policy, read our <TextLinkInteractive navigate={navigate} link='/legal/terms' style={{ color: theme.accent, textDecorationLine: 'underline' }}>Terms of Services</TextLinkInteractive>.</DefaultText>
            </View>
        </View>
    )
}