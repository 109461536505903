import React from 'react';
import Background from '../components/Background';
import { Animated, View } from 'react-native';
import { DefaultText } from '../components/Text';
import Modal from '../components/Modal';
import Divider from '../components/Divider';
import useTheme from '../contexts/theme';
import Title from '../components/Title';
import { Link } from 'react-router-dom';
import SpinIcon from '../components/SpinIcon';
import AnimatedEmoji from '../components/AnimatedEmoji';
import useScale from '../contexts/scale';

export default function Splash() {
    const { theme } = useTheme();
    const { style } = useScale();

    return (
        <Background>
            <View style={{ height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ ...style({ width: '80%' }, 'any'), ...style({ width: '50%' }, 'md'), ...style({ width: '30%' }, 'xl') }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}><Title title="Loading" fontSize={20} /></View>
                    <Modal>
                        {/* Title */}
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <DefaultText font='bold' style={{ fontSize: 36 }}><AnimatedEmoji emoji={"clock"} interval={100}/>Loading...</DefaultText>
                        </View>
                        <Divider style={{ backgroundColor: theme.text.secondary }} />

                        {/* Message */}
                        <DefaultText style={{ textAlign: 'center', fontSize: 18 }}>
                            Validating session.
                        </DefaultText>

                        {/* Connection issues? */}
                        <DefaultText style={{ textAlign: 'center', marginTop: 12, fontSize: 14 }}>
                            Connection issues?&nbsp;
                            <Link to="/status" style={{ color: theme.accent }}>
                                <DefaultText font='bold' style={{ color: theme.accent }}>Status</DefaultText>
                            </Link>
                            .
                        </DefaultText>
                    </Modal>
                </View>

                <View style={{position: 'absolute', right: 0, bottom: 0, marginRight: 12, marginBottom: 12, ...theme.shadowPrimary}}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 10, paddingHorizontal: 20, borderRadius: 8, backgroundColor: theme.alternative}}>
                        <SpinIcon active={true} size={18} />
                        <DefaultText font='semibold' style={{ fontSize: 16, marginLeft: 10 }}>Connecting to API</DefaultText>
                    </View>
                </View>
            </View>
        </Background>
    )
}