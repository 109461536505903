import React from 'react';

import { View, StyleSheet } from 'react-native';
import { useNavigate } from 'react-router-dom';

import { DefaultText, TextLinkInteractive } from './Text';
import Modal from './Modal';

import useTheme from '../contexts/theme';
import Divider from './Divider';
import Button from './Button';

import { formatDate } from '../utility/date';

export function InvoiceDetails({ invoice, payButton }: { invoice: any, payButton?: boolean | undefined }) {
    const { theme } = useTheme();
    const navigate = useNavigate();

    const styles = StyleSheet.create({
        entry: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6
        }
    });

    return (
        <Modal>
            {/* Header */}
            <DefaultText font='medium' style={{ fontSize: 16 }}>Invoice Details</DefaultText>
            <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 8 }} />

            {/* Invoice Details */}

            {/* Invoice Number */}
            <View style={styles.entry}>
                <DefaultText>Invoice Number</DefaultText>
                <TextLinkInteractive link="/dashboard/invoices/view-invoice" interaction={{ onPress: () => { navigate('/dashboard/invoices/view-invoice', { state: invoice }) } }} style={{ color: theme.accent, textDecorationLine: 'underline' }}>{invoice.number}</TextLinkInteractive>
            </View>

            {/* Invoice Date */}
            <View style={styles.entry}>
                <DefaultText>Invoice Date</DefaultText>
                <DefaultText>{formatDate(invoice.created)}</DefaultText>
            </View>

            {/* Invoice Status */}
            <View style={styles.entry}>
                <DefaultText>Invoice Status</DefaultText>
                <DefaultText>{invoice.status.toUpperCase()}</DefaultText>
            </View>

            {/* Display line data */}
            {
                invoice.lines.data.map((line: any, index: number) => {
                    return (
                        <View style={styles.entry} key={index}>
                            <DefaultText>{line.description}</DefaultText>
                            <DefaultText style={{paddingLeft: '10px'}}>{'$' + (line.amount / 100).toFixed(2)}</DefaultText>
                        </View>
                    )
                })
            }

            <Divider style={{ backgroundColor: theme.alternativeSecondary, marginTop: 8 }} />

            {/* Total */}
            <View style={styles.entry}>
                <DefaultText font='medium'>Total</DefaultText>
                <DefaultText font='medium'>{'$' + (invoice.amount_due / 100).toFixed(2)}</DefaultText>
            </View>

            {payButton && <Button title="Pay Now" icon='credit-card' onPress={() => navigate('/checkout', { state: invoice })} style={{ button: { marginTop: 16 } }} />}

        </Modal>
    )
}