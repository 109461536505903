import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { DefaultText, TextInteractive } from './Text';
import useTheme from '../contexts/theme';
import Divider from './Divider';
import useScale from '../contexts/scale';

type CustomStyle = {
    container?: Object,
    title?: Object,
}

// TODO: WIREFRAME OPTION?
// Todo: Blog or News?
export default function Modal({ style, children }: { style?: CustomStyle, children?: React.ReactNode }) {
    const { theme } = useTheme();

    const styles = StyleSheet.create({
        container: {
            width: '100%',
            padding: 24,
            backgroundColor: theme.alternative,
            borderRadius: 8,
            // borderWidth: 2,
            // borderColor: theme.text.secondary,
            ...theme.shadowPrimary,
            ...style?.container
        }
    });
    return (
        <View style={styles.container}>
            {children}
        </View>
    );
}

export function ScrollableModal({ style, children }: { style?: CustomStyle, children?: React.ReactNode }) {

    return (
        <Modal style={style}>
            <ScrollView horizontal={true} contentContainerStyle={{
                width: '100%',
            }}>
                <View style={{ flexGrow: 1 }}>
                    {children}
                </View>
            </ScrollView>
        </Modal>
    )
}

export function ModalTitle({ style = {}, children, title }: { style?: CustomStyle, children?: React.ReactNode, title: string | React.ReactNode }) {
    const { theme } = useTheme();
    const { style: scale } = useScale();

    style.container = {
        flexDirection: 'column',
        alignItems: 'center',
        ...(style?.container || {})
    }

    return (
        <Modal style={style}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <DefaultText font='bold' style={{ ...scale({ fontSize: 24, textAlign: 'center' }, 'any'), ...scale({ fontSize: 28 }, 'sm'), ...scale({ fontSize: 36 }, 'lg'), ...style?.title }}>{title}</DefaultText>
            </View>
            <Divider style={{ backgroundColor: theme.text.secondary }} />
            {/* alternativeSecondary, marginVertical: ?? */}
            <View style={{ width: '100%', fontSize: 18 }}>
                {children}
            </View>
        </Modal>
    )
}

export function ModalList({ style = {}, children, title, options, onPress }: { style?: CustomStyle, children?: React.ReactNode, title: Array<string>, options: Array<Array<string>>, onPress: (titleIndex: number, optionIndex: number) => void }) {
    const { style: scale } = useScale();
    const { theme } = useTheme();
    const [active, setActive] = React.useState('0-0');

    const activeStyle = {
        color: theme.accent,
        borderLeftWidth: 2,
        borderLeftColor: theme.accent,
        paddingLeft: 8
    }

    return (
        <Modal style={{ container: { width: '100%', ...scale({ width: 'fit-content' }, 'sm'), ...style?.container } }}>
            {title.map((title, titleIndex) => (
                <View key={titleIndex} style={titleIndex > 0 && { marginTop: 24 }}>
                    <DefaultText font='bold' style={{ color: theme.text.secondary, textTransform: 'uppercase', fontSize: 14, lineHeight: 24 }}>{title}</DefaultText>
                    <Divider style={{ backgroundColor: theme.alternativeSecondary, marginVertical: 6 }} />
                    <View style={{ marginTop: 3 }}>
                        {options[titleIndex].map((item, optionIndex) => (
                            <TextInteractive key={optionIndex} font='semibold' style={{ fontSize: 14, lineHeight: 24, marginBottom: 3, ...(`${titleIndex}-${optionIndex}` === active && activeStyle) }}
                                interaction={{
                                    onPress: () => {
                                        setActive(`${titleIndex}-${optionIndex}`);
                                        onPress(titleIndex, optionIndex);
                                    }
                                }}>{item}
                            </TextInteractive>
                        ))}
                    </View>
                </View>
            ))}
        </Modal>
    )
}