import React from 'react';

// Router
import {
	createBrowserRouter,
	RouterProvider,
	Navigate,
} from "react-router-dom";

// Providers
import { WebSocketProvider } from './contexts/websocket';
import { AuthProvider } from './contexts/auth';
import { ThemeProvider } from './contexts/theme';
import { ScaleProvider } from './contexts/scale';
import { ToastProvider } from './contexts/toast';
import { DataProvider } from './contexts/data';

// Routes
import Splash from './routes/Splash';

// Main Site
import Root from './routes/Root';
import Error from './routes/Error';
import Index from './routes/Index';
import Login from './routes/Login';
import Register from './routes/Register';
import VerifyEmail from './routes/VerifyEmail';
import Checkout from './routes/Checkout';
import Contact from './routes/Contact';
import About from './routes/About';
import Unauthorized from './routes/Unauthorized';
import ResetPassword from './routes/ResetPassword';

// Legal
import LegalRoot from './routes/Legal/Root';
import GeneralTerms from './routes/Legal/Terms/General';
import SubscriptionTerms from './routes/Legal/Terms/Subscription';
import GeneralPrivacy from './routes/Legal/Privacy/General';
import GDPRPrivacy from './routes/Legal/Privacy/GDPR';

// Dashboard
import DashboardRoot from './routes/Dashboard/Root';
import DashboardIndex from './routes/Dashboard/Index';
import DashboardRoutes from './routes/Dashboard/Routes';

// Dashboard Services
import TuneboxService from './routes/Dashboard/Services/Tunebox';
import TuneboxSpotifyCb from './routes/Dashboard/Services/TuneboxSpotifyCb';
import DashboardSurveillance from './routes/Dashboard/Security/Surveillance';
import DashboardDeviceKeys from './routes/Dashboard/Security/DeviceKeys';

// Admin
import AdminRoot from './routes/Admin/Root';
import AdminRoutes from './routes/Admin/Routes';
import ViewUsers from './routes/Admin/Users/ViewUsers';
import UserProfile from './routes/Admin/Users/UserProfile';
import ServiceTokens from './routes/Admin/Services/Tokens';

// Primary
import DashboardTickets from './routes/Dashboard/Primary/Tickets'
import DashboardProfile from './routes/Dashboard/Primary/Profile'
import DashboardSettings from './routes/Dashboard/Primary/Settings'

// Billing
import DashboardServices from './routes/Dashboard/Billing/Services';
import DashboardInvoices from './routes/Dashboard/Billing/Invoices';
import DashboardPaymentMethods from './routes/Dashboard/Billing/PaymentMethods';
import DashboardViewInvoice from './routes/Dashboard/Billing/ViewInvoice';
import DashboardSubscribe from './routes/Dashboard/Billing/Subscribe';

// Auth
import Protected from './components/Protected';

// todo: auto-load all from directory.
import { loadAsync } from 'expo-font';
const fonts = {
	'nunito-bold': require('./assets/fonts/Nunito/Nunito-Bold.ttf'),
	'nunito-medium': require('./assets/fonts/Nunito/Nunito-Medium.ttf'),
	'nunito-semibold': require('./assets/fonts/Nunito/Nunito-SemiBold.ttf'),
	'nunito-regular': require('./assets/fonts/Nunito/Nunito-Regular.ttf'),
}


// import { src } from '@expo/vector-icons'; | https://icons.expo.fyi/ <src name="name"/> | https://fonts.google.com/icons

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <Error />,

		children: [
			{ index: true, element: <Index /> },
			{
				path: 'dashboard',
				element: <DashboardRoot routes={DashboardRoutes} />,
				children: [
					{ index: true, element: <Protected condition="registered"><DashboardIndex /></Protected> },

					// Primary
					{ path: 'tickets', element: <Protected condition="registered"><DashboardTickets /></Protected> },
					{ path: 'profile', element: <Protected condition="auth"><DashboardProfile /></Protected> },
					{ path: 'settings', element: <Protected condition="registered"><DashboardSettings /></Protected> },

					// Billing
					{ path: 'services', element: <Protected condition="registered"><DashboardServices /></Protected> },
					{ path: 'invoices', element: <Protected condition="registered"><DashboardInvoices /></Protected> },
					{ path: 'billing/payment-methods', element: <Protected condition="registered"><DashboardPaymentMethods /></Protected> },
					{ path: 'invoices/view-invoice/:id?', element: <Protected condition="registered"><DashboardViewInvoice /></Protected> },
					{ path: 'subscribe/:id?', element: <Protected condition="registered"><DashboardSubscribe /></Protected>},

					// Security
					{ path: 'security/surveillance', element: <Protected condition="registered"><DashboardSurveillance /></Protected>, },
					{ path: 'security/device-keys', element: <Protected condition="registered"><DashboardDeviceKeys /></Protected>, },

					// Tunebox
					{ path: 'tunebox', element: <Protected condition="subscribed" args={["tunebox"]}><TuneboxService /></Protected>, },
					{ path: 'tunebox/spotify', element: <Protected condition="subscribed" args={["tunebox"]}><TuneboxSpotifyCb /></Protected>, },
				]
			},
			{
				path: 'legal',
				element: <LegalRoot />,
				children: [
					{ index: true, element: <Navigate to='/legal/terms' replace /> },
					{ path: 'terms', element: <GeneralTerms /> },
					{ path: 'terms/subscription', element: <SubscriptionTerms /> },
					{ path: 'privacy', element: <GeneralPrivacy /> },
					{ path: 'privacy/gdpr', element: <GDPRPrivacy /> }
				]
			},
			{
				path: 'admin',
				element: <AdminRoot routes={AdminRoutes} />,
				children: [
					{ index: true, element: <Protected condition="privilege" args={["GLOBAL_FLAG", "STAFF"]}><DashboardIndex /></Protected> },

					// Users
					{ path: 'users', element: <Protected condition="privilege" args={["GLOBAL_PERMISSION", "VIEW_USERS"]}><ViewUsers /></Protected> },

					// Users
					{ path: 'users/:uuid', element: <Protected condition="privilege" args={["GLOBAL_PERMISSION", "VIEW_USERS"]}><UserProfile /></Protected> },

					// Service tokens
					{ path: 'services/tokens', element: <Protected condition="privilege" args={["GLOBAL_PERMISSION", "MANAGE_SERVICE_TOKENS"]}><ServiceTokens /></Protected> },
				]
			},


			{
				path: 'login',
				element: <Protected condition="!auth"><Login /></Protected>
				// element: <Login/>
			},
			{
				path: 'register',
				element: <Protected condition="!auth"><Register /></Protected>
			},
			{
				path: 'verify-email/:email',
				element: <VerifyEmail />
			},
			{
				path: 'reset-password/:email?',
				element: <ResetPassword />
			},
			{
				path: 'checkout',
				element: <Protected condition="registered"><Checkout /></Protected>
			},
			{
				path: 'contact',
				element: <Contact />
			},
			{
				path: 'about',
				element: <About />
			},

			{
				path: '/unauthorized',
				element: <Unauthorized />
			}
		]
	}
]);

export default function App() {
	// Load fonts.
	const [fontsLoaded, setFontsLoaded] = React.useState(false);
	loadAsync(fonts).then(() => setFontsLoaded(true));

	// Display splash while loading.
	// if (!fontsLoaded) { return <Splash/>; }

	// Display app.
	return (
		<WebSocketProvider>
			<AuthProvider>
				<ThemeProvider>
					<ScaleProvider>
						<ToastProvider>
							<DataProvider>
								<RouterProvider router={router} />
							</DataProvider>
						</ToastProvider>
					</ScaleProvider>
				</ThemeProvider>
			</AuthProvider>
		</WebSocketProvider>
	);
}