import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from '../contexts/theme';

export default function Divider({ style }: { style?: Object }) {
    const { theme } = useTheme();
    const styles = StyleSheet.create({
        divider: {
            width: '100%',
            height: 2,
            marginVertical: 12,
            backgroundColor: theme.alternative,
            borderRadius: 4,
            ...style,
        },
    });
    return (
        <View style={styles.divider}></View>
    );
}

export function VerticalDivider({ style }: { style?: Object }) {
    const { theme } = useTheme();
    return (
        <View style={{ borderColor: theme.alternativeSecondary, borderWidth: 1, borderRadius: 4, marginHorizontal: 12, height: '100%', width: 2, ...style }}></View>
    )
}